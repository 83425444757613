import React, { useContext, useEffect, useState } from "react";
import Header from "../../dashboard/components/header";
import HeaderCreate from "../../../components/header_create";
import MainContext from "../../../context";
import Table from "../../../components/ajonjolib/table/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../../components/ajonjolib/inputs/ajonjolinput";
import EmployeesModal from "../company/modals/employees";
import ExportModal from "../company/modals/export";
import { eventBus, openModal } from "../../../components/ajonjolib/toasts/toast/toast";
import ImportModal from "../company/modals/import";
import EmployeesCreate from "./create";
import api from "../../../api";
import HeaderFilters from "../../../components/header_filters";
import { Label, Select, Text } from "../../../components/ajonjolib/inputs/ajonjolinput";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



export default function Employees() {
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [openCreate, setOpenCreate] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [filters, setFilters] = useState({
        subenterprise: -1,
        projectGroup: -1,
        project: -1,
        guard: -1,
        turn: -1,
        assigned: -1
    });
    const [companies, setCompanies] = useState([]);
    const [projectGroups, setProjectGroups] = useState([]);
    const [projects, setProjects] = useState([]);
    const [guards, setGuards] = useState([]);
    const [turns, setTurns] = useState([]);

    const assignedOptions = [
        { name: 'Todos', value: -1 },
        { name: 'Asignado', value: true },
        { name: 'No Asignado', value: false }
    ];

    const { setSettings } = useContext(MainContext);

    useEffect(() => {
        api.get('subenterprises/').then((response) => {
            if (response?.status !== 200) return;
            let values = response.data;
            values.unshift({ id: -1, name: 'Todos' });
            setCompanies(values);
        });
    }, []);

    useEffect(() => {
        api.get('project-groups/?subenterprise=' + filters['subenterprise']).then((response) => {
            if (response?.status !== 200) return;
            let values = response.data;
            values.unshift({ id: -1, name: 'Todos' });
            setProjectGroups(values);
        });
    }, [filters['subenterprise']]);

    useEffect(() => {
        api.get('projects/?project_group=' + filters['projectGroup']).then((response) => {
            if (response?.status !== 200) return;
            let values = response.data;
            values.unshift({ id: -1, name: 'Todos' });
            setProjects(values);
        });
    }, [filters['projectGroup']]);

    useEffect(() => {
        api.get('guards/?project=' + filters['project']).then((response) => {
            if (response?.status !== 200) return;
            let values = response.data;
            values.unshift({ id: -1, name: 'Todos' });
            setGuards(values);
        });
    }, [filters['project']]);

    useEffect(() => {
        api.get('turns/?project=' + filters['project']).then((response) => {
            if (response?.status !== 200) return;
            let values = response.data;
            values.unshift({ id: -1, name: 'Todos' });
            setTurns(values);
        });
    }, [filters['project'], filters['guard']]);

    const onTapSearch = () => {
        let url = 'employees/?';
        for (const [key, value] of Object.entries(filters)) {
            if (value === -1) continue;
            url += key + '=' + value + '&';
        }
        api.get(url).then(response => {
            if (response?.status !== 200) return;
            setRows(response.data);
        });
    }

    useEffect(() => {
        document.title = "Sleeptech - Empleados";
        setSettings((prev) => {
            return {
                ...prev, sidebar: 3, submenu: 4
            }
        });

        api.get('employees/').then(response => {
            setRows(response.data);
        })
    }, [setSettings]);

    const cols = [
        {
            title: 'Estado', key: 'active', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <div className={'me-2'} style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: data ? '#00C853' : '#EAEAEC' }} />
                        <div>{data}</div>
                    </div>
                )
            }
        },
        { title: 'Empresa', key: 'subenterprise.name' },
        { title: 'Grupo de Proyecto', key: 'projectGroup.name' },
        { title: 'Proyecto', key: 'project.name' },
        { title: 'Guardia', key: 'guard.name' },
        { title: 'ID', key: 'id' },
        {
            title: 'Apellidos y Nombres', key: '', code: (row, data) => {
                return (
                    <div>{row.vApellidos}, {row.vNombres}</div>
                )
            }
        },
        {
            title: 'Turno', key: 'turn.isDay', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        {data ? <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0013 0H15.0296C15.695 0 16.2361 0.665417 16.2361 1.20643V3.67772C16.2361 4.34313 15.695 4.88414 15.0296 4.88414H15.0013C14.3359 4.88414 13.7949 4.21873 13.7949 3.67772V1.20643C13.7949 0.54101 14.3359 0 15.0013 0Z" fill="#239290" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.38469 4.41317L4.40543 4.39243C4.87669 3.92117 5.72874 4.01165 6.10948 4.39243L7.8588 6.14175C8.33006 6.61301 8.33006 7.37645 7.8588 7.8458L7.83807 7.86654C7.36681 8.3378 6.51476 8.24731 6.13401 7.86654L4.38469 6.11722C3.91344 5.64596 3.91344 4.88251 4.38469 4.41317Z" fill="#239290" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M29.9993 14.994V15.0242C29.9993 15.6896 29.3339 16.2306 28.7929 16.2306H26.3197C25.6543 16.2306 25.1133 15.6915 25.1133 15.0242V14.994C25.1133 14.3286 25.7787 13.7876 26.3197 13.7876H28.7929C29.4583 13.7876 29.9993 14.3267 29.9993 14.994Z" fill="#239290" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.598 4.39238L25.6187 4.41312C26.09 4.88438 25.9995 5.73643 25.6187 6.11717L23.8694 7.86649C23.3982 8.33775 22.6347 8.33775 22.1654 7.86649L22.1446 7.84576C21.6734 7.3745 21.7639 6.52245 22.1446 6.1417L23.8939 4.39238C24.3652 3.92113 25.1287 3.92113 25.598 4.39238Z" fill="#239290" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0013 25.1429H15.0296C15.695 25.1429 16.2361 25.8084 16.2361 26.3494V28.8207C16.2361 29.4861 15.695 30.0271 15.0296 30.0271H15.0013C14.3359 30.0271 13.7949 29.3617 13.7949 28.8207V26.3494C13.7949 25.684 14.3359 25.1429 15.0013 25.1429Z" fill="#239290" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1445 22.1722L22.1652 22.1515C22.6365 21.6802 23.4885 21.7707 23.8692 22.1515L25.6186 23.9008C26.0898 24.372 26.0898 25.1355 25.6186 25.6048L25.5978 25.6256C25.1266 26.0968 24.2745 26.0063 23.8938 25.6256L22.1445 23.8763C21.6732 23.405 21.6732 22.6415 22.1445 22.1722Z" fill="#239290" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.88603 14.9939V15.0241C4.88603 15.6895 4.22061 16.2305 3.6796 16.2305H1.20643C0.54101 16.2305 0 15.6914 0 15.0241V14.9939C0 14.3285 0.665417 13.7875 1.20643 13.7875H3.6796C4.34502 13.7875 4.88603 14.3266 4.88603 14.9939Z" fill="#239290" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.83823 22.1514L7.85897 22.1722C8.33023 22.6434 8.23975 23.4955 7.85897 23.8762L6.10965 25.6255C5.63839 26.0968 4.87495 26.0968 4.4056 25.6255L4.38486 25.6048C3.9136 25.1335 4.00409 24.2815 4.38486 23.9007L6.13418 22.1514C6.60544 21.6802 7.36889 21.6802 7.83823 22.1514Z" fill="#239290" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.2043 15.0089C23.2043 19.5405 19.5322 23.2126 15.0006 23.2126C10.4689 23.2126 6.79688 19.5405 6.79688 15.0089C6.79688 10.4772 10.4689 6.80518 15.0006 6.80518C19.5322 6.80518 23.2043 10.4772 23.2043 15.0089Z" fill="#239290" />
                        </svg>
                            : <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.998 13.4358C23.9387 19.3394 18.8097 23.7765 12.6215 23.7765C5.61902 23.7765 0 18.1575 0 11.155C0 6.31077 2.72755 2.07522 6.75944 0C6.63698 0.733189 6.55587 1.50611 6.55587 2.28067C6.55587 9.24369 12.2144 14.9022 19.1774 14.9022C21.2942 14.9022 23.2489 14.3726 25 13.4358H24.998Z" fill="#FFAA00" />
                            </svg>
                        }
                        <div className={'ms-3'}>{data ? 'Día' : 'Noche'}</div>
                    </div>
                )
            }
        },
        {
            title: 'Pulsera', key: 'device.id', header: (title) => {
                return (
                    <div className={'d-flex justify-content-center flex-column align-items-center'}>
                        <div>{title}</div>
                        <div className={'d-flex justify-content-center align-items-center w-100'} style={{ color: '#FFF' }}>
                            <div className={'d-flex justify-content-center align-items-center'} style={{ backgroundColor: '#5863B4', borderRadius: '5px 5px 0 0', width: '107px', height: '23px', fontSize: '11px' }}>Estado</div>
                        </div>
                    </div>
                )
            }, code: (row, data) => {
                const active = row.device?.active
                const assigned = row.device ? true : false;
                console.log(data, assigned);
                let color = assigned ? '#5863B4' : '#F66565';
                let text = assigned ? 'Asignado' : 'No Asignado';
                if (assigned && active == false) {
                    color = '#FFAA00';
                    text = 'Desactivada';
                }
                return (
                    <div style={{ color }}>
                        {text}
                    </div>
                )
            }
        },
        {
            title: 'Acciones', key: 'actions', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faEdit} color={'#5863B4'} size={'lg'} role={'button'} onClick={() => {
                            eventBus.emit('employees_modal_open', row);
                        }} />
                    </div>
                )
            }
        }
    ];

    const onSearch = (text) => {
        setSearchText(text);
    }

    const onSubmitFile = (file) => {
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file);
        api.post('employees/all/import', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            if (response?.status !== 200) return;
            toast.success('Empleados importados correctamente');
            onTapSearch();
        }
        )
    }

    const filteredRows = rows.filter((row) => {
        const text = searchText.toLowerCase();
        const turn = row.turn?.isDay ? 'Día' : 'Noche';
        const fullName = row.vApellidos + ', ' + row.vNombres;
        const active = row.device?.active
        const assigned = row.device ? true : false;
        let deviceStatus = assigned ? 'Asignado' : 'No Asignado';
        if (assigned && active == false) {
            deviceStatus = 'Desactivada';
        }
        return row.id.toString().includes(text) ||
            (fullName && fullName.toLowerCase().includes(text)) ||
            (row.project && row.project.name?.toLowerCase().includes(text)) ||
            (row.projectGroup && row.projectGroup.name?.toLowerCase().includes(text)) ||
            (row.subenterprise && row.subenterprise.name?.toLowerCase().includes(text)) ||
            (row.guard && row.guard.name?.toLowerCase().includes(text)) ||
            (turn && turn.toLowerCase().includes(text)) ||
            (deviceStatus && deviceStatus.toLowerCase().includes(text))
    })

    return (
        <div>
            <EmployeesModal onSuccess={() => {
                toast.success('Empleado actualizado correctamente');
                onTapSearch();
            }} />
            <ExportModal title={'Master de Empleados'} />
            <ImportModal title={'Master de Empleados'} submit={onSubmitFile} />
            <Header title={'Master de Empleados'}>
                <>
                    <div>
                        <div className={'searchBox'}>
                            <div>
                                <img src={'/icons/search.png'} alt={'search'} width={'auto'} height={'24px'} />
                            </div>
                            <input type={'text'} placeholder={'Buscar...'}
                                onChange={(e) => {
                                    onSearch(e.target.value);
                                }
                                }
                            />
                        </div>
                    </div>
                    <div className={'d-flex align-items-center'}>
                        <Button variant={'primary'} name={'Importar'} icon={'/icons/import.png'} onSubmit={() => {
                            openModal('import_modal', { url: 'employees/all/export' })
                        }} />
                        <div className={'ms-3'} />
                        <Button variant={'primary'} name={'Exportar'} icon={'/icons/export.png'} onSubmit={() => {
                            openModal('export_modal', { url: 'employees/all/export' })
                        }} />
                    </div>
                </>
            </Header>
            <div className={'d-flex align-items-center'}>
                <div style={{ width: '100%' }}>

                    <HeaderCreate name={'Lista de Empleados'}
                        open={openCreate} setOpen={setOpenCreate}>
                        <EmployeesCreate onSuccess={() => {
                            toast.success('Empleado creado correctamente');
                            setOpenCreate(false);
                            onTapSearch();
                        }} />
                    </HeaderCreate>
                </div>
                <HeaderFilters open={openFilters} setOpen={setOpenFilters} >
                    <div>
                        <div className={'mb-3'}>
                            <div style={{ flexBasis: '100%' }}>
                                <Label>Empresa</Label>
                                <Select value={filters['subenterprise']} options={companies?.map((x) => {
                                    return {
                                        name: x.name ?? '',
                                        value: x.id
                                    }
                                })} onChange={(val) => {
                                    if (val[0] === filters['subenterprise']) {
                                        return;
                                    }
                                    setFilters({
                                        ...filters,
                                        subenterprise: val[0],
                                        projectGroup: -1,
                                        project: -1,
                                        guard: -1,
                                        turn: -1
                                    });
                                }} />
                            </div>
                            <div style={{ flexBasis: '100%' }}>
                                <Label>Grupo de Proyecto</Label>
                                <Select value={filters['projectGroup']} options={projectGroups?.map((x) => {
                                    return {
                                        name: x.name ?? '',
                                        value: x.id
                                    }
                                })} onChange={(val) => {
                                    if (val[0] === filters['projectGroup']) {
                                        return;
                                    }
                                    setFilters({
                                        ...filters,
                                        projectGroup: val[0],
                                        project: -1,
                                        guard: -1,
                                        turn: -1
                                    });
                                }} />
                            </div>
                            <div style={{ flexBasis: '100%' }}>
                                <Label>Proyecto</Label>
                                <Select value={filters['project']} options={projects?.map((x) => {
                                    return {
                                        name: x.name ?? '',
                                        value: x.id
                                    }
                                })} onChange={(val) => {
                                    if (val[0] === filters['project']) {
                                        return;
                                    }
                                    setFilters({
                                        ...filters,
                                        project: val[0],
                                        guard: -1,
                                        turn: -1
                                    });
                                }} />
                            </div>
                            <div style={{ flexBasis: '100%' }}>
                                <Label>Guardia</Label>
                                <Select value={filters['guard']} options={guards?.map((x) => {
                                    return {
                                        name: x.name ?? '',
                                        value: x.id
                                    }
                                })} onChange={(val) => {
                                    if (val[0] === filters['guard']) {
                                        return;
                                    }
                                    setFilters({
                                        ...filters,
                                        guard: val[0],
                                        turn: -1
                                    });
                                }} />
                            </div>
                            <div style={{ flexBasis: '100%' }}>
                                <Label>Turno</Label>
                                <Select value={filters['turn']} options={turns?.map((x) => {
                                    return {
                                        name: x.id == -1 ? x.name : x.isDay ? 'Día' : 'Noche',
                                        value: x.id
                                    }
                                })} onChange={(val) => {
                                    if (val[0] === filters['turn']) {
                                        return;
                                    }
                                    setFilters({
                                        ...filters,
                                        turn: val[0],
                                    });
                                }} />
                            </div>
                            <div style={{ flexBasis: '100%' }}>
                                <Label>Asignado</Label>
                                <Select value={filters['assigned']} options={assignedOptions?.map((x) => {
                                    return {
                                        name: x.name,
                                        value: x.value
                                    }
                                })} onChange={(val) => {
                                    if (val[0] === filters['assigned']) {
                                        return;
                                    }
                                    setFilters({
                                        ...filters,
                                        assigned: val[0],
                                    });
                                }} />
                            </div>
                        </div>
                        <div className={'mb-3'} />
                        <div onClick={onTapSearch} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                            backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
                        }}>
                            <FontAwesomeIcon className={'me-3'} icon={faSearch} color={'#5863B4'} />
                            <div style={{ color: '#5863B4' }}>Buscar</div>
                        </div>
                    </div>
                </HeaderFilters>
            </div>
            <div className={'mb-3'} />
            <Table cols={cols} rows={filteredRows} />
            <ToastContainer />
        </div>
    )
}