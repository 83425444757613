export function currentDateStr() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
export function dateToStr(pickedDate) {
    console.log('pickedDate', pickedDate);
    const year = pickedDate.getFullYear();
    const month = String(pickedDate.getMonth() + 1).padStart(2, '0');
    const day = String(pickedDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function timestampToStr(timestamp) {
    return dateToStr(new Date(timestamp));
}

export function minutesToStr(minutes) {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    return `${h}h ${m < 10 ? '0' : ''}${m}m`;
}