import React, { useEffect, useState } from "react";
import { Label, Select, Text } from "../../../components/ajonjolib/inputs/ajonjolinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../api";

/**
 * Component for creating a new band.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.onSuccess - Callback function to be called on successful form submission.
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <BandsCreate onSuccess={() => console.log('Band created successfully!')} />
 *
 * @typedef {Object} Form
 * @property {string} [serial] - The serial number of the device.
 * @property {string} [model] - The model ID of the device.
 * @property {string} [mac] - The MAC address of the device.
 * @property {string} [key] - The key of the device.
 * @property {string} [project_group] - The project group ID.
 * @property {string} [project] - The project ID.
 */
export default function BandsCreate({ onSuccess }) {
    const [form, setForm] = useState({})
    const [models, setModels] = useState([]);
    const [groupProject, setGroupProject] = useState([]);
    const [project, setProject] = useState([]);

    useEffect(() => {
        api.get('project-groups/').then((response) => {
            if (response?.status !== 200) return;
            setGroupProject(response.data);
        }).catch(error => {
            console.log(error);
        }
        );

        api.get('device-models/').then((response) => {
            if (response?.status !== 200) return;
            setModels(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, []);

    useEffect(() => {
        setForm({
            ...form,
            project: undefined
        });
        api.get('projects/?project_group=' + form['project_group']).then((response) => {
            if (response?.status !== 200) return;
            setProject(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [form['project_group']]);

    const submit = () => {
        api.post('devices/', form).then((response) => {
            if (response?.status !== 201) return;
            onSuccess();

        }).catch(error => {
            console.log(error);
        }
        );
    }

    return (
        <>
            <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>N° de Serie</Label>
                        <Text value={form['serial']} onChange={(val) => {
                            setForm({ ...form, serial: val })
                        }} />
                    </div>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Marca y Modelo</Label>
                        <Select value={form['model']} options={models?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} onChange={(val) => {
                            setForm({ ...form, model: val[0] })
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>MAC de Dispositivo</Label>
                        <Text value={form['mac']} onChange={(val) => {
                            setForm({ ...form, mac: val })
                        }} />
                    </div>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Llave Dispositivo</Label>
                        <Text value={form['key']} onChange={(val) => {
                            setForm({ ...form, key: val })
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Grupo de Proyecto</Label>
                        <Select value={form['project_group']} onChange={(val) => {
                            setForm({
                                ...form,
                                project_group: val[0],
                            });
                        }} options={groupProject?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} />
                    </div>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Proyecto</Label>
                        <Select value={form['project']} onChange={(val) => {
                            setForm({ ...form, project: val[0] });
                        }} options={project?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} />
                    </div>
                </div>
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'} />
                <div style={{ color: '#5863B4' }}>Guardar</div>
            </div>
        </>
    )
}