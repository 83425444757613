import React from "react";
import styles from './modal.module.css';

/**
 * Modal component that displays a modal dialog with a backdrop.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The content to be displayed inside the modal.
 * @param {function} props.close - The function to be called when the modal is closed.
 * @param {string} [props.className] - Additional class names to be applied to the modal.
 * @param {Object} [props.style] - Inline styles to be applied to the modal.
 * @param {string} [props.title] - The title to be displayed in the modal header.
 * @param {string} [props.desc] - The description to be displayed below the title in the modal header.
 * @param {string} [props.padding] - The padding to be applied to the modal content.
 * @returns {JSX.Element} The rendered modal component.
 */
export default function Modal({ children, close, className, style, title, desc, padding }) {
    return (
        <div className={styles.container}>
            <div className={styles.backdrop} onClick={close} />
            <div className={`${styles.modal} ${className}`} style={style}>
                <div className={styles.header}>
                    <div>{title}</div>
                    {desc &&
                        <div className={'d-flex'}>
                            <div className={styles.divider} />
                            <div className={styles.desc}>{desc}</div>
                        </div>
                    }
                </div>
                <div style={{
                    color: '#8B8B8B',
                    fontWeight: '400',
                    fontSize: '14px', padding: padding ? padding : "30px 30px"
                }}>
                    {children}
                </div>
            </div>
        </div>
    );
}