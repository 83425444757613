import { createContext, useState } from "react";

const MainContext = createContext("");

/**
 * ContextProvider component that provides the context to the children components.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {JSX.Element} props.children - The children components.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @description
 * This component uses the following hooks:
 * - `useState` to manage the settings state.
 * 
 * The component provides the settings state to the children components
 * using the `MainContext.Provider`.
 */
export function ContextProvider({ children }) {
    const [settings, setSettings] = useState({
        filters: true
    });

    return (
        <MainContext.Provider value={{ setSettings, settings }}>
            {children}
        </MainContext.Provider>
    )
}

export default MainContext;