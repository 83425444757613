import React, { useEffect, useState } from "react";
import { DateRangePicker, Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import styles from "../../../../screens/dashboard/components/filters/index.module.css";
import { timestampToStr } from "../../../../utils/date";


export default function GuardCreate({ onCreate, company }) {
    const [form, setForm] = useState({ active: true });
    const [projects, setProjects] = useState([]);
    const [projectGroups, setProjectGroups] = useState([]);

    const submit = () => {
        const start_date = form.start_date ? timestampToStr(form.start_date) : null;
        const end_date = form.end_date ? timestampToStr(form.end_date) : null;
        const copy = { ...form, start_date, end_date };
        api.post('guards/', copy).then((response) => {
            if (response.status === 201) {
                toast.success('Guardia creada correctamente');
                onCreate();
            }
        });
    }

    useEffect(() => {
        api.get('project-groups/?subenterprise=' + company?.id).then((res) => {
            if (res?.status !== 200) return;
            setProjectGroups(res.data);
        });
    }, []);

    useEffect(() => {
        api.get('projects/?project_group=' + form['project_group']).then((response) => {
            setProjects(response.data)
        });
    }, [form['project_group']]);

    return (
        <>
            <div>
                <Label>Grupo de Proyecto</Label>
                <Select value={form['project_group']} onChange={(val) => {
                    if (val[0] === form['project_group']) return;
                    setForm({ ...form, project_group: val[0], project: undefined });
                }} options={projectGroups?.map((x) => {
                    return {
                        value: x.id,
                        name: x.name ? x.name : 'Sin Nombre'
                    }
                })} />
                <div className={'mb-4'} />
                <Label>Proyecto</Label>
                <Select value={form['project']} onChange={(val) => {
                    setForm({ ...form, project: val[0] });
                }} options={projects?.map((x) => {
                    return {
                        value: x.id,
                        name: x.name ? x.name : 'Sin Nombre'
                    }
                })} />
                <div className={'mb-4'} />
                <Label>Guardia</Label>
                <Text value={form['name']} onChange={(val) => {
                    setForm({ ...form, name: val });
                }} />
                <div className={'mb-4'} />
                <Label>Rango de Fecha</Label>
                <DatePicker
                    selectsRange={true}
                    startDate={form.start_date}
                    endDate={form.end_date}
                    locale={'es'}
                    dateFormat={'dd/MM/yyyy'}
                    onChange={(dates) => {
                        setForm({ ...form, start_date: dates[0], end_date: dates[1] });
                    }}
                    className={styles.datePicker}
                />
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'} />
                <div style={{ color: '#5863B4' }}>Guardar</div>
            </div>
        </>
    )
}