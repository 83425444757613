import React, { useContext, useEffect, useState } from "react";
import Header from "../../dashboard/components/header";
import HeaderCreate from "../../../components/header_create";
import MainContext from "../../../context";
import Table from "../../../components/ajonjolib/table/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import AccessModal from "../company/modals/access";
import AccessCreate from "./create";
import api from "../../../api";
import { eventBus } from "../../../components/ajonjolib/toasts/toast/toast";

export default function Access() {
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');

    const { setSettings } = useContext(MainContext);
    useEffect(() => {
        document.title = "Sleeptech - Accesos";
        setSettings((prev) => {
            return {
                ...prev, sidebar: 3, submenu: 1
            }
        });

        api.get('users/').then(response => {
            setRows(response.data);
        })
    }, [setSettings]);

    const cols = [
        {
            title: 'Estado', key: 'is_active', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <div className={'me-2'} style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: data ? '#00C853' : '#EAEAEC' }} />
                    </div>
                )
            }
        },
        { title: 'Empresa', key: 'subenterprise.name' },
        { title: 'Grupo de Proyecto', key: 'project_group.name' },
        { title: 'Proyecto', key: 'project.name' },
        { title: 'Nombre de Usuario', key: 'username' },
        { title: 'Documento', key: 'documentId' },
        { title: 'E-Mail', key: 'email' },
        {
            title: 'Acciones', key: 'actions', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faEdit} color={'#5863B4'} size={'lg'} role={'button'} onClick={() => {
                            eventBus.emit('access_modal_open', row);
                        }} />
                    </div>
                )
            }
        }
    ];

    const onSearch = (text) => {
        setSearchText(text);
    }

    const filteredRows = rows.length > 0 ? rows.filter((row) => {
        const text = searchText.toLowerCase();
        return (
            row.subenterprise?.name?.toLowerCase().includes(text) ||
            row.username?.toLowerCase().includes(text) ||
            row.documentId?.toLowerCase().includes(text) ||
            row.email?.toLowerCase().includes(text)
        );
    }) : [];

    return (
        <div>
            <AccessModal />

            <Header title={'Accesos'}>
                <div className={'searchBox'}>
                    <div>
                        <img src={'/icons/search.png'} alt={'search'} width={'auto'} height={'24px'} />
                    </div>
                    <input type={'text'} placeholder={'Buscar...'}
                        onChange={(e) => {
                            onSearch(e.target.value);
                        }
                        }
                    />
                </div>
            </Header>
            <HeaderCreate name={'Lista de Usuarios'} width={'622px'} height={'auto'}>
                <AccessCreate />
            </HeaderCreate>
            <div className={'mb-3'} />
            <Table cols={cols} rows={filteredRows} />
        </div>
    )
}