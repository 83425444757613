import React from "react";
import styles from './button.module.css';

/**
 * Button component that renders a styled button with various variants and an optional icon.
 *
 * @param {Object} props - The properties object.
 * @param {Function} props.onSubmit - The function to call when the button is clicked.
 * @param {boolean} props.disabled - If true, the button is disabled and onSubmit will not be called.
 * @param {string} props.className - Additional class names to apply to the button container.
 * @param {Object} props.style - Inline styles to apply to the button container.
 * @param {string} props.name - The text to display inside the button.
 * @param {string} props.variant - The variant of the button, which determines its styling. Can be 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', or 'link'.
 * @param {string} props.icon - The URL of an icon to display inside the button.
 * @returns {JSX.Element} The rendered button component.
 */
export default function Button({ onSubmit, disabled, className, style, name, variant, icon }) {

    const getClass = () => {
        if (variant === 'secondary') {
            return styles.secondary;
        } else if (variant === 'success') {
            return styles.success;
        } else if (variant === 'danger') {
            return styles.danger;
        } else if (variant === 'warning') {
            return styles.warning;
        } else if (variant === 'info') {
            return styles.info;
        } else if (variant === 'light') {
            return styles.light;
        } else if (variant === 'dark') {
            return styles.dark;
        } else if (variant === 'link') {
            return styles.link;
        } else {
            return styles.primary;
        }
    }

    return (
        <div className={className} onClick={() => {
            if (!disabled) {
                if (onSubmit) onSubmit();
            }
        }}>
            <div className={`${styles.container} ${getClass()}`} style={style}>
                {icon && <img src={icon} alt={'icon'} className={'me-2'} style={{ width: '22px', height: 'auto' }} />}
                <div style={{ whiteSpace: 'nowrap' }}>{name}</div>
            </div>
        </div>
    )
}