import axios from "axios";

/**
 * Base URL for the API.
 * 
 * @type {string}
 * @example
 * import { baseURL } from "../../api";
 * 
 * console.log(baseURL);
 * 
 * @description
 * This variable is used to set the base URL for the API.
 * The base URL is used to send HTTP requests to the API.
 * The base URL is constructed using the domain from the environment variables.
 */
let domain = process.env.REACT_APP_DOMAIN;
export let baseURL = "https://" + domain + "/";
export let wsURL = "wss://" + domain + "/ws/";
let refresh = false;

/**
 * Axios instance with base URL and authorization header.
 * 
 * @type {AxiosInstance}
 * @example
 * axiosInstance.get('hubs/').then(response => {
 *    console.log(response.data);
 * }
 * 
 * @description
 * This instance is used to send HTTP requests to the API.
 * The instance has a base URL and an authorization header.
 * The instance also has an interceptor to handle 401 errors.
 * If a 401 error occurs, the instance sends a POST request to the API to refresh the access token.
 * If the refresh request is successful, the instance updates the authorization header with the new access token.
 * If the refresh request fails, the instance clears the local storage and redirects the user to the login page.
 * 
 */
const axiosInstance = axios.create({
    baseURL: baseURL,
    withCredentials: false
});
axiosInstance.defaults.headers.common['Authorization'] = localStorage.getItem('access_token') ? `Token ${localStorage.getItem('access_token')}` : null;
axiosInstance.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 401 && !refresh) {
        refresh = true;
        try {
            const response = await axiosInstance.post('token/refresh/', {
                refresh: localStorage.getItem('refresh_token')
            });
            if (response.status === 200) {
                localStorage.setItem('access_token', response.data.access_token);
                axiosInstance.defaults.headers.common['Authorization'] = `Token ${response.data.access_token}`;
                return axiosInstance(error.config);
            }
        } catch (err) {
            localStorage.clear();
            axiosInstance.defaults.headers.common['Authorization'] = null;
            window.location.href = '/login';
        } finally {
            refresh = false;
        }
    } else if (error.response.status === 401) {
        localStorage.clear();
        axiosInstance.defaults.headers.common['Authorization'] = null;
        window.location.href = '/login';
    }
    return Promise.reject(error);
});

export default axiosInstance;
