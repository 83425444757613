import React, { useEffect, useState } from "react";
import { Label, Select, Text } from "../../../components/ajonjolib/inputs/ajonjolinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import styles from "../company/modals/modals.module.css";
import api from "../../../api";

export default function PermissionsCreate({ onSuccess }) {
    const [form, setForm] = useState({});
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        api.get('permissions/').then((response) => {
            setPermissions(response.data);
        });
    }, []);

    const submit = () => {
        console.log(form);
        api.post('roles/', form).then((response) => {
            if (response?.status !== 201) return;
            onSuccess();
        })
    }

    return (
        <>
            <div>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Nombre</Label>
                        <Text value={form['name']} onChange={(val) => {
                            setForm({ ...form, name: val });
                        }} />
                    </div>
                </div>
                {/* Iterate over permissions and create line for each permission */}
                <div className={'d-flex flex-wrap'} >
                    {permissions.map((permission, index) => (
                        <div key={index}
                            className="d-flex justify-content-between align-items-center mb-4"
                            style={{
                                flexBasis: '45%',
                                marginRight: (index % 2 === 0) ? '20px' : '0',
                            }}>
                            <div style={{ width: '100%' }}>
                                <Label>{permission.name}</Label>
                                <Select value={form[permission.id] ?? "none"} options={[
                                    { name: "Ninguno", value: "none" },
                                    { name: "Lectura", value: "read" },
                                    { name: "Edición", value: "edit" },
                                ]} onChange={(val) => {
                                    setForm({ ...form, [permission.id]: val[0] });
                                }} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'} />
                <div style={{ color: '#5863B4' }}>Guardar</div>
            </div>
        </>
    )
}