import React from "react";
import styles from './index.module.css';

/**
 * Date component renders a date input field.
 *
 * @param {Object} props - The properties object.
 * @param {function} props.changeFrom - Function to handle changes to the "from" date.
 * @param {function} props.changeTo - Function to handle changes to the "to" date.
 * @param {string} props.valueFrom - The current value of the "from" date.
 * @param {string} props.valueTo - The current value of the "to" date.
 * @param {string} props.className - Additional class names for styling.
 * @param {Object} props.style - Inline styles for the component.
 * @returns {JSX.Element} The rendered date input component.
 */
export default function Date({ changeFrom, changeTo, valueFrom, valueTo, className, style }) {
    return (
        <div className={`${styles.container} ${className}`} style={style}>
            <input type={'date'} placeholder={'dd/mm/aaaa'} value={valueFrom} onChange={(event) => {
                changeFrom(event.target.value);
            }} />
        </div>
    )
}