import React, { useEffect, useRef, useState } from "react";
import styles from './searchbox.module.css';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * SearchBox component renders a search input box with customizable properties.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.value - The initial value of the search box.
 * @param {function} props.onChange - Callback function to handle changes in the search term.
 * @param {function} props.onInput - Callback function to handle input events.
 * @param {string} props.className - Additional class names for styling the component.
 * @param {Object} props.style - Inline styles for the component.
 * @param {boolean} props.disabled - Flag to disable the search box.
 * @param {string} props.placeholder - Placeholder text for the search box.
 *
 * @returns {JSX.Element} The rendered search box component.
 */
export default function SearchBox({ value, onChange, onInput, className, style, disabled, placeholder }) {
    const [searchTerm, setSearchTerm] = useState(value);
    const [focused, setFocused] = useState(false);
    const contentEditableRef = useRef(null);

    useEffect(() => {
        if (onChange) onChange(searchTerm);
    }, [searchTerm, onChange]);

    const search = () => {
        if (onInput) onInput(searchTerm);
    };

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            search();
        }
    };

    return (
        <div className={className} style={style}>
            <div className={styles.container}>
                <div
                    style={{
                        color: !focused && !searchTerm ? '#999' : '#000',
                    }}
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    ref={contentEditableRef}
                    onKeyDown={handleEnterKey}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    onInput={(e) => {
                        setSearchTerm(e.currentTarget.textContent);
                    }}
                    className={styles.input}
                >
                    {!focused && !searchTerm && placeholder}
                </div>

                <div className={styles.searchIcon} onClick={() => search()}>
                    <FontAwesomeIcon icon={faSearch} color={'#CCC'} />
                </div>
            </div>
        </div>
    );
}