import React, { useEffect, useState } from "react";
import HeaderDropdown from "../../../../components/header_dropdown";
import styles from './index.module.css';
import { Area, LineChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Line } from "recharts";

function CustomXAxis({ x, y, payload }) {
    console.log(payload);
    const current_date = payload?.value;
    const monthsInSpanish = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    const date = new Date(current_date);
    const monthIndex = date.getMonth();

    return (
        <text x={x} y={y} dy={16} textAnchor="middle" fill="#666">
            {monthsInSpanish[monthIndex + 1]}
        </text>
    )
}

function CustomToolTipRisk({ active, payload, label, unit }) {
    if (active) {
        if (label === undefined) return <div>-</div>

        return (
            <div style={{ backgroundColor: '#FFF', border: '1px solid #000', borderRadius: '6px', textAlign: 'left' }} className={'p-1'}>
                {payload &&
                    <>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#F66565'
                            }} />
                            <div>Riesgo Alto: {parseInt(payload[0]?.value)}<b>{unit}</b></div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#FFC90C'
                            }} />
                            <div>Riesgo Moderado: {parseInt(payload[1]?.value)}<b>{unit}</b></div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#B3C739'
                            }} />
                            <div>Riesgo Bajo: {parseInt(payload[2]?.value)}<b>{unit}</b></div>
                        </div>
                    </>}
            </div>
        );
    }

    return null;
}

function CustomToolTipSleep({ active, payload, label, unit }) {
    if (active) {
        if (label === undefined) return <div>-</div>
        const day = parseInt(payload[0]?.value);
        const dayHours = Math.floor(day / 60);
        const dayMinutes = day % 60;
        const night = parseInt(payload[1]?.value);
        const nightHours = Math.floor(night / 60);
        const nightMinutes = night % 60;
        const dayFormatted = `${dayHours}h ${dayMinutes < 10 ? "0" + dayMinutes : dayMinutes}m`;
        const nightFormatted = `${nightHours}h ${nightMinutes < 10 ? "0" + nightMinutes : nightMinutes}m`;
        return (
            <div style={{ backgroundColor: '#FFF', border: '1px solid #000', borderRadius: '6px', textAlign: 'left' }} className={'p-1'}>
                {payload &&
                    <>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#239290'
                            }} />
                            <div>Día: {dayFormatted}</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#FFAA00'
                            }} />
                            <div>Noche: {nightFormatted}</div>
                        </div>
                    </>
                }
            </div>
        )
    }

    return null;
}

function CustomToolTipCritical({ active, payload, label, unit }) {
    if (active) {
        if (label === undefined) return <div>-</div>

        return (
            <div style={{ backgroundColor: '#FFF', border: '1px solid #000', borderRadius: '6px', textAlign: 'left' }} className={'p-1'}>
                {payload &&
                    <>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#239290'
                            }} />
                            <div>Día: {parseInt(payload[0]?.value)}<b>{unit}</b></div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#FFAA00'
                            }} />
                            <div>Noche: {parseInt(payload[1]?.value)}<b>{unit}</b></div>
                        </div>
                    </>
                }
            </div>
        );
    }

    return null;
}

function CustomToolTipUsed({ active, payload, label, unit }) {
    if (active) {
        if (label === undefined) return <div>-</div>

        return (
            <div style={{ backgroundColor: '#FFF', border: '1px solid #000', borderRadius: '6px', textAlign: 'left' }} className={'p-1'}>
                {payload &&
                    <>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#B3C739'
                            }} />
                            <div>Usaron: {parseInt(payload[0]?.value)}<b>{unit}</b></div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#F66565'
                            }} />
                            <div>No Usaron: {parseInt(payload[1]?.value)}<b>{unit}</b></div>
                        </div>
                    </>
                }
            </div>
        );
    }

    return null;
}

function GenericGraph({ title, data, format, unit, tooltip, tickFormatter }) {
    return (
        <div className={styles.graphContainer}>
            <div className={'d-flex justify-content-between'}>
                <div>{title}</div>
                <div style={{ flexBasis: '40%' }}>
                    <div className={'d-flex align-items-center justify-content-between'}>
                        {format.map((entry) => {
                            return (
                                <div className={'d-flex align-items-center'}>
                                    <div style={{ backgroundColor: entry.hex, borderRadius: '50%', width: '20px', height: '20px' }} />
                                    <div className={'ps-1'} style={{ whiteSpace: 'nowrap', fontFamily: 'Poppins, sans-serif', fontSize: '12px', letterSpacing: '0', fontWeight: '400', lineHeight: '18px' }}>{entry.name}</div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
            <ResponsiveContainer width={'100%'} height={300}>
                <LineChart width={'100%'} height={300} data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <defs>
                        {format.map((entry) => {
                            return (
                                <linearGradient id={entry.key} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={entry.hex} stopOpacity={0.8} />
                                    <stop offset="95%" stopColor={entry.hex} stopOpacity={0} />
                                </linearGradient>
                            )
                        })
                        }
                    </defs>
                    <Tooltip content={tooltip} />
                    <XAxis dataKey="name" tick={<CustomXAxis />} />
                    <YAxis
                        padding={{ top: 30 }}
                        label={{
                            value: unit, position: 'insideTop',
                            fill: '#807f7f', fontSize: '14px', fontWeight: '600'
                        }}
                        tickFormatter={tickFormatter}

                    />
                    {
                        format.map((entry) => {
                            return <Line dataKey={entry.key} stroke={entry.hex} fillOpacity={1} fill={`url(#${entry.key})`} />
                        })
                    }
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default function Evolutive({ data }) {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <HeaderDropdown open={open} setOpen={setOpen} name={'Estadística Evolutiva'} />

            {open &&
                <>
                    <GenericGraph tooltip={<CustomToolTipRisk unit={'%'} />} title={'Clasificación de Riesgo'} unit={'%'} data={data?.risk_data} format={[
                        { hex: '#F66565', name: 'Riesgo Alto', key: 'high_risk' },
                        { hex: '#FFC90C', name: 'Riesgo Moderado', key: 'mid_risk' },
                        { hex: '#B3C739', name: 'Riesgo Bajo', key: 'low_risk' },
                    ]} />

                    <GenericGraph tooltip={<CustomToolTipSleep unit={'min.'} />} title={'Tiempo Total de Sueño'} unit={'Hor.'} data={data?.sleep_time} format={[
                        { hex: '#239290', name: 'Día', key: 'day_minutes' },
                        { hex: '#FFAA00', name: 'Noche', key: 'night_minutes' },
                    ]} tickFormatter={(value) => {
                        const hours = Math.floor(value / 60);
                        const fract_minutes = value % 60;
                        return `${hours}h ${fract_minutes < 10 ? "0" + fract_minutes : fract_minutes}m`
                    }}
                    />

                    <GenericGraph tooltip={<CustomToolTipCritical unit={''} />} title={'Ocurrencia de Riesgo Muy Crítico'} unit={'Ocurr.'} data={data?.risk_data} format={[
                        { hex: '#239290', name: 'Día', key: 'critical_day' },
                        { hex: '#FFAA00', name: 'Noche', key: 'critical_night' },
                    ]} />

                    <GenericGraph tooltip={<CustomToolTipUsed unit={'%'} />} title={'Uso del Sistema'} unit={'Ocurr.'} data={data?.usage} format={[
                        { hex: '#B3C739', name: 'Usaron', key: 'used_percent' },
                        { hex: '#F66565', name: 'No Usaron', key: 'not_used_percent' },
                    ]} />
                </>
            }
        </div>
    )
}