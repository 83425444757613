import React, { useContext, useEffect, useState } from "react";
import Header from "../../dashboard/components/header";
import HeaderCreate from "../../../components/header_create";
import MainContext from "../../../context";
import Table from "../../../components/ajonjolib/table/table";
import { Button } from "../../../components/ajonjolib/inputs/ajonjolinput";
import BandsModal from "../company/modals/bands";
import ExportModal from "../company/modals/export";
import { eventBus, openModal } from "../../../components/ajonjolib/toasts/toast/toast";
import ImportModal from "../company/modals/import";
import BandsCreate from "./create";
import api from "../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HubsModal from "../company/modals/hubs";


export default function Hubs() {
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [openCreate, setOpenCreate] = useState(false);

    const { setSettings } = useContext(MainContext);
    useEffect(() => {
        document.title = "Sleeptech - Hubs";
        setSettings((prev) => {
            return {
                ...prev, sidebar: 3, submenu: 6
            }
        });

        api.get('hubs/').then(response => {
            setRows(response.data);
        })
    }, [setSettings]);

    const retrieveData = () => {
        api.get('hubs/').then(response => {
            setRows(response.data);
        });
    }

    const cols = [
        {
            title: 'Estado', key: 'status', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <div className={'me-2'} style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: data ? '#00C853' : '#EAEAEC' }} />
                        <div>{data}</div>
                    </div>
                )
            }
        },
        { title: 'Serial', key: 'hub_code' },
        { title: 'Nombre', key: 'name' },
        { title: 'Empresa', key: 'subenterprise.name' },
        { title: 'Grupo de Proyecto', key: 'project_group.name' },
        { title: 'Proyecto', key: 'project.name' },
        { title: 'Guardia', key: 'guard.name' },
        { title: 'Llave de Hub', key: 'api_key' },
        {
            title: 'Acciones', key: 'actions', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faEdit} color={'#5863B4'} size={'lg'} role={'button'} onClick={() => {
                            eventBus.emit('hubs_modal_open', row);
                        }} />
                    </div>
                )
            }
        }
    ];

    const onSearch = (text) => {
        setSearchText(text);
    }

    const onSubmitFile = async (file) => {
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file);
        const response = await api.post('hub/all/import/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        if (response?.status !== 200) return null;
        toast.success('Hubs importados correctamente');
        api.get('hubs/').then(response => {
            setRows(response.data);
        })
        return response.data.errors ?? [];
    }

    const filteredRows = rows.filter((row) => {
        const text = searchText.toLowerCase();
        return row.hub_code.toLowerCase().includes(text) ||
            row.name.toLowerCase().includes(text) ||
            row.subenterprise?.name?.toLowerCase().includes(text) ||
            row.project_group?.name?.toLowerCase()?.includes(text) ||
            row.project?.name?.toLowerCase()?.includes(text) ||
            row.guard?.name?.toLowerCase()?.includes(text)
    }
    );

    return (
        // Set height to 100%
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <HubsModal onSuccess={() => {
                toast.success('Hub actualizado correctamente');
                retrieveData();
            }} />
            <ExportModal title={'Master de Hubs'} />
            <ImportModal title={'Master de Hubs'} submit={onSubmitFile} />
            <Header title={'Master de Hubs'}>
                <>
                    <div>
                        <div className={'searchBox'}>
                            <div>
                                <img src={'/icons/search.png'} alt={'search'} width={'auto'} height={'24px'} />
                            </div>
                            <input type={'text'}
                                placeholder={'Buscar...'}
                                onChange={(e) => {
                                    onSearch(e.target.value);
                                }
                                }
                            />
                        </div>
                    </div>
                    <div className={'d-flex align-items-center'}>
                        <Button variant={'primary'} name={'Importar'} icon={'/icons/import.png'} onSubmit={() => {
                            openModal('import_modal', { url: 'hubs/all/export' })
                        }} />
                        <div className={'ms-3'} />
                        <Button variant={'primary'} name={'Exportar'} icon={'/icons/export.png'} onSubmit={() => {
                            openModal('export_modal', { url: 'hubs/all/export' })
                        }} />
                    </div>
                </>
            </Header>
            <HeaderCreate name={'Lista de Hubs'} width={'931px'}
                open={openCreate} setOpen={setOpenCreate}>
                <BandsCreate onSuccess={() => {
                    toast.success('Hub creado correctamente');
                    setOpenCreate(false);
                    api.get('hubs/').then(response => {
                        setRows(response.data);
                    })
                }} />
            </HeaderCreate>
            <div className={'mb-3'} />
            <div style={{ flex: 1, overflowY: 'scroll', overflowX: 'scroll' }}>
                <Table cols={cols} rows={filteredRows} />
            </div>
            <ToastContainer />
        </div>
    )
}