import React, { useEffect, useState } from "react";
import { Label, Select, Text } from "../../../components/ajonjolib/inputs/ajonjolinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../api";


export default function BandsCreate({ onSuccess }) {
    const [form, setForm] = useState({})
    const [enterprise, setEnterprise] = useState([]);
    const [guard, setGuard] = useState([]);
    const [groupProject, setGroupProject] = useState([]);
    const [project, setProject] = useState([]);

    useEffect(() => {
        api.get('subenterprises/').then((response) => {
            if (response?.status !== 200) return;
            setEnterprise(response.data);
        }
        );
        api.get('project-groups/').then((response) => {
            if (response?.status !== 200) return;
            setGroupProject(response.data);
        });
    }, []);

    useEffect(() => {
        setForm({
            ...form,
            project: undefined
        });
        api.get('projects/?project_group=' + form['project_group']).then((response) => {
            if (response?.status !== 200) return;
            setProject(response.data);
        })
    }, [form['project_group']]);

    useEffect(() => {
        setForm({
            ...form,
            guard: undefined
        });
        api.get('guards/?project=' + form['project'] + '&project_group=' + form['project_group']).then((response) => {
            if (response?.status !== 200) return;
            setGuard(response.data);
        })
    }, [form['project']]);

    const submit = () => {
        api.post('hubs/', form).then((response) => {
            if (response?.status !== 201) return;
            onSuccess();

        });
    }

    return (
        <>
            <div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Serial</Label>
                        <Text value={form['hub_code']} onChange={(val) => {
                            setForm({ ...form, hub_code: val })
                        }} />
                    </div>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Nombre</Label>
                        <Text value={form['name']} onChange={(val) => {
                            setForm({ ...form, name: val })
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Empresa</Label>
                        <Select value={form['subenterprise']} options={enterprise?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} onChange={(val) => {
                            setForm({ ...form, subenterprise: val[0] })
                        }} />
                    </div>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Grupo de Proyecto</Label>
                        <Select value={form['project_group']} options={groupProject?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} onChange={(val) => {
                            setForm({ ...form, project_group: val[0] })
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Proyecto</Label>
                        <Select value={form['project']} onChange={(val) => {
                            setForm({
                                ...form,
                                project: val[0],
                            });
                        }} options={project?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} />
                    </div>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Guardia</Label>
                        <Select value={form['guard']} onChange={(val) => {
                            setForm({ ...form, guard: val[0] });
                        }} options={guard?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} />
                    </div>
                </div>
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'} />
                <div style={{ color: '#5863B4' }}>Guardar</div>
            </div>
        </>
    )
}