import React, { useContext, useEffect, useState } from "react";
import Header from "../../dashboard/components/header";
import HeaderCreate from "../../../components/header_create";
import MainContext from "../../../context";
import Table from "../../../components/ajonjolib/table/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import AccessModal from "../company/modals/access";
import AccessCreate from "./create";
import api from "../../../api";
import { eventBus } from "../../../components/ajonjolib/toasts/toast/toast";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Access component for managing user access settings.
 * 
 * @component
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <Access />
 * 
 * @description
 * This component fetches and displays a list of users with their access settings.
 * It includes search functionality, user creation, and user update features.
 * 
 * @function
 * @name Access
 * 
 * @property {Array} rows - The state variable that holds the list of users.
 * @property {Function} setRows - The state setter function for rows.
 * @property {string} searchText - The state variable for the search text.
 * @property {Function} setSearchText - The state setter function for searchText.
 * @property {boolean} openCreate - The state variable that controls the visibility of the user creation modal.
 * @property {Function} setOpenCreate - The state setter function for openCreate.
 * 
 * @hook
 * @name useContext
 * @description Uses the MainContext to set sidebar and submenu settings.
 * 
 * @hook
 * @name useEffect
 * @description Sets the document title and fetches the initial list of users.
 * 
 * @function
 * @name retrieveData
 * @description Fetches the list of users from the API and updates the rows state.
 * 
 * @constant
 * @name cols
 * @description Defines the columns for the user table, including custom rendering logic for some columns.
 * 
 * @function
 * @name onSearch
 * @description Updates the searchText state with the provided text.
 * 
 * @constant
 * @name filteredRows
 * @description Filters the rows based on the searchText.
 * 
 * @returns {JSX.Element} The rendered Access component.
 */
export default function Access() {
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [openCreate, setOpenCreate] = useState(false);

    const { setSettings } = useContext(MainContext);
    useEffect(() => {
        document.title = "Sleeptech - Accesos";
        setSettings((prev) => {
            return {
                ...prev, sidebar: 3, submenu: 1
            }
        });

        api.get('users/').then(response => {
            setRows(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [setSettings]);

    const retrieveData = () => {
        api.get('users/').then(response => {
            setRows(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }


    const cols = [
        {
            title: 'Estado', key: 'is_active', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <div className={'me-2'} style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: data ? '#00C853' : '#EAEAEC' }} />
                    </div>
                )
            }
        },
        {
            title: 'Empresa', key: 'subenterprise.name', code: (row, data) => {
                if (data === null || data === '') {
                    return 'TODOS'
                }
                return data
            }
        },
        {
            title: 'Grupo de Proyecto', key: 'project_group.name', code: (row, data) => {
                if (data === null || data === '') {
                    return 'TODOS'
                }
                return data
            }
        },
        {
            title: 'Proyecto', key: 'project.name', code: (row, data) => {
                if (data === null || data === '') {
                    return 'TODOS'
                }
                return data
            }
        },
        { title: 'Nombre de Usuario', key: 'username' },
        { title: 'Documento', key: 'documentId' },
        { title: 'E-Mail', key: 'email' },
        {
            title: 'Acciones', key: 'actions', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faEdit} color={'#5863B4'} size={'lg'} role={'button'} onClick={() => {
                            eventBus.emit('access_modal_open', row);
                        }} />
                    </div>
                )
            }
        }
    ];

    const onSearch = (text) => {
        setSearchText(text);
    }

    const filteredRows = rows.length > 0 ? rows.filter((row) => {
        const text = searchText.toLowerCase();
        return (
            row.project_group?.name?.toLowerCase().includes(text) ||
            row.project?.name?.toLowerCase().includes(text) ||
            row.subenterprise?.name?.toLowerCase().includes(text) ||
            row.username?.toLowerCase().includes(text) ||
            row.documentId?.toLowerCase().includes(text) ||
            row.email?.toLowerCase().includes(text)
        );
    }) : [];

    return (
        <div>
            <AccessModal onSuccess={() => {
                toast.success('Usuario actualizado correctamente');
                retrieveData();
            }} />

            <Header title={'Accesos'}>
                <div className={'searchBox'}>
                    <div>
                        <img src={'/icons/search.png'} alt={'search'} width={'auto'} height={'24px'} />
                    </div>
                    <input type={'text'} placeholder={'Buscar...'}
                        onChange={(e) => {
                            onSearch(e.target.value);
                        }
                        }
                    />
                </div>
            </Header>
            <HeaderCreate name={'Lista de Usuarios'} width={'622px'} height={'auto'}
                open={openCreate} setOpen={setOpenCreate}>
                <AccessCreate onSuccess={() => {
                    toast.success('Usuario creado correctamente');
                    setOpenCreate(false);
                    retrieveData();
                }
                } />
            </HeaderCreate>
            <div className={'mb-3'} />
            <Table cols={cols} rows={filteredRows} />

        </div>
    )
}