import React, { useEffect, useState } from "react";
import styles from './modals.module.css'
import dashboardStyles from '../../../../screens/dashboard/components/filters/index.module.css';
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import { Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import Switch from "../../../../components/ajonjolib/switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faFile } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

/**
 * ExportGuardModal component renders a modal for exporting guard reports.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onSuccess - Callback function to be called on successful export.
 * @param {Object} props.company - The company object containing company details.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <ExportGuardModal onSuccess={handleSuccess} company={companyData} />
 * 
 * @typedef {Object} Form
 * @property {string} project_group - The selected project group.
 * @property {string} project - The selected project.
 * @property {string} guard - The selected guard.
 * @property {Date} start_date - The start date of the report range.
 * @property {Date} end_date - The end date of the report range.
 * 
 * @typedef {Object} ProjectGroup
 * @property {string} id - The ID of the project group.
 * @property {string} name - The name of the project group.
 * 
 * @typedef {Object} Project
 * @property {string} id - The ID of the project.
 * @property {string} name - The name of the project.
 * 
 * @typedef {Object} Guard
 * @property {string} id - The ID of the guard.
 * @property {string} name - The name of the guard.
 * 
 * @typedef {Object} ApiResponse
 * @property {number} status - The status code of the response.
 * @property {Array<ProjectGroup|Project|Guard>} data - The data returned from the API.
 */
export default function ExportGuardModal({ onSuccess, company }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [form, setForm] = React.useState({});
    const [projectGroups, setProjectGroups] = useState([]);
    const [projects, setProjects] = useState([]);
    const [guards, setGuards] = useState([]);
    const [loadingData, setLoadingData] = useState(false);


    useEffect(() => {
        api.get('project-groups/?subenterprise=' + company?.id).then((res) => {
            if (res?.status !== 200) {
                setProjectGroups([]);
                return;
            }
            setProjectGroups(res.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [isOpen]);

    useEffect(() => {
        api.get('projects/?project_group=' + form?.project_group).then((res) => {
            if (res?.status !== 200) {
                setForm({ ...form, project: undefined });
                setProjects([]);
                return;
            }
            setProjects(res.data);
        }
        ).catch(error => {
            console.log(error);
        }
        );
    }, [form.project_group]);

    useEffect(() => {
        api.get('guards/?project=' + form?.project).then((res) => {
            if (res?.status !== 200) {
                setForm({ ...form, guard: undefined });
                setGuards([]);
                return;
            }
            setGuards(res.data);
        }
        );
    }, [form.project]);

    useEffect(() => {
        eventBus.subscribe('export_guard_modal_open', (rec_data) => {
            setIsOpen(true);

        });
        eventBus.subscribe('export_guard_modal_close', (data) => { setIsOpen(false) });
    }, []);

    const submit = () => {
        setLoadingData(true);
        const start_date = form.start_date?.toISOString().split('T')[0];
        const end_date = form.end_date?.toISOString().split('T')[0];
        api.get(`reports/guard/?guard_id=${form.project}&start_date=${start_date}&end_date=${end_date}`, {
            responseType: 'blob'
        }).then((response) => {
            if (response?.status !== 200) return;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url
            link.setAttribute('download', 'reporte.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }).finally(() => {
            setLoadingData(false);
        })
            .catch(error => {
                console.log(error);
            }
            );

    }

    return (
        <div>
            {isOpen &&
                <Modal close={() => setIsOpen(false)} title={'Guardia'} desc={'Exportar'}
                    // Set width to 200
                    style={{ width: '160px' }}
                >
                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '100%' }}>
                            <Label>Grupo de Proyecto</Label>
                            <Select value={form['project_group']} options={projectGroups?.map((x) => {
                                return {
                                    value: x.id,
                                    name: x.name ?? ''
                                }
                            })} onChange={(val) => {
                                if (val[0] === form['project_group']) return;
                                setForm({ ...form, project_group: val[0], project: undefined });
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '100%' }}>
                            <Label>Proyecto</Label>
                            <Select value={form['project']} options={projects?.map((x) => {
                                return {
                                    value: x.id,
                                    name: x.name ?? ''
                                }
                            })} onChange={(val) => {
                                setForm({ ...form, project: val[0] })
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '100%' }}>
                            <Label>Guardia</Label>
                            <Select value={form['guard']} options={guards?.map((x) => {
                                return {
                                    value: x.id,
                                    name: x.name ?? ''
                                }
                            })} onChange={(val) => {
                                setForm({ ...form, guard: val[0] })
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '100%' }}>
                            <Label>Rango de Fecha</Label>
                            <div className={dashboardStyles.dateFilter} style={{
                                marginBottom: '10px',
                            }}>
                                <DatePicker
                                    selectsRange={true}
                                    startDate={form['start_date']}
                                    endDate={form['end_date']}
                                    locale="es"
                                    dateFormat={'dd/MM/yyyy'}
                                    onChange={(dates) => {
                                        setForm({
                                            ...form,
                                            start_date: dates[0],
                                            end_date: dates[1]
                                        });
                                    }
                                    }
                                    className={dashboardStyles.datePicker}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '20px' }}>
                        <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                            {loadingData ?
                                <div className={styles.button}>
                                    <FontAwesomeIcon icon={faSave} color={'#52A035'} />
                                    <div className={'ps-2'}>Descargando...</div>
                                </div>
                                :
                                <div className={styles.button} onClick={submit}>
                                    <FontAwesomeIcon icon={faFile} color={'#52A035'} />
                                    <div className={'ps-2'}>Descargar Reporte</div>
                                </div>
                            }
                        </div>
                    </div>

                </Modal>
            }
        </div >
    )
}