import React, { useEffect } from "react"
import { Select } from "../../ajonjolib/inputs/ajonjolinput";
import styles from "../../../screens/dashboard/components/filters/index.module.css";
import { currentDateStr } from "../../../utils/date";
import DatePicker from 'react-datepicker';


/**
 * ResultsFilters component renders a series of filter options for the results screen.
 *
 * @param {Object} props - The properties object.
 * @param {Object} props.filters - The current filter values.
 * @param {Function} props.setFilters - Function to update the filter values.
 * @param {Array} props.projects - List of projects to choose from.
 * @param {Array} props.projectGroups - List of project groups to choose from.
 * @param {Array} props.guards - List of guards to choose from.
 * @param {Array} props.companies - List of companies to choose from.
 *
 * @returns {JSX.Element} The rendered component.
 */
export default function ResultsFilters({ filters, setFilters, projects, projectGroups, guards, companies }) {
    return (
        <>
            <div>Empresa</div>
            <Select className={'mb-4'} value={[filters['company']]}
                options={
                    [
                        { name: "Todas", value: -1 },
                        ...companies?.map((x) => {
                            return { name: x.name, value: x.id };
                        })]
                } onChange={(val) => {
                    if (val[0] === -1) {
                        setFilters({
                            ...filters,
                            company: -1,
                            project_group: -1,
                            project: -1,
                            guard: -1,
                        });
                        return;
                    }
                    setFilters({
                        ...filters,
                        company: val[0]
                    })
                }} />

            <div>Grupo de Proyecto</div>
            <Select className={'mb-4'} value={[filters['project_group']]} onChange={(val) => {

                if (val[0] === -1) {
                    setFilters({
                        ...filters,
                        project_group: -1,
                        project: -1,
                        guard: -1,
                    });
                    return;
                }

                setFilters({
                    ...filters,
                    project_group: val[0]
                });
            }} options={
                [
                    { name: "Todos", value: -1 },
                    ...projectGroups?.map((x) => {
                        return { name: x.name, value: x.id }
                    })]
            } />

            <div>Proyecto</div>
            <Select className={'mb-4'} value={[filters['project']]} onChange={(val) => {

                if (val[0] === -1) {
                    setFilters({
                        ...filters,
                        project: -1,
                        guard: -1,
                    });
                    return;
                }

                setFilters({
                    ...filters,
                    project: val[0]
                });
            }} options={
                [
                    { name: "Todos", value: -1 },
                    ...projects?.map((x) => {
                        return { name: x.name, value: x.id }
                    })]
            } />

            <div>Guardia</div>
            <Select className={'mb-4'} value={[filters['guard']]} onChange={(val) => {
                setFilters({
                    ...filters,
                    guard: val[0]
                });
            }} options={[
                { name: "Todas", value: -1 },
                ...guards?.map((x) => {
                    return { name: x.name, value: x.id }
                })]} />

            <div>Nivel de Riesgo</div>
            <Select className={'mb-4'} value={[filters['risk_level']]} onChange={(val) => {
                setFilters({
                    ...filters,
                    risk_level: val[0]
                });
            }} options={
                [{ name: "Todos", value: "all" },
                { name: "Bajo", value: "low" },
                { name: "Medio", value: "mid" },
                { name: "Alto", value: "high" },
                { name: "Crítico", value: "critical" },
                { name: "Desconocido", value: "unknown" }]} />

            <div>Fecha Consulta</div>
            <DatePicker
                selected={filters['date']}
                locale="es"
                dateFormat={'dd/MM/yyyy'}
                onChange={(date) => {
                    setFilters({
                        ...filters,
                        date: date
                    });
                }
                }
                className={styles.datePicker}
            />
        </>
    )
}