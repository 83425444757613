import React, { useState, useEffect } from 'react';
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import styles from "./modals.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { useFilePicker } from 'use-file-picker';


export default function ImportModal({ title, submit }) {
    const [isOpen, setIsOpen] = useState(false);

    const { openFilePicker, filesContent, loading } = useFilePicker({
        accept: '.xlsx',
        multiple: false,
        readAs: 'ArrayBuffer',
    });
    const [fileName, setFileName] = useState('');


    useEffect(() => {
        eventBus.subscribe('import_modal_open', (rec_data) => {
            setIsOpen(true);
        });
        eventBus.subscribe('import_modal_close', (data) => { setIsOpen(false) });
    }, []);

    const handleFileChange = (files) => {
        if (files.length > 0) {
            setFileName(files[0].name); // Guardar el nombre del archivo seleccionado
        }
    };

    useEffect(() => {
        if (filesContent.length > 0) {
            handleFileChange(filesContent);
        }
    }, [filesContent]);

    return (
        <div>
            {isOpen &&
                <Modal title={title} desc={"Importar"} close={() => setIsOpen(false)}>

                    <div className={styles.importContainer}>
                        {/* Show buttons and file name in column */}
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            {fileName && (
                                <div className={styles.fileName}>
                                    <span style={{ color: '#6A86C8' }}>Archivo seleccionado: {fileName}</span>
                                </div>
                            )}
                            <div className='d-flex flex-row justify-content-center align-items-center mt-2' style={{ gap: '1rem' }}>
                                <div className={styles.importButton} onClick={() => openFilePicker()}>Examinar Equipo</div>
                                <div style={{ color: '#C3D1EF' }}>o</div>
                                <div>
                                    <svg width="57" height="37" viewBox="0 0 57 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M48.2712 10.7801C46.3856 4.55174 40.3931 0 33.2907 0C27.9364 0 23.212 2.58699 20.4027 6.53618C18.9711 5.41302 17.1446 4.73837 15.1511 4.73837C10.5694 4.73837 6.85379 8.30119 6.85379 12.6957C6.85379 13.439 6.96167 14.1562 7.15996 14.8406C2.94154 16.6449 0 20.7023 0 25.4177C0 31.813 5.4029 37 12.0686 37H24.1286V26.7996H17.5723L28.4998 14.4671L39.4238 26.7996H32.8694V37H42.7848C50.6366 37 57 30.8928 57 23.3613C57 17.6927 53.3994 12.8366 48.2712 10.7801Z" fill="#C3D1EF" />
                                    </svg>
                                    <div className={'mt-2'} style={{ color: '#6A86C8' }}>Arrastra tu archivo aquí</div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                        <div className={styles.button} onClick={() => {
                            setIsOpen(false);
                            console.log('filesContent', filesContent);
                            const file = filesContent?.at(0);
                            if (!file) return;
                            const { content, name } = file;

                            const blob = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                            const fileObj = new File([blob], name, { type: blob.type });
                            if (submit) submit(fileObj);
                        }}>
                            <FontAwesomeIcon icon={faSave} color={'#52A035'} />
                            <div className={'ps-2'}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}