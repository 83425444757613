import React from "react";
import styles from './index.module.css'

export default function TextArea({value, onChange, placeholder}) {
    return (
        <div className={styles.container}>
            <textarea value={value} onChange={(event) => {
                onChange&&onChange(event.target.value);
            }} placeholder={placeholder}/>
        </div>
    )
}