import React, { useContext, useEffect, useState } from "react";
import Header from "../../dashboard/components/header";
import CompanyDetailHeader from "./components/company_detail_header/company_detail_header";
import CompanyData from "./sections/company_data";
import Group from "./sections/group";
import Project from "./sections/project";
import Guard from "./sections/guard";
import Email from "./sections/email";
import MainContext from "../../../context";
import GroupModal from "./modals/group";
import ProjectModal from "./modals/project";
import GuardModal from "./modals/guard";
import EmailModal from "./modals/email";
import { useParams } from "react-router-dom";
import api from "../../../api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CompanyDetail() {
    const [selected, setSelected] = React.useState(0);
    const { setSettings } = useContext(MainContext);
    const { id } = useParams();
    const [company, setCompany] = useState({});
    const [reload, setReload] = useState(false);

    useEffect(() => {
        document.title = "Sleeptech - Empresa";
        setSettings((prev) => {
            return {
                ...prev, sidebar: 3, submenu: 0
            }
        });

        api.get(`subenterprises/${id}/`).then((response) => {
            setCompany(response.data);
        });
    }, [setSettings]);

    const renderSection = () => {
        if (selected === 0) return (<CompanyData company={company} />)
        if (selected === 1) return (<Group company={company} />)
        if (selected === 2) return (<Project company={company} />)
        if (selected === 3) return (<Guard company={company} />)
        if (selected === 4) return (<Email company={company} />)
    }

    const onCreate = () => {
        const last = selected;
        setSelected(-1);
        setTimeout(() => {
            setSelected(last);
        }, 100);
    }

    return (
        <div>
            <GroupModal onSuccess={() => { toast.success('Grupo editado correctamente'); onCreate(); }} company={company} />
            <ProjectModal onSuccess={() => { toast.success('Proyecto editado correctamente'); onCreate(); }} company={company} />
            <GuardModal onSuccess={() => { toast.success('Guardia editada correctamente'); onCreate(); }} company={company} />
            <EmailModal onSuccess={() => { toast.success('Email editado correctamente'); onCreate(); }} company={company} />

            <Header title={'Empresa'} subtitle={'Stracon'} />
            <CompanyDetailHeader selected={selected} setSelected={setSelected}
                company={company} onCreate={onCreate} />
            <div className={'mb-3'} />
            {renderSection()}
            <ToastContainer />
        </div>
    );
}