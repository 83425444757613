import React, { useState } from "react";
import styles from "./company_detail_header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import GroupCreate from "../../create_popups/group";
import ProjectCreate from "../../create_popups/project";
import GuardCreate from "../../create_popups/guard";
import EmailCreate from "../../create_popups/email";

/**
 * CompanyDetailHeader component renders a header with tabs and a create button.
 * 
 * @param {Object} props - The component props.
 * @param {number} props.selected - The index of the currently selected tab.
 * @param {Function} props.setSelected - Function to set the selected tab index.
 * @param {Object} props.company - The company data.
 * @param {Function} props.onCreate - Function to handle the creation of a new item.
 * 
 * @returns {JSX.Element} The rendered component.
 */
export default function CompanyDetailHeader({ selected, setSelected, company, onCreate }) {
    const [showCreate, setShowCreate] = useState(false);
    const tabs = [
        "Datos Empresa", "Grupo de Proyecto", "Proyecto", "Guardia", "E-Mail"
    ];

    const onCreateWithClose = () => {
        onCreate();
        setShowCreate(false);
    }

    const getPopup = () => {
        if (selected === 1) return (<GroupCreate company={company} onCreate={onCreateWithClose} />)
        if (selected === 2) return (<ProjectCreate company={company} onCreate={onCreateWithClose} />)
        if (selected === 3) return (<GuardCreate company={company} onCreate={onCreateWithClose} />)
        if (selected === 4) return (<EmailCreate company={company} onCreate={onCreateWithClose} />)
    }

    return (
        <div className={'d-flex justify-content-between align-items-center'}>
            <div className={styles.tabs}>
                {tabs.map((tab, index) => {
                    return (
                        <div key={index} className={`${styles.tab} ${selected === index ? styles.selected : ''}`} onClick={() => {
                            setSelected(index);
                            setShowCreate(false);
                        }}>
                            {tab}
                        </div>
                    )
                })
                }
            </div>

            <div className={styles.createButton} style={{ display: selected !== 0 ? 'flex' : 'none' }}>
                <div className={styles.createButtonInner} onClick={() => setShowCreate((prev) => !prev)}>
                    <FontAwesomeIcon className={'me-3'} icon={faPlus} color={'#5863B4'} size={'xl'} />
                    <div>Nuevo</div>
                </div>
                {showCreate && <div className={`${styles.createPopup} ${selected === 4 ? styles.largePopup : ''}`}>
                    {getPopup()}
                </div>}
            </div>
        </div>
    )
}