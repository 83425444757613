import React from "react";
import styles from "./text.module.css";

/**
 * Text input component.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.value - The value of the input.
 * @param {function} props.onChange - The function to call when the input value changes.
 * @param {string} [props.className] - The CSS class name for the input element.
 * @param {Object} [props.style] - The inline styles for the input element.
 * @param {boolean} [props.disabled] - Whether the input is disabled.
 * @param {string} [props.placeholder] - The placeholder text for the input.
 * @param {string} [props.type='text'] - The type of the input.
 * @returns {JSX.Element} The rendered text input component.
 */
export default function Text({ value, onChange, className, style, disabled, placeholder, type }) {
    return (
        <div className={styles.container}>
            <input style={style} className={className} type={type ? type : 'text'} placeholder={placeholder} value={value} disabled={disabled} onChange={(e) => {
                if (onChange) onChange(e.target.value);
            }} />
        </div>
    )
}