import React, { useEffect, useState } from "react";
import Table from "../../../../components/ajonjolib/table/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";

/**
 * Guard component renders a table with guard details.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.company - The company object.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <Guard company={companyData} />
 * 
 * @description
 * This component uses several hooks:
 * - `useState` to manage the state of the table rows.
 * - `useEffect` to fetch data from the API when the component mounts.
 * 
 * The component renders a table with guard details, including:
 * - Estado (active switch)
 * - Nombre de Guardia (guard name)
 * - Empresa (company)
 * - Acciones (edit and configure actions)
 */
export default function Guard({ company }) {
    const [rows, setRows] = useState([]);

    const cols = [
        {
            title: 'Estado', key: 'active', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <div className={'me-2'} style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: data ? '#00C853' : '#EAEAEC' }} />
                        <div>{data}</div>
                    </div>
                )
            }
        },
        { title: 'Grupo de Proyecto', key: 'project_group.name' },
        { title: 'Proyecto', key: 'project.name' },
        { title: 'Guardia', key: 'name' },
        { title: 'Total de Empleados', key: 'employees_count' },
        {
            title: 'Rango de Fechas', key: 'read', header: (title) => {
                return (
                    <div className={'d-flex justify-content-center flex-column align-items-center'}>
                        <div>{title}</div>
                        <div className={'d-flex justify-content-center align-items-center w-100'} style={{ color: '#FFF' }}>
                            <div className={'d-flex justify-content-center align-items-center me-3'} style={{ backgroundColor: '#5863B4', borderRadius: '5px 5px 0 0', width: '80px', height: '23px', fontSize: '11px' }}>Desde</div>
                            <div className={'d-flex justify-content-center align-items-center'} style={{ backgroundColor: '#5863B4', borderRadius: '5px 5px 0 0', width: '80px', height: '23px', fontSize: '11px' }}>Hasta</div>
                        </div>
                    </div>
                )
            }, code: (row, data) => {
                return (
                    <div className={'d-flex justify-content-between align-items-center w-100'}>
                        <div className={'me-3'}>{(new Date(row.start_date)).toLocaleDateString()}</div>
                        <div>{(new Date(row.end_date)).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            title: 'Acciones', key: 'actions', code: (row) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faEdit} color={'#5863B4'} size={'lg'} role={'button'} onClick={() => {
                            eventBus.emit('guard_modal_open', row);
                        }} />
                    </div>
                )
            }
        }
    ];

    useEffect(() => {
        api.get(`guards/?subenterprise=${company?.id}`).then((response) => {
            setRows(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, []);

    return (
        <div>
            <Table model={'guards'} cols={cols} rows={rows} />
        </div>
    )
}