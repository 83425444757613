import React, { useContext, useEffect, useState } from "react";
import styles from './index.module.css';
import { Button, Select, Date } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import MainContext from "../../../../context";
import api from "../../../../api";
import DashboardFilters from "../../../../components/filters/sections/dashboard";
import ResultsFilters from "../../../../components/filters/sections/results";
import DetailFilters from "../../../../components/filters/sections/detail";

/**
 * Filters component renders the filters section for the dashboard.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.filters - The filters object.
 * @param {Function} props.setFilters - The function to set the filters.
 * @param {string} props.type - The type of filters to render.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <Filters filters={{}} setFilters={() => {}} type={'dashboard'} />
 * 
 * @description
 * This component uses several hooks:
 * - `useState` to manage the state of the companies, project groups, projects, guards, and shifts.
 * - `useEffect` to fetch data from the API when the component mounts and when the filters change.
 * 
 * The component renders a title and the filters section, which includes:
 * - Companies (company selection)
 * - Project Groups (project group selection)
 * - Projects (project selection)
 * - Guards (guard selection)
 * - Shifts (shift selection)
 * - Date (date selection)
 */
export default function Filters({ filters, setFilters, type }) {
    const { setSettings, settings } = useContext(MainContext);
    const [companies, setCompanies] = useState([]);
    const [projectGroups, setProjectGroups] = useState([]);
    const [projects, setProjects] = useState([]);
    const [guards, setGuards] = useState([]);
    const [shifts, setShifts] = useState([]);

    useEffect(() => {
        if (!filters['company']) return;
        api.get(`project-groups/?subenterprise=${filters['company']}`).then((response) => {
            setProjectGroups(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [filters['company']]);

    useEffect(() => {
        if (!filters['project_group']) return;
        api.get(`projects/?project_group=${filters['project_group']}`).then((response) => {
            setProjects(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [filters['project_group']]);

    useEffect(() => {
        if (!filters['project']) return;
        api.get(`guards/?project=${filters['project']}&project_group=${filters['project_group']}`).then((response) => {
            setGuards(response.data);
        }).catch(error => {
            console.log(error);
        }
        );

        api.get(`turns/?project=${filters['project']}`).then((response) => {
            setShifts(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [filters['project']]);

    useEffect(() => {
        api.get('subenterprises/').then((response) => {
            setCompanies(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, []);

    const mapFilters = () => {
        if (type === 'dashboard') return (<DashboardFilters filters={filters} setFilters={setFilters} guards={guards} projectGroups={projectGroups} projects={projects} companies={companies} />);
        else if (type === 'results') return (<ResultsFilters filters={filters} setFilters={setFilters} guards={guards} projectGroups={projectGroups} projects={projects} companies={companies} />)
        else if (type === 'detailed') return <DetailFilters filters={filters} setFilters={setFilters} projectGroups={projectGroups} projects={projects} companies={companies} shifts={shifts} />
    }

    return (
        <div className={styles.container} style={{
            height: settings.filters ? '100%' : '77px',
            overflowY: settings.filters ? 'auto' : 'hidden'
        }}>
            <div className={styles.title} onClick={() => {
                setSettings({
                    ...settings,
                    filters: !settings.filters
                });
            }}>
                <img src={'/icons/filters.png'} alt={'filters'} style={{ width: '31px', height: '23px' }} />
                <div className={'ps-4'}>Filtros</div>
            </div>

            {mapFilters()}
        </div>
    )
}