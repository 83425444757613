import { createContext, useContext, useEffect, useState } from "react";
import { currentDateStr } from "./utils/date";

const FiltersContext = createContext(null);

/**
 * FiltersProvider component that provides the filters context to the children components.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {JSX.Element} props.children - The children components.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @description
 * This component uses the following hooks:
 * - `useState` to manage the filters state.
 * - `useEffect` to set the initial filters state.
 * 
 * The component provides the filters state to the children components
 * using the `FiltersContext.Provider`.
 * The filters state includes the following properties:
 * - date: The current date.
 * - start_date: The current date.
 * - end_date: The current date.
 * - company: The selected company.
 * - project_group: The selected project group.
 * - project: The selected project.
 * - guard: The selected guard.
 * - shift: The selected shift.
 */
function FiltersProvider({ children }) {
    const [filters, setFilters] = useState({});

    useEffect(() => {
        setFilters({
            ...filters,
            // Set date as now with %Y-%m-%d format
            date: Date.now(),
            start_date: Date.now(),
            end_date: Date.now(),
        });
    }, []);

    return (
        <FiltersContext.Provider value={[filters, setFilters]}>
            {children}
        </FiltersContext.Provider>
    )
}

export { FiltersContext, FiltersProvider };