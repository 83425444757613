import React, { useEffect, useState } from "react";
import { Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";
import { toast, ToastContainer } from "react-toastify";


/**
 * ProjectCreate component for creating a new project.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onCreate - Callback function to be called after project creation.
 * @param {Object} props.company - The company object containing company details.
 * @param {string} props.company.id - The ID of the company.
 *
 * @returns {JSX.Element} The rendered component.
 */
export default function ProjectCreate({ onCreate, company }) {
    const [form, setForm] = useState({ vCenBeneficio: "test", vDenominacion: "test" });
    const [projectGroups, setProjectGroups] = useState([]);

    const submit = () => {
        api.post('projects/', form).then((response) => {
            if (response.status === 201) {
                toast.success('Proyecto creado correctamente');
                onCreate();
            }
        });
    }

    useEffect(() => {
        api.get('project-groups/?subenterprise=' + company?.id).then((res) => {
            if (res?.status !== 200) return;
            setProjectGroups(res.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, []);

    return (
        <>
            <div>
                <Label>Grupo de Proyecto</Label>
                <Select value={form['project_group']} options={projectGroups?.map((x) => {
                    return {
                        value: x.id,
                        name: x.name
                    }
                })} onChange={(val) => {
                    setForm({ ...form, project_group: val[0] })
                }} />
                <div className={'mb-4'} />
                <Label>Zona Horaria</Label>
                <Text value={form['timezone']} onChange={(val) => {
                    setForm({ ...form, timezone: val })
                }} />
                <div className={'mb-4'} />
                <Label>Proyecto</Label>
                <Text value={form['name']} onChange={(val) => {
                    setForm({ ...form, name: val })
                }} />
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'} />
                <div style={{ color: '#5863B4' }}>Guardar</div>
            </div>
        </>
    )
}