import React, { useEffect, useState } from "react";
import { Label, Select, Text } from "../../../components/ajonjolib/inputs/ajonjolinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import styles from "../company/modals/modals.module.css";
import api from "../../../api";

function SexSelect({ form, setForm }) {
    const options = [
        { name: "Femenino", value: 'F' }, { name: "Masculino", value: 'M' }
    ];

    return (
        <div className={styles.dateContainer}>
            {options.map((gender, index) => (
                <div key={index} className={`${styles.dateItem} ${form['gender'] === gender.value ? styles.genderSelected : ''}`} onClick={() => {
                    setForm({ ...form, gender: gender.value });
                }}>
                    <div>{gender.name}</div>
                </div>
            ))}
        </div>
    )
}

export default function AccessCreate({ onSuccess }) {
    const [form, setForm] = useState({});
    const [companies, setCompanies] = useState([]);
    const [projectGroups, setProjectGroups] = useState([]);
    const [projects, setProjects] = useState([]);
    const [roles, setRoles] = useState([]);


    useEffect(() => {
        api.get('subenterprises/').then((response) => {
            setCompanies(response.data);
        });
    }, []);
    useEffect(() => {
        api.get('roles/').then((response) => {
            setRoles(response.data);
        });

    }, []);

    useEffect(() => {
        setForm({
            subenterprise: -1,
            project_group: -1,
            project: -1,
        });
    }, []);

    useEffect(() => {
        api.get('project-groups/?subenterprise=' + form['subenterprise']).then((response) => {
            if (response?.status !== 200) {
                setProjectGroups([]);
                return;
            }
            setProjectGroups(response.data);
        });
    }, [form['subenterprise']]);

    useEffect(() => {
        api.get('projects/?project_group=' + form['project_group']).then((response) => {
            if (response?.status !== 200) {
                setProjects([]);
                return;
            }
            setProjects(response.data);
        });
    }, [form['project_group']]);

    const submit = () => {
        const copy = { ...form };
        if (copy['subenterprise'] === -1) delete copy['subenterprise'];
        if (copy['project_group'] === -1) delete copy['project_group'];
        if (copy['project'] === -1) delete copy['project'];
        api.post('users/', form).then((response) => {
            if (response?.status !== 201) return;
            onSuccess();
        })
    }

    return (
        <>
            <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Empresa</Label>
                        <Select value={form['subenterprise']} options={
                            [...companies?.map((x) => {
                                return {
                                    name: x.name ?? '',
                                    value: x.id
                                }
                            }),
                            { name: "TODOS", value: -1 }
                            ]
                        }
                            onChange={(val) => {
                                if (val[0] === form['subenterprise']) return;
                                setForm({ ...form, subenterprise: val[0], project_group: -1, project: -1 });
                            }} />
                    </div>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Rol</Label>
                        <Select value={form['role']} options={roles?.map((x) => {
                            return {
                                name: x.name ?? '',
                                value: x.id
                            }
                        })} onChange={(val) => {
                            setForm({ ...form, role: val[0] })
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Grupo de Proyecto</Label>
                        <Select value={form['project_group']} options={
                            [...projectGroups?.map((x) => {
                                return {
                                    name: x.name ?? '',
                                    value: x.id
                                }
                            }),
                            { name: "TODOS", value: -1 }
                            ]
                        } onChange={(val) => {
                            if (val[0] === form['project_group']) return;
                            setForm({ ...form, project_group: val[0], project: -1 });

                        }} />
                    </div>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Proyecto</Label>
                        <Select value={form['project']} options={
                            [...projects?.map((x) => {
                                return {
                                    name: x.name ?? '-',
                                    value: x.id
                                }
                            }),
                            { name: "TODOS", value: -1 }
                            ]
                        } onChange={(val) => {
                            setForm({ ...form, project: val[0] })
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Apellidos</Label>
                        <Text value={form['last_name']} onChange={(val) => {
                            setForm({ ...form, last_name: val });
                        }} />
                    </div>

                    <div style={{ flexBasis: '45%' }}>
                        <Label>Nombres</Label>
                        <Text value={form['first_name']} onChange={(val) => {
                            setForm({ ...form, first_name: val });
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '30%' }}>
                        <Label>N° Documento</Label>
                        <Text value={form['documentId']} onChange={(val) => {
                            setForm({ ...form, documentId: val });
                        }} />
                    </div>

                    <div style={{ flexBasis: '65%' }}>
                        <Label>E-Mail</Label>
                        <Text value={form['email']} onChange={(val) => {
                            setForm({ ...form, email: val });
                        }} />
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Usuario Login</Label>
                        <Text value={form['username']} onChange={(val) => {
                            setForm({ ...form, username: val })
                        }} />
                    </div>

                    <div style={{ flexBasis: '45%' }}>
                        <Label>Contraseña Login</Label>
                        <Text value={form['password']} onChange={(val) => {
                            setForm({ ...form, password: val })
                        }} />
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Sexo</Label>
                        <SexSelect form={form} setForm={setForm} />
                    </div>

                    <div style={{ flexBasis: '45%' }}>
                        <Label>Fecha de Alta</Label>
                        <div className={styles.dateFilterOnly}>
                            <input type={'date'} value={form['dischargeDate']} onChange={(event) => {
                                setForm({ ...form, dischargeDate: event.target.value });
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'} />
                <div style={{ color: '#5863B4' }}>Guardar</div>
            </div>
        </>
    )
}