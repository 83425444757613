import axios from "axios";

let domain = process.env.REACT_APP_DOMAIN;
export let baseURL = "https://" + domain + "/";
export let wsURL = "wss://" + domain + "/ws/";
let refresh = false;

const axiosInstance = axios.create({
    baseURL: baseURL,
    withCredentials: false
});

axiosInstance.defaults.headers.common['Authorization'] = `Token ${localStorage.getItem('access_token')}`;
axiosInstance.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 401) {
        localStorage.clear();
        axiosInstance.defaults.headers.common['Authorization'] = null;
        window.location.href = '/login';
        refresh = true;
        axiosInstance.post('token/refresh/', {
            refresh: localStorage.getItem('refresh_token')
        }).then((response) => {
            if (response.status === 200) {
                //axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data['access_token']}`;
                //localStorage.setItem('access_token', response.data.access_token);
                window.location.reload();
            }
        }).catch((err) => {
            localStorage.clear();
            axiosInstance.defaults.headers.common['Authorization'] = null;
            window.location.href = '/login';
        });
    }
    refresh = false;
    return error;
});

export default axiosInstance;
