// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_buttonContainer__oytuo {
    display: flex;
    justify-content: space-between;
    padding: 10px 14px;
    align-items: center;
    background-color: #E2E5F4;
    border-radius: 10px;
}

.dashboard_button__5GW3F {
    width: 200px;
    height: 40px;
    border-radius: 9px;
    background-color: #FFFFFF;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #5863B4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}

.dashboard_selected__e9ZTg {
    background-color: #ADB6F8;
    color: #FFFFFF;
}

.dashboard_button__5GW3F:last-of-type {
    margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/screens/dashboard/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,yBAAyB;IACzB,kCAAkC;IAClC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".buttonContainer {\n    display: flex;\n    justify-content: space-between;\n    padding: 10px 14px;\n    align-items: center;\n    background-color: #E2E5F4;\n    border-radius: 10px;\n}\n\n.button {\n    width: 200px;\n    height: 40px;\n    border-radius: 9px;\n    background-color: #FFFFFF;\n    text-transform: uppercase;\n    font-family: 'Poppins', sans-serif;\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 18px;\n    text-align: left;\n    color: #5863B4;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-right: 10px;\n    user-select: none;\n    cursor: pointer;\n}\n\n.selected {\n    background-color: #ADB6F8;\n    color: #FFFFFF;\n}\n\n.button:last-of-type {\n    margin-right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": `dashboard_buttonContainer__oytuo`,
	"button": `dashboard_button__5GW3F`,
	"selected": `dashboard_selected__e9ZTg`
};
export default ___CSS_LOADER_EXPORT___;
