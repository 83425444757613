import React, { useEffect, useState } from "react";
import { Label, Select, Text, TextArea } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import TimePicker from "../../../../components/ajonjolib/inputs/time";
import styles from "../modals/modals.module.css";
import api from "../../../../api";
import { toast } from "react-toastify";


function DateSelect({ form, setForm }) {
    const dates = [
        "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"
    ];

    const [selected, setSelected] = useState([]);

    return (
        <div className={styles.dateContainer}>
            {dates.map((date, index) => (
                <div style={{
                    backgroundColor: selected.includes(index + 1) ? "#5863B4" : "#FFF",
                    color: selected.includes(index + 1) ? "#FFF" : "#5863B4"
                }} key={index} className={styles.dateItem} onClick={() => {
                    const value = index + 1;
                    let copy = [...selected];
                    if (copy.includes(value)) {
                        copy.splice(copy.indexOf(value), 1);
                    } else {
                        copy.push(value);
                    }
                    setSelected(copy);

                    setForm({
                        ...form,
                        days: copy
                    });
                }}>
                    <div>{date}</div>
                </div>
            ))}
        </div>
    )
}

function CovidSelect({ form, setForm }) {
    const options = [
        "Cargo", "Alerta COVID"
    ];

    const [selected, setSelected] = useState([
        false, false
    ]);

    useEffect(() => {
        setForm((prev) => {
            return {
                ...prev,
                show_covid: selected[1], show_position: selected[0]
            }
        })
    }, []);

    return (
        <div className={styles.dateContainer}>
            {options.map((date, index) => (
                <div style={{
                    backgroundColor: selected[index] ? "#5863B4" : "#FFF",
                    color: selected[index] ? "#FFF" : "#5863B4"
                }} key={index} className={styles.dateItem} onClick={() => {
                    let copy = [...selected];
                    copy[index] = !copy[index];
                    setSelected(copy);
                    setForm({ ...form, show_covid: copy[1], show_position: copy[0] });
                }}>
                    <div>{date}</div>
                </div>
            ))}
        </div>
    )
}

export default function EmailCreate({ onCreate, company }) {
    const configLevelOptions = [
        { value: "group", name: "Grupo de Proyecto" },
        { value: "project", name: "Proyecto" },
        { value: "guard", name: "Guardia" },
    ]
    const groupLevelOptions = [
        { value: "none", name: "Ninguno" },
        { value: "group", name: "Por Grupo" },
    ]
    const [groupProject, setGroupProject] = useState([]);
    const [shifts, setShifts] = useState([]);
    const [form, setForm] = useState({
        active: true
    });

    useEffect(() => {
        api.get(`project-groups/?subenterprise=${company?.id}`).then((response) => {
            if (response?.status !== 200) return;
            setGroupProject(response.data);
        });
        api.get(`turns/?subenterprise=${company?.id}`).then((response) => {
            if (response?.status !== 200) return;
            setShifts(response.data);
        });
    }, [])

    const submit = () => {
        api.post('emails/', form).then((response) => {
            if (response.status === 201) {
                toast.success('Email creado correctamente');
                onCreate();
            } else {
                toast.error('Error al crear el email');
            }
        });
    }

    return (
        <>
            <div>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '30%' }}>
                        <Label required={true}>Nivel de Configuración</Label>
                        <Select value={form['config_level']} options={configLevelOptions} onChange={(val) => {
                            setForm({
                                ...form,
                                config_level: val[0]
                            })
                        }} />
                    </div>

                    <div style={{ flexBasis: '30%' }}>
                        <Label>Grupo de Proyecto</Label>
                        <Select value={form['project_group']} onChange={(val) => {
                            setForm({
                                ...form,
                                project_group: val[0],
                            });
                        }} options={groupProject?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} />
                    </div>

                    <div style={{ flexBasis: '30%' }}>
                        <Label>Turno</Label>
                        <Select value={form['turn']} onChange={(val) => {
                            setForm({ ...form, turn: val[0] })
                        }} options={shifts?.map((x) => {
                            return {
                                name: x.isDay ? "Día" : "Noche",
                                value: x.id
                            }
                        })} />
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '30%' }}>
                        <Label>Nivel de Agrupación</Label>
                        <Select value={form['group_level']} options={groupLevelOptions} onChange={(val) => {
                            setForm({
                                ...form,
                                group_level: val[0]
                            })
                        }} />
                    </div>

                    <div style={{ flexBasis: '60%' }}>
                        <Label required={true}>Asunto</Label>
                        <Text value={form['subject']} onChange={(val) => {
                            setForm({ ...form, subject: val })
                        }} />
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label required={true}>Destinatarios</Label>
                        <TextArea value={form['recipients']} onChange={(val) => {
                            setForm({ ...form, recipients: val })
                        }} />
                    </div>
                </div>

                <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label required={true}>Días de Envío de Reporte</Label>
                        <DateSelect form={form} setForm={setForm} />
                    </div>
                </div>

                <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                    <div style={{ flexBasis: '50%' }}>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <div>
                                <Label>Primer Envío</Label>
                                <TimePicker onChange={(val) => {
                                    setForm({
                                        ...form,
                                        first_time: val
                                    })
                                }} />
                            </div>

                            <div>
                                <Label>Segundo Envío</Label>
                                <TimePicker onChange={(val) => {
                                    setForm({
                                        ...form,
                                        second_time: val
                                    })
                                }} />
                            </div>

                            <div>
                                <Label>Tercer Envío</Label>
                                <TimePicker onChange={(val) => {
                                    setForm({
                                        ...form,
                                        third_time: val
                                    })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Mostrar</Label>
                        <CovidSelect form={form} setForm={setForm} />
                    </div>
                </div>
            </div>

            <div className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }} onClick={submit}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'} />
                <div style={{ color: '#5863B4' }}>Guardar</div>
            </div>
        </>
    )
}