import React from "react";
import styles from "../../screens/dashboard/components/risk/index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

/**
 * HeaderDropdown component that toggles its open state when clicked.
 *
 * @param {Object} props - The component props.
 * @param {string} props.name - The name to be displayed in the dropdown.
 * @param {function} props.setOpen - Function to set the open state.
 * @param {boolean} props.open - The current open state of the dropdown.
 * @returns {JSX.Element} The rendered HeaderDropdown component.
 */
export default function HeaderDropdown({ name, setOpen, open }) {
    return (
        <div onClick={() => setOpen((prev) => !prev)} className={styles.dropdown}>
            <div>{name}</div>
            <FontAwesomeIcon icon={!open ? faChevronUp : faChevronDown} color={'#5863B4'} />
        </div>
    )
}