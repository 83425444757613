import React, { useEffect, useState } from "react";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import styles from "./modals.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import Switch from "../../../../components/ajonjolib/switch";
import api from "../../../../api";

export default function EmployeesModal({ onSuccess }) {
    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState({});
    const [companies, setCompanies] = useState([]);
    const [projects, setProjects] = useState([]);
    const [guards, setGuards] = useState([]);
    const [shifts, setShifts] = useState([]);
    const [devices, setDevices] = useState([]);
    const [groupProject, setGroupProject] = useState([]);

    useEffect(() => {
        api.get('subenterprises/').then((response) => {
            if (response?.status !== 200) return;
            setCompanies(response.data);
        })

        api.get('devices/?active=true&ordering=id').then((response) => {
            if (response?.status !== 200) return;
            setDevices(response.data);
        });
    }, [isOpen]);

    useEffect(() => {
        api.get('project-groups/?subenterprise=' + form['subenterprise']).then((response) => {
            if (response?.status !== 200) setGroupProject([]);
            setGroupProject(response.data);
        }
        );
    }, [form['subenterprise']]);

    useEffect(() => {
        api.get('projects/?project_group=' + form['projectGroup']).then((response) => {
            if (response?.status !== 200) setProjects([]);
            setProjects(response.data);
        })
    }, [form['projectGroup']]);

    useEffect(() => {
        api.get('guards/?project=' + form['project'] + '&project_group=' + form['projectGroup']).then((response) => {
            if (response?.status !== 200) setGuards([]);
            setGuards(response.data);
        })
    }, [form['project']]);

    useEffect(() => {
        api.get('turns/?project=' + form['project']).then((response) => {
            if (response?.status !== 200) setShifts([]);
            setShifts(response.data);
        });
    }, [form['project'], form['guard']]);

    React.useEffect(() => {
        eventBus.subscribe('employees_modal_open', (rec_data) => {
            setIsOpen(true);
            const copy = {
                ...rec_data,
                subenterprise: rec_data.subenterprise?.id,
                project: rec_data.project?.id,
                device: rec_data.device?.id,
                guard: rec_data.guard?.id,
                turn: rec_data.turn?.id,
                projectGroup: rec_data.projectGroup?.id,
            };
            setForm(copy);
        });
        eventBus.subscribe('employees_modal_close', (data) => { setIsOpen(false) });
    }, []);

    const submit = () => {
        const formToSend = {
            ...form,
        }
        if (formToSend['device'] === undefined) {
            formToSend['device'] = null;
        }
        api.put(`employees/${form?.id}/`, formToSend).then((response) => {
            if (response?.status === 200) {
                setIsOpen(false);
                onSuccess();
                return;
            }
        });
    }

    return (
        <div>
            {isOpen &&
                <Modal title={'Master de Empleados'} desc={'Editar'} close={() => setIsOpen(false)}>
                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '25%' }}>
                            <Label>Estado</Label>
                            <Switch value={form['active']} onChange={(e) => setForm({
                                ...form, active: e
                            })} />
                        </div>

                        <div style={{ flexBasis: '65%' }}>
                            <Label>Empresa</Label>
                            <Select value={form['subenterprise']} onChange={(val) => {
                                if (val[0] === form['subenterprise']) return;
                                setForm({ ...form, subenterprise: val[0], projectGroup: undefined, project: undefined });
                            }} options={companies?.map((x) => {
                                return {
                                    name: x.name ?? '',
                                    value: x.id
                                }
                            })} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Grupo de Proyecto</Label>
                            <Select value={form['projectGroup']} onChange={(val) => {
                                if (val[0] === form['projectGroup']) return;
                                setForm({ ...form, projectGroup: val[0], project: undefined });
                            }} options={groupProject?.map((x) => {
                                return {
                                    name: x.name ?? '',
                                    value: x.id
                                }
                            })} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Proyecto</Label>
                            <Select options={projects?.map((x) => {
                                return {
                                    name: x.name ?? '',
                                    value: x.id
                                }
                            })} value={form['project']} onChange={(val) => {
                                if (val[0] === form['project']) return;
                                setForm({ ...form, project: val[0], guard: undefined, turn: undefined });
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Apellidos</Label>
                            <Text value={form['vApellidos']} onChange={(val) => {
                                setForm({ ...form, vApellidos: val });
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Nombres</Label>
                            <Text value={form['vNombres']} onChange={(val) => {
                                setForm({ ...form, vNombres: val });
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>ID</Label>
                            <Text value={form['vCodEmpleado']} onChange={(val) => {
                                setForm({ ...form, vCodEmpleado: val });
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Turno</Label>
                            <Select value={form['turn']} onChange={(val) => {
                                if (val[0] === form['turn']) return;
                                setForm({ ...form, turn: val[0] })
                            }} options={shifts?.map((x) => {
                                return {
                                    name: x.isDay ? "Día" : "Noche",
                                    value: x.id
                                }
                            })} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Pulsera</Label>
                            <Select searchable={true} value={form['device']} options={
                                [

                                    ...devices?.map((x) => {
                                        return {
                                            name: x.serial ?? '',
                                            value: x.id
                                        }
                                    }),
                                    {
                                        name: 'Ninguno',
                                        value: undefined
                                    },
                                ]
                            } onChange={(val) => {
                                console.log('Change', val);
                                if (val[0] === form['device']) return;
                                setForm({ ...form, device: val[0] })
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Guardia</Label>
                            <Select value={form['guard']} onChange={(val) => {
                                if (val[0] === form['guard']) return;
                                setForm({ ...form, guard: val[0], turn: undefined });
                            }} options={guards?.map((x) => {
                                return {
                                    name: x.name ?? '',
                                    value: x.id
                                }
                            })} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                        <div className={styles.button} onClick={submit}>
                            <FontAwesomeIcon icon={faSave} color={'#52A035'} />
                            <div className={'ps-2'}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}