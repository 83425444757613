import React, { useEffect, useState } from "react";
import styles from './sections.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Switch from "../../../../components/ajonjolib/switch";
import api from "../../../../api";
import { toast } from "react-toastify";

export default function CompanyData({ company }) {
    const [form, setForm] = useState(company);

    useEffect(() => {
        setForm(company);
    }, [company]);

    const submit = () => {
        api.put(`subenterprises/${company.id}/`, form).then((response) => {
            if (response?.status === 200) {
                onSuccess();
                return;
            }
        });
    }

    const onSuccess = () => {
        toast.success('Empresa actualizada correctamente');
    }

    return (
        <div className={styles.formContainer}>
            <div className={'mt-4'} style={{
                display: 'flex', justifyContent: "space-between", alignItems: 'center'
            }}>
                <div style={{ flexBasis: '100%' }}>
                    <div>Nombre de Empresa</div>
                    <input type={'text'} value={form['name']} onChange={(event) => {
                        setForm({ ...form, name: event.target.value })
                    }} />
                </div>
                <div className={'ms-4'} />
                <div>
                    <div>Estado</div>
                    <Switch value={form['active']} onChange={(e) => setForm({
                        ...form, active: e
                    })} />

                </div>
            </div>
            <div className={'mt-4'} style={{
                display: 'flex', justifyContent: "space-between", alignItems: 'center'
            }}>
                <div style={{ flexBasis: '30%' }}>
                    <div>Key User</div>
                    <input type={'text'} value={form['key_user']} onChange={(event) => {
                        setForm({ ...form, key_user: event.target.value })
                    }} />
                </div>
                <div style={{ flexBasis: '30%' }}>
                    <div>E-Mail</div>
                    <input type={'text'} value={form['email']} onChange={(event) => {
                        setForm({ ...form, email: event.target.value })
                    }} />
                </div>
                <div style={{ flexBasis: '30%' }}>
                    <div>Teléfono</div>
                    <input type={'text'} value={form['phone']} onChange={(event) => {
                        setForm({ ...form, phone: event.target.value })
                    }} />
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                <div onClick={submit} className={styles.button}>
                    <FontAwesomeIcon icon={faSave} size={'xl'} />
                    <div className={'ms-3'}>Guardar</div>
                </div>
            </div>
        </div>
    )
}