import React, { useEffect } from "react"
import { Select } from "../../ajonjolib/inputs/ajonjolinput";
import styles from "../../../screens/dashboard/components/filters/index.module.css";
import { currentDateStr } from "../../../utils/date";

export default function ResultsFilters({ filters, setFilters, projects, projectGroups, guards, companies }) {
    return (
        <>
            <div>Empresa</div>
            <Select className={'mb-4'} value={[filters['company']]}
                options={
                    [
                        { name: "Todas", value: -1 },
                        ...companies?.map((x) => {
                            return { name: x.name, value: x.id };
                        })]
                } onChange={(val) => {
                    if (val[0] === -1) {
                        setFilters({
                            ...filters,
                            company: -1,
                            project_group: -1,
                            project: -1,
                            guard: -1,
                        });
                        return;
                    }
                    setFilters({
                        ...filters,
                        company: val[0]
                    })
                }} />

            <div>Grupo de Proyecto</div>
            <Select className={'mb-4'} value={[filters['project_group']]} onChange={(val) => {

                if (val[0] === -1) {
                    setFilters({
                        ...filters,
                        project_group: -1,
                        project: -1,
                        guard: -1,
                    });
                    return;
                }

                setFilters({
                    ...filters,
                    project_group: val[0]
                });
            }} options={
                [
                    { name: "Todos", value: -1 },
                    ...projectGroups?.map((x) => {
                        return { name: x.name, value: x.id }
                    })]
            } />

            <div>Proyecto</div>
            <Select className={'mb-4'} value={[filters['project']]} onChange={(val) => {

                if (val[0] === -1) {
                    setFilters({
                        ...filters,
                        project: -1,
                        guard: -1,
                    });
                    return;
                }

                setFilters({
                    ...filters,
                    project: val[0]
                });
            }} options={
                [
                    { name: "Todos", value: -1 },
                    ...projects?.map((x) => {
                        return { name: x.name, value: x.id }
                    })]
            } />

            <div>Guardia</div>
            <Select className={'mb-4'} value={[filters['guard']]} onChange={(val) => {
                setFilters({
                    ...filters,
                    guard: val[0]
                });
            }} options={[
                { name: "Todas", value: -1 },
                ...guards?.map((x) => {
                    return { name: x.name, value: x.id }
                })]} />
        </>
    )
}