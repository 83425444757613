import React, { useEffect, useState } from "react";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import { Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import Switch from "../../../../components/ajonjolib/switch";
import styles from "./modals.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";

/**
 * BandsModal component renders a modal for editing band details.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onSuccess - Callback function to be called on successful form submission.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <BandsModal onSuccess={() => console.log('Success!')} />
 * 
 * @description
 * This component uses several hooks:
 * - `useState` to manage the state of the modal, form data, models, group projects, and projects.
 * - `useEffect` to fetch data from the API when the modal is opened and when the project group changes.
 * - `useEffect` to subscribe to events for opening and closing the modal.
 * 
 * The component renders a modal with various input fields for editing band details, including:
 * - Estado (active switch)
 * - N° de Serie (serial number)
 * - Marca y Modelo (model selection)
 * - MAC de Dispositivo (MAC address)
 * - Llave Dispositivo (device key)
 * - Grupo de Proyecto (project group selection)
 * - Proyecto (project selection)
 * 
 * The form data is submitted via an API call when the "Guardar" button is clicked.
 */
export default function BandsModal({ onSuccess }) {
    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState({});
    const [models, setModels] = useState([]);
    const [groupProject, setGroupProject] = useState([]);
    const [project, setProject] = useState([]);

    useEffect(() => {
        api.get('project-groups/').then((response) => {
            if (response?.status !== 200) return;
            setGroupProject(response.data);
        }).catch(error => {
            console.log(error);
        }
        );

        api.get('device-models/').then((response) => {
            if (response?.status !== 200) return;
            setModels(response.data?.results);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [isOpen]);

    useEffect(() => {
        setForm({
            ...form,
            project: undefined
        });
        api.get('projects/?project_group=' + form['project_group']).then((response) => {
            if (response?.status !== 200) return;
            setProject(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [form['project_group']]);

    useEffect(() => {
        eventBus.subscribe('bands_modal_open', (rec_data) => {
            setIsOpen(true);
            const copy = {
                ...rec_data,
                model: rec_data.model?.id,
                project_group: rec_data.project_group?.id,
                project: rec_data.project?.id
            }
            setForm(copy);
        });
        eventBus.subscribe('bands_modal_close', (data) => { setIsOpen(false) });
    }, []);

    const submit = () => {
        api.put(`devices/${form?.id}/`, form).then((response) => {
            if (response?.status === 200) {
                setIsOpen(false);
                onSuccess();
                return;
            }
        }).catch(error => {
            console.log(error);
        }
        );
    }

    return (
        <div>
            {isOpen &&
                <Modal style={{ width: '50%' }} title={'Master de Pulseras'} desc={'Editar'} close={() => setIsOpen(false)}>
                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '25%' }}>
                            <Label>Estado</Label>
                            <Switch value={form['active']} onChange={(e) => setForm({
                                ...form, active: e
                            })} />
                        </div>


                    </div>
                    <div className={'d-flex align-items-center w-100 justify-content-between mt-2'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>N° de Serie</Label>
                            <Text value={form['serial']} onChange={(val) => {
                                setForm({ ...form, serial: val })
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Marca y Modelo</Label>
                            <Select value={form['model']} options={models?.map((x) => {
                                return {
                                    name: x.name,
                                    value: x.id
                                }
                            })} onChange={(val) => {
                                setForm({ ...form, model: val[0] })
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>MAC de Dispositivo</Label>
                            <Text value={form['mac']} onChange={(val) => {
                                setForm({ ...form, mac: val })
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Llave Dispositivo</Label>
                            <Text value={form['key']} onChange={(val) => {
                                setForm({ ...form, key: val })
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Grupo de Proyecto</Label>
                            <Select value={form['project_group']} onChange={(val) => {
                                setForm({ ...form, project_group: val[0] });
                            }} options={groupProject?.map((x) => {
                                return {
                                    name: x.name,
                                    value: x.id
                                }
                            })} />
                        </div>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Proyecto</Label>
                            <Select value={form['project']} onChange={(val) => {
                                setForm({ ...form, project: val[0] });
                            }} options={project?.map((x) => {
                                return {
                                    name: x.name,
                                    value: x.id
                                }
                            })} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                        <div className={styles.button} onClick={submit}>
                            <FontAwesomeIcon icon={faSave} color={'#52A035'} />
                            <div className={'ps-2'}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}