// websocketContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { wsURL } from './api';

const WebSocketContext = createContext(null);

/**
 * WebSocketProvider component that provides the WebSocket context to the children components.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {JSX.Element} props.children - The children components.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @description
 * This component uses the following hooks:
 * - `useState` to manage the WebSocket state.
 * - `useEffect` to create a new WebSocket connection.
 * 
 * The component provides the WebSocket state to the children components
 */
export const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const notificationsUrl = wsURL + 'notifications/';
        const ws = new WebSocket(notificationsUrl);
        ws.onopen = () => {
            console.log('WebSocket connection established');
            setSocket(ws);
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        ws.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
        };

        return () => {
            ws.close();
        };
    }, []);

    return (
        <WebSocketContext.Provider value={socket}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
