import React, { useEffect, useState } from "react";

/**
 * RadioInput component renders a single radio button.
 * 
 * @param {Object} props - The properties object. 
 * @param {string} props.name - The name attribute for the radio button.
 * @param {string} props.value - The value attribute for the radio button.
 * @param {boolean} props.checked - The checked state of the radio button.
 * @param {function} props.onChange - The callback function to handle value change.
 * 
 * @returns {JSX.Element} The rendered RadioInput component. 
 */
function RadioInput({ name, value, checked, onChange }) {
    return (
        <label>
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            {value}
        </label>
    );
}

/**
 * RadioGroup component renders a group of radio buttons.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.name - The name attribute for the radio buttons.
 * @param {React.ReactNode} props.children - The child components, expected to be RadioInput components.
 * @param {string} props.value - The current selected value.
 * @param {function} props.onChange - The callback function to handle value change.
 * @param {string} [props.className] - Optional CSS class for the container.
 * @param {Object} [props.style] - Optional inline styles for the container.
 * @param {string} [props.labelPosition] - Optional position for the label.
 *
 * @returns {JSX.Element} The rendered RadioGroup component.
 */
function RadioGroup({ name, children, value, onChange, className, style, labelPosition }) {
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
        if (onChange) {
            onChange(currentValue);
        }
    }, [currentValue, onChange]);

    return (
        <div className={className} style={style}>
            <div>{name}</div>
            <div>
                {React.Children.map(children, (child) => {
                    if (React.isValidElement(child) && child.type === RadioInput) {
                        return React.cloneElement(child, {
                            name: name,
                            checked: child.props.value === value,
                            onChange: () => setCurrentValue(child.props.value),
                        });
                    }
                    return child;
                })}
            </div>
        </div>
    )
}

/**
 * Radio component that groups related radio inputs.
 * 
 * @namespace Radio
 * @property {React.Component} Group - The RadioGroup component that manages a group of radio inputs.
 * @property {React.Component} Input - The RadioInput component that represents an individual radio input.
 */
const Radio = {
    Group: RadioGroup,
    Input: RadioInput,
};

export default Radio;