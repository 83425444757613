import React, { useEffect, useState } from "react";
import { Label, Select } from "../../../components/ajonjolib/inputs/ajonjolinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import TimePicker from "../../../components/ajonjolib/inputs/time";
import api from "../../../api";

/**
 * ShiftsCreate component renders a form for creating a new shift.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onSuccess - Callback function to be called on successful form submission.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <ShiftsCreate onSuccess={() => console.log('Success!')} />
 * 
 * @description
 * This component uses several hooks:
 * - `useState` to manage the state of the form data, projects, project groups, and companies.
 * - `useEffect` to fetch data from the API when the component mounts and when the project group changes.
 * 
 * The component renders a form with various input fields for creating a new shift, including:
 * - Empresa (company selection)
 * - Grupo de Proyecto (project group selection)
 * - Proyecto (project selection)
 * - Turno (day/night selection)
 * - Horario Laboral (work schedule)
 * - Save button
 */
export default function ShiftsCreate({ onSuccess }) {
    const [form, setForm] = useState({});
    const [projects, setProjects] = useState([]);
    const [projectGroups, setProjectGroups] = useState([]);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {

        api.get('subenterprises/').then((response) => {
            setCompanies(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, []);

    useEffect(() => {
        api.get('project-groups/?subenterprise=' + form['subenterprise']).then((response) => {
            if (response?.status !== 200) {
                setProjectGroups([]);
                return;
            }
            setProjectGroups(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [form['subenterprise']]);

    useEffect(() => {
        api.get('projects/?project_group=' + form['project_group']).then((response) => {
            if (response?.status !== 200) {
                setProjects([]);
                return;
            }
            setProjects(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [form['project_group']]);

    const submit = () => {
        api.post('turns/', form).then((response) => {
            if (response?.status !== 201) return;
            onSuccess();
        }).catch(error => {
            console.log(error);
        }
        );
    }

    return (
        <>
            <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Empresa</Label>
                        <Select value={form['subenterprise']} options={companies?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} onChange={(val) => {
                            if (val[0] === form['subenterprise']) return;
                            setForm({ ...form, subenterprise: val[0], project_group: undefined, project: undefined });
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Grupo de Proyecto</Label>
                        <Select options={projectGroups?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} value={form['project_group']} onChange={(val) => {
                            if (val[0] === form['project_group']) return;
                            setForm({ ...form, project_group: val[0], project: undefined });
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Proyecto</Label>
                        <Select options={projects?.map((x) => {
                            return {
                                name: x.name ?? '',
                                value: x.id
                            }
                        })} value={form['project']} onChange={(val) => {
                            setForm({ ...form, project: val[0] });
                        }} />
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Turno</Label>
                        <Select value={form['isDay']} options={[{ name: 'Día', value: 1 }, { name: "Noche", value: 0 }]} onChange={(val) => {
                            setForm({ ...form, isDay: val[0] === 1 });
                        }} />
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Horario Laboral</Label>
                        <div className={'d-flex align-items-center justify-content-between'}>
                            <TimePicker value={form['laboral_time_start']} onChange={(val) => {
                                setForm({ ...form, laboral_time_start: val });
                            }} />
                            <div>-</div>
                            <TimePicker value={form['laboral_time_end']} onChange={(val) => {
                                setForm({ ...form, laboral_time_end: val });
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'} />
                <div style={{ color: '#5863B4' }}>Guardar</div>
            </div>
        </>
    )
}