import React, { useEffect, useState } from "react";
import Switch from "../../../../components/ajonjolib/switch";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import { Button, Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import styles from './modals.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";

export default function GroupModal({ onSuccess }) {
    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState({})

    useEffect(() => {
        eventBus.subscribe('group_modal_open', (rec_data) => {
            setIsOpen(true);
            rec_data.subenterprise = rec_data.subenterprise?.id;
            setForm(rec_data);
        });
        eventBus.subscribe('group_modal_close', (data) => { setIsOpen(false) });
    }, []);

    const submit = () => {
        api.put(`project-groups/${form?.id}/`, form).then((response) => {
            if (response?.status === 200) {
                setIsOpen(false);
                onSuccess();
                return;
            }
        });
    }

    return (
        <div>
            {isOpen &&
                <Modal title={'Grupo de Proyecto'} desc={'Editar'} close={() => setIsOpen(false)}>
                    <Label>Estado</Label>
                    <Switch value={form['active']} onChange={(e) => setForm({
                        ...form, active: e
                    })} />

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Grupo de Proyecto</Label>
                            <Text value={form['name']} onChange={(val) => {
                                setForm({ ...form, name: val })
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>País</Label>
                            <Text value={form['country']} onChange={(val) => {
                                setForm({ ...form, country: val })
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-3'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Tipo de Servicio</Label>
                            <Text value={form['services']} onChange={(val) => {
                                setForm({ ...form, services: [val] })
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label style={{ color: 'transparent' }}>-</Label>
                            <Button style={{
                                padding: '0 20px', fontSize: '13px', height: '30px', textAlign: 'center', justifyContent: 'center',
                                backgroundColor: form['show_tts'] ? '#5863B4' : '#FFF',
                                color: form['show_tts'] ? '#FFF' : '#5863B4'
                            }} name={'Mostrar TTS en Reposo'} onSubmit={() => {
                                setForm({ ...form, show_tts: !form['show_tts'] })
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                        <div className={styles.button} onClick={submit}>
                            <FontAwesomeIcon icon={faSave} color={'#52A035'} />
                            <div className={'ps-2'}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}