import React, { useContext, useEffect, useState } from "react";
import Header from "../../dashboard/components/header";
import HeaderCreate from "../../../components/header_create";
import MainContext from "../../../context";
import Table from "../../../components/ajonjolib/table/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import CompanyCreate from "./create";
import api from "../../../api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function Company() {
    const navigate = useNavigate();
    const { setSettings } = useContext(MainContext);
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [openCreate, setOpenCreate] = useState(false);


    const retrieveData = () => {
        api.get('subenterprises/').then(response => {
            setRows(response.data);
        });
    }

    useEffect(() => {
        document.title = "Sleeptech - Empresa";
        setSettings((prev) => {
            return {
                ...prev, sidebar: 3, submenu: 0
            }
        });
        retrieveData();
    }, [setSettings]);

    const cols = [
        {
            title: 'Estado', key: 'active', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <div className={'me-2'} style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: data ? '#00C853' : '#EAEAEC' }} />
                        <div>{data}</div>
                    </div>
                )
            }
        },
        { title: 'Nombre de Empresa', key: 'name' },
        { title: 'Key User', key: 'key_user' },
        { title: 'E-Mail', key: 'email' },
        { title: 'Teléfono', key: 'phone' },
        {
            title: 'Fecha Registro', key: 'created_at', code: (row, data) => {
                return (new Date(data)).toLocaleDateString();
            }
        },
        {
            title: 'Acciones', key: 'actions', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faEdit} color={'#5863B4'} size={'lg'} role={'button'} onClick={() => {
                            navigate(`/config/company/detail/${row.id}`);
                        }} />
                    </div>
                )
            }
        }
    ];

    const onSearch = (text) => {
        setSearchText(text);
    }

    const filteredRows = rows.length > 0 ? rows.filter((row) => {
        const text = searchText.toLowerCase();
        return (
            row.name.toLowerCase().includes(text) ||
            row.key_user.toLowerCase().includes(text) ||
            row.email.toLowerCase().includes(text) ||
            row.phone.toLowerCase().includes(text) ||
            row.created_at.toLowerCase().includes(text)
        );
    }) : [];

    return (
        <div>
            <Header title={'Empresa'}>
                <div className={'searchBox'}>
                    <div>
                        <img src={'/icons/search.png'} alt={'search'} width={'auto'} height={'24px'} />
                    </div>
                    <input type={'text'} placeholder={'Buscar...'}
                        onChange={(e) => {
                            onSearch(e.target.value);
                        }
                        }

                    />
                </div>
            </Header>
            <HeaderCreate name={'Panel de Empresas'}
                open={openCreate} setOpen={setOpenCreate}>
                <CompanyCreate onSuccess={
                    () => {
                        toast.success('Empresa creada correctamente');
                        setOpenCreate(false);
                        retrieveData();
                    }
                } />
            </HeaderCreate>
            <div className={'mb-3'} />
            <Table cols={cols} rows={filteredRows} />
            <ToastContainer />
        </div>
    )
}