import React, { useEffect, useState } from "react";
import { Line, CartesianGrid, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

/**
 * SleepGraph component renders a graph of the user's sleep data.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.rec_data - The user's sleep data.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <SleepGraph rec_data={{}} />
 * 
 * @description
 * This component uses the following hooks:
 * - `useState` to manage the state of the graph data.
 * - `useEffect` to fetch data from the API when the component mounts.
 * 
 * The component renders a graph of the user's sleep data.
 * The graph displays the user's sleep and awake times.
 * The graph is displayed in a responsive container.
 * The graph has a custom tooltip that displays the time and state of the user.
 * The graph has a line for the user's sleep time and a line for the user's awake time.
 * The graph has a grid with dashed lines.
 * The graph has an X-axis with the time of the user's sleep.
 * The graph has a Y-axis with the state of the user (awake or asleep).
 */
export default function SleepGraph({ rec_data }) {
    const [data, setData] = useState({});

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            console.log('payload', payload)
            let status = 'Despierto';
            if (payload[0].value === 0) {
                status = 'Dormido';
            }
            if (payload[0].value === 2) {
                status = 'Somnolencia';
            }
            return (
                <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #A36EFE' }}>
                    <p className="label">{`Hora: ${payload[0].payload.name}`}</p>
                    <p className="label">{`Estado: ${status}`}</p>
                    {payload[0].value == 2 && <p className="label">{`Duración: ${payload[0].payload.duration} minutos`}</p>}
                    {payload[0].value == 2 && <p className="label">{`Rango de sueño: ${payload[0].payload.sleep} minutos`}</p>}
                </div>
            );
        }
        return null;
    }

    useEffect(() => {
        if (Object.keys(rec_data).length < 1) return
        let sleep_samples = rec_data?.sleep_time_samples;
        let sleep_stage_samples = rec_data?.sleep_stage_samples;
        let activity_samples = rec_data?.activity_samples;
        // Log first and last activity samples
        activity_samples = activity_samples.filter((x) => x.heart_rate > 0);
        const filtered_sleep_samples = [];
        const filtered_stages_samples = [];
        for (let i = 0; i < sleep_samples.length; ++i) {
            const sleep_sample = sleep_samples[i];
            const start_time_in_ms = sleep_sample.timestamp;
            const end_time_in_ms = sleep_sample.wakeup_time;
            const start_time = Math.floor(start_time_in_ms / 1000);
            const end_time = Math.ceil(end_time_in_ms / 1000);
            let activity_samples_in_range = [];
            for (let activity_sample of activity_samples) {
                if (activity_sample.timestamp >= start_time && activity_sample.timestamp <= end_time && activity_sample.heart_rate > 0) {
                    activity_samples_in_range.push(activity_sample);
                }
            }
            if (activity_samples_in_range.length == 0) {
                continue;
            }
            filtered_sleep_samples.push(sleep_sample);
        }
        sleep_samples = filtered_sleep_samples;
        for (let i = 0; i < sleep_stage_samples.length; ++i) {
            const sleep_stage_sample = sleep_stage_samples[i];
            for (let sleep_sample of sleep_samples) {
                if (sleep_stage_sample.timestamp >= sleep_sample.timestamp && sleep_stage_sample.timestamp <= sleep_sample.wakeup_time) {
                    filtered_stages_samples.push(sleep_stage_sample);
                    break;
                }
            }
        }
        sleep_stage_samples = filtered_stages_samples;
        let graph_data = [];
        let last_sample = null;
        for (let i = 0; i < sleep_samples.length; ++i) {
            const start = sleep_samples[i]?.timestamp
            const end = sleep_samples[i]?.wakeup_time
            const differenceSeconds = end - start
            const differenceMinutes = Math.floor(differenceSeconds / 60000)
            const fullMinutes = sleep_samples[i]?.total_duration + sleep_samples[i]?.awake_duration

            if (last_sample) {
                const lastEnd = last_sample?.wakeup_time
                graph_data.push({
                    name: new Date((lastEnd + start) / 2).toTimeString().slice(0, 5),
                    awake: 1,
                    timestamp: (lastEnd + start) / 2
                });
            }
            let awake = 0
            if (fullMinutes !== differenceMinutes) {
                awake = 2
            }
            graph_data.push({
                name: new Date(sleep_samples[i]?.timestamp).toTimeString().slice(0, 5),
                awake: awake,
                timestamp: start,
                duration: differenceMinutes,
                sleep: sleep_samples[i]?.total_duration
            });
            graph_data.push({
                name: new Date(sleep_samples[i]?.wakeup_time).toTimeString().slice(0, 5),
                awake: awake,
                timestamp: end,
                duration: differenceMinutes,
                sleep: sleep_samples[i]?.total_duration
            });
            last_sample = sleep_samples[i];
        }
        if (sleep_stage_samples.length < 1) {
            graph_data.sort((a, b) => a.timestamp - b.timestamp);
            setData(graph_data);
            return
        }
        let last_stage = null
        sleep_stage_samples.forEach((stage) => {
            const current = stage?.timestamp
            if (last_stage) {
                const lastEnd = last_stage?.timestamp
                const isAwake = last_stage.stage === 5 || last_stage.stage === 0;

                if (isAwake) {
                    graph_data.push({
                        name: new Date((lastEnd + current) / 2).toTimeString().slice(0, 5),
                        awake: 1,
                        timestamp: (lastEnd + current) / 2
                    });
                }
                graph_data.push({
                    name: new Date(last_stage?.timestamp).toTimeString().slice(0, 5),
                    awake: 0,
                    timestamp: lastEnd
                });
                graph_data.push({
                    name: new Date(stage?.timestamp).toTimeString().slice(0, 5),
                    awake: 0,
                    timestamp: current
                });
            }
            last_stage = stage;
        });
        // Sort by timestamp
        graph_data.sort((a, b) => a.timestamp - b.timestamp);
        console.log('graph_data', graph_data)
        setData(graph_data);
    }, [rec_data]);
    return (
        <div className={'mt-4 d-flex justify-content-center align-items-center flex-column px-2'}>
            <ResponsiveContainer width="100%" height={150}>
                <LineChart
                    data={data}
                    margin={{
                        top: 5, right: 30, left: 70, bottom: 5,
                    }}
                >
                    <XAxis dataKey="name" />
                    <Line dataKey="awake" stroke="#A36EFE" type="monotone" dot={false} />
                    <YAxis padding={{ top: 10, bottom: 10 }} type="category"
                        tickFormatter={(value) => {
                            switch (value) {
                                case 0:
                                    return 'Dormir'
                                case 1:
                                    return 'Despertar'
                                case 2:
                                    return 'Somnolencia'
                            }
                        }}
                        allowDataOverflow />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip content={<CustomTooltip />} />
                </LineChart>
            </ResponsiveContainer>

        </div>
    )
}