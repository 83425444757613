import React, { useEffect, useState } from "react";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import styles from './modals.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import api from "../../../../api";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

/**
 * DownloadButton component for rendering a download button.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onTap - Callback function to be called when the button is clicked.
 * @param {string} props.children - The button text.
 * @param {boolean} props.loading - Indicates if the button is in a loading state.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <DownloadButton onTap={handleDownload} loading={isLoading}>Download</DownloadButton>
 * 
 * @description
 * This component renders a button with a download icon and text.
 * The button calls the `onTap` function when clicked.
 */
function DownloadButton({ children, onTap, loading }) {
    return (
        <div className={styles.downloadButton} onClick={onTap}>
            {loading ? (
                <div className={styles.spinner}></div>
            ) : (
                <>
                    <FontAwesomeIcon icon={faFileExcel} color={'#FFF'} className={'me-3'} />
                    {children}
                </>
            )}
        </div>
    )
}

/**
 * ExportModal component for rendering an export modal.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The modal title.
 * @param {boolean} [props.allowDownloadTemplate=true] - If true, allow downloading a template.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <ExportModal title={'Exportar'} />
 * 
 * @description
 * This component renders a modal for exporting data.
 * 
 * The modal has two sections:
 * - Download Template: Allows downloading an empty template.
 * - Download Data: Allows downloading data based on a query.
 */
export default function ExportModal({ title, allowDownloadTemplate = true }) {
    const [isOpen, setIsOpen] = useState(false);
    const [url, setUrl] = useState('');
    const [query, setQuery] = useState('');
    const [loadingTemplate, setLoadingTemplate] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    useEffect(() => {
        eventBus.subscribe('export_modal_open', (rec_data) => {
            setUrl(rec_data.url);
            setIsOpen(true);
            setQuery(rec_data.query);
            console.log('QUERY', query);
            console.log('REC DATA', rec_data);
        });
        eventBus.subscribe('export_modal_close', (data) => { setIsOpen(false) });
    }, []);

    return (
        <div>
            {isOpen &&
                <Modal title={title} desc={'Exportar'} close={() => setIsOpen(false)}>
                    {allowDownloadTemplate &&
                        <div className={'d-flex align-items-center w-100 justify-content-between'}>
                            <div style={{ flexBasis: '45%', textAlign: 'left' }}>
                                <div style={{ fontSize: '15px', color: '#494B50', lineHeight: '16px' }}>Descargar Plantilla</div>
                                <div style={{ fontSize: '12px' }}>La plantilla a descargar se encuentra vacía</div>
                            </div>

                            <div style={{ flexBasis: '45%' }}>
                                <DownloadButton onTap={
                                    () => {
                                        setLoadingTemplate(true);
                                        const emptyUrl = url + '?empty=true';
                                        const emptyUrlWithQuery = emptyUrl + '&' + query;
                                        api.get(emptyUrlWithQuery, {
                                            responseType: 'blob'
                                        }).then((response) => {
                                            if (response?.status !== 200) return;
                                            const url = window.URL.createObjectURL(new Blob([response.data]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', 'template.xlsx');
                                            document.body.appendChild(link);
                                            link.click();
                                            link.parentNode.removeChild(link);
                                        }).finally(() => {
                                            setLoadingTemplate(false);
                                        }
                                        ).catch((error) => {
                                            toast.error('Ha ocurrido un error inesperado');
                                        });
                                    }
                                } loading={loadingTemplate}>
                                    <div>Descargar Plantilla</div>
                                </DownloadButton>
                            </div>
                        </div>
                    }
                    {allowDownloadTemplate && <hr />}

                    <div className={'d-flex align-items-center w-100 justify-content-between '}>
                        <div style={{ flexBasis: '45%', textAlign: 'left' }}>
                            <div style={{ fontSize: '15px', color: '#494B50', lineHeight: '16px' }}>Descargar Registro</div>
                            <div style={{ fontSize: '12px' }}>El registro a descargar toma a los usuarios y datos registrados en el Master de Empleados</div>
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <DownloadButton onTap={() => {
                                setLoadingData(true);
                                const urlWithQuery = url + '?' + query;
                                api.get(urlWithQuery, {
                                    responseType: 'blob'
                                }).then((response) => {
                                    if (response?.status !== 200) return;
                                    const url = window.URL.createObjectURL(new Blob([response.data]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', 'data.xlsx');
                                    document.body.appendChild(link);
                                    link.click();
                                    link.parentNode.removeChild(link);
                                }).finally(() => {
                                    setLoadingData(false);
                                }).catch((error) => {
                                    toast.error('Ha ocurrido un error inesperado');
                                }
                                );
                            }} loading={loadingData}>
                                <div>Descargar Registro</div>
                            </DownloadButton>
                        </div>
                    </div>
                </Modal>
            }

        </div>
    )
}