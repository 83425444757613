/**
 * currentDateStr
 * @param {Date} date
 * @returns {string}
 * @description
 * This function takes a date object and returns a string in the format 'YYYY-MM-DD'.
 */
export function currentDateStr() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
/**
 * dateToStr
 * @param {Date} pickedDate
 * @returns {string}
 * @description
 * This function takes a date object and returns a string in the format 'YYYY-MM-DD'.
 */
export function dateToStr(pickedDate) {
    console.log('pickedDate', pickedDate);
    const year = pickedDate.getFullYear();
    const month = String(pickedDate.getMonth() + 1).padStart(2, '0');
    const day = String(pickedDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

/**
 * timestampToStr
 * @param {int} timestamp
 * @returns {string}
 * @description
 * This function takes a timestamp and returns a string in the format 'YYYY-MM-DD'.
 */
export function timestampToStr(timestamp) {
    return dateToStr(new Date(timestamp));
}

/**
 * minutesToStr
 * @param {float} hours
 * @returns {string}
 * @description
 * This function takes a float and returns a string in the format 'HH:MM'.
 */
export function minutesToStr(minutes) {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    return `${h}h ${m < 10 ? '0' : ''}${m}m`;
}

/**
 * numToTimePicker
 * @param {float} hours
 * @returns {string}
 * @description
 * This function takes a float and returns a string in the format 'HH:MM'.
 * @example
 * const hours = 8.5;
 * const time = numToTimePicker(hours);
 * console.log(time); // '08:30'
 */
export function numToTimePicker(hours) {
    const h = String(Math.floor(hours)).padStart(2, '0');
    const m = String(Math.round((hours - Math.floor(hours)) * 60)).padStart(2, '0');
    return `${h}:${m}`;
}

/**
 * timePickerToNum
 * @param {string} time
 * @returns {float}
 * @description
 * This function takes a string in the format 'HH:MM' and returns a float.
 * @example
 * const time = '08:30';
 * const num = timePickerToNum(time);
 * console.log(num); // 8.5
 * @example
 * const time = '08:15';
 * const num = timePickerToNum(time);
 * console.log(num); // 8.25
 */
export function timePickerToNum(time) {
    const [h, m] = time.split(':');
    return parseInt(h) + parseInt(m) / 60;
}