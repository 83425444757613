import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import RouteContainer from "./routes";
import Sidebar from "./components/sidebar/sidebar";
import { ContextProvider } from "./context";
import { WebSocketProvider } from './wsContext';
import { FiltersProvider } from './filtersContext';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * App component is the main component that renders the application.
 * 
 * @component
 * @returns {JSX.Element} The rendered App component.
 * 
 * @example
 * return <App />
 * 
 * @description
 * This component uses the following context providers:
 * - `ContextProvider` to provide the main context.
 * - `WebSocketProvider` to provide the WebSocket context.
 * - `FiltersProvider` to provide the filters context.
 * 
 * The component renders the following components:
 * - `Sidebar` to render the sidebar.
 * - `RouteContainer` to render the routes.
 */
function App() {
    const path = window.location.pathname;
    console.log('path', path);
    return (
        <div className="App">
            <ContextProvider>
                <WebSocketProvider>
                    <FiltersProvider>
                        <Router>
                            {(path !== '/login' && !path.startsWith('/password_reset') && !path.startsWith('/password_request')) &&
                                <div>
                                    <Sidebar />
                                </div>
                            }
                            <div style={(path !== '/login' && !path.startsWith('/password_reset') && !path.startsWith('/password_request')) ? {

                                marginLeft: '250px',
                                padding: '20px',
                                height: '100%',
                            } : {}}>
                                <RouteContainer />
                            </div>
                        </Router>
                    </FiltersProvider>
                </WebSocketProvider>

            </ContextProvider>
            <ToastContainer />
        </div>
    );
}

export default App;
