import React from "react";
import styles from './index.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";

/**
 * TimePicker component renders a time input field with an icon.
 *
 * @param {Object} props - The component props.
 * @param {function} props.onChange - Callback function to handle the change event.
 * @param {string} props.value - The current value of the time input.
 * @returns {JSX.Element} The rendered TimePicker component.
 */
export default function TimePicker({ onChange, value }) {
    return (
        <div className={styles.container}>
            <FontAwesomeIcon icon={faClock} className={styles.inputIcon} />
            <input aria-label="Time" type="time"
                value={value} onChange={(event) => {
                    if (onChange) {
                        onChange(event.target.value);
                    }
                }} />
        </div>
    )
}