import React, { useState } from "react";
import { Label, Text } from "../../../components/ajonjolib/inputs/ajonjolinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../api";

/**
 * CompanyCreate component renders a form for creating a new company.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onSuccess - Callback function to be called on successful form submission.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <CompanyCreate onSuccess={() => console.log('Success!')} />
 * 
 * @description
 * This component uses a single hook:
 * - `useState` to manage the state of the form data.
 * 
 * The component renders a form with various input fields for creating a new company, including:
 * - Nombre de Empresa (company name)
 * - Key User (key user)
 * - E-Mail (email)
 * - Teléfono (phone)
 */
export default function CompanyCreate({ onSuccess }) {
    const [form, setForm] = useState({ active: true });

    const submit = () => {
        api.post('subenterprises/', form).then((response) => {
            if (response?.status !== 201) return;
            onSuccess();
        })
    }

    return (
        <>
            <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Nombre de Empresa</Label>
                        <Text value={form['name']} onChange={(val) => {
                            setForm({ ...form, name: val })
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Key User</Label>
                        <Text value={form['key_user']} onChange={(val) => {
                            setForm({ ...form, key_user: val })
                        }} />
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>E-Mail</Label>
                        <Text value={form['email']} onChange={(val) => {
                            setForm({ ...form, email: val })
                        }} />
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Teléfono</Label>
                        <Text value={form['phone']} onChange={(val) => {
                            setForm({ ...form, phone: val });
                        }} />
                    </div>
                </div>
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'} />
                <div style={{ color: '#5863B4' }}>Guardar</div>
            </div>
        </>
    )
}