import React, { useEffect, useState } from "react";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import { Label, Select, Text, TextArea } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import Switch from "../../../../components/ajonjolib/switch";
import styles from "./modals.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import TimePicker from "../../../../components/ajonjolib/inputs/time";
import api from "../../../../api";
import { toast } from "react-toastify";

/**
 * DateSelect component for selecting days of the week.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form data.
 * @param {Function} props.setForm - The function to set the form data.
 * 
 * @returns {JSX.Element} The rendered component.
 */
function DateSelect({ form, setForm }) {
    const dates = [
        "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"
    ];

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (form?.days) {
            const transformDays = form?.days.map((d, i) => parseInt(d))
            setSelected(transformDays);
        }
    }, [form]);

    return (
        <div className={styles.dateContainer}>
            {dates.map((date, index) => (
                <div style={{
                    backgroundColor: selected.includes(index + 1) ? "#5863B4" : "#FFF",
                    color: selected.includes(index + 1) ? "#FFF" : "#5863B4"
                }} key={index} className={styles.dateItem} onClick={() => {
                    const value = index + 1;
                    let copy = [...selected];
                    if (copy.includes(value)) {
                        copy.splice(copy.indexOf(value), 1);
                    } else {
                        copy.push(value);
                    }
                    setSelected(copy);

                    setForm({
                        ...form,
                        days: copy
                    });
                }}>
                    <div>{date}</div>
                </div>
            ))}
        </div>
    )
}

/**
 * CovidSelect component for selecting COVID related options.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form data.
 * @param {Function} props.setForm - The function to set the form data.
 * 
 * @returns {JSX.Element} The rendered component.
 */
function CovidSelect({ form, setForm }) {
    const options = [
        "Cargo", "Alerta COVID"
    ];

    const [selected, setSelected] = useState([
        false, false
    ]);

    useEffect(() => {
        setSelected([form?.show_position, form?.show_covid]);
    }, [form]);

    return (
        <div className={styles.dateContainer}>
            {options.map((date, index) => (
                <div style={{
                    backgroundColor: selected[index] ? "#5863B4" : "#FFF",
                    color: selected[index] ? "#FFF" : "#5863B4"
                }} key={index} className={styles.dateItem} onClick={() => {
                    let copy = [...selected];
                    copy[index] = !copy[index];
                    setSelected(copy);
                    setForm({ ...form, show_covid: copy[1], show_position: copy[0] });
                }}>
                    <div>{date}</div>
                </div>
            ))}
        </div>
    )
}

/**
 * EmailModal component for editing email configurations.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onSuccess - Callback function to be called on successful form submission.
 * @param {Object} props.company - The company object containing company details.
 * @returns {JSX.Element} The rendered EmailModal component.
 *
 * @example
 * <EmailModal onSuccess={handleSuccess} company={companyData} />
 *
 * @typedef {Object} Form
 * @property {string} config_level - The configuration level.
 * @property {string} project_group - The project group ID.
 * @property {string} turn - The turn ID.
 * @property {string} subject - The email subject.
 * @property {string} recipients - The email recipients.
 * @property {Array<string>} days - The days for sending the report.
 * @property {string} first_time - The first time for sending the email.
 * @property {string} [second_time] - The optional second time for sending the email.
 * @property {string} [third_time] - The optional third time for sending the email.
 * @property {boolean} [active] - The active status of the email configuration.
 * @property {string} [group_level] - The grouping level.
 *
 * @typedef {Object} Company
 * @property {number} id - The company ID.
 */
export default function EmailModal({ onSuccess, company }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [form, setForm] = useState({});
    const configLevelOptions = [
        { value: "group", name: "Grupo de Proyecto" },
        { value: "project", name: "Proyecto" },
        { value: "guard", name: "Guardia" },
    ]
    const groupLevelOptions = [
        { value: "none", name: "Ninguno" },
        { value: "group", name: "Por Grupo" },
    ]
    const [groupProject, setGroupProject] = useState([]);
    const [projects, setProjects] = useState([]);
    const [guards, setGuards] = useState([]);
    const [shifts, setShifts] = useState([]);

    useEffect(() => {
        api.get(`project-groups/?subenterprise=${company?.id}`).then((response) => {
            if (response?.status !== 200) return;
            setGroupProject(response.data);
        }).catch(error => {
            console.log(error);
        }
        );

        api.get(`projects/?subenterprise=${company?.id}`).then((response) => {
            if (response?.status !== 200) return;
            setProjects(response.data);
        }
        ).catch(error => {
            console.log(error);
        }
        );
        api.get(`guards/?subenterprise=${company?.id}`).then((response) => {
            if (response?.status !== 200) return;
            setGuards(response.data);
        }
        ).catch(error => {
            console.log(error);
        }
        );

        api.get(`turns/?subenterprise=${company?.id}`).then((response) => {
            if (response?.status !== 200) return;
            setShifts(response.data);
        }).catch(error => {
            console.log(error);
        }
        );

    }, [company]);

    useEffect(() => {
        eventBus.subscribe('email_modal_open', (rec_data) => {
            setIsOpen(true);
            let copy = {
                ...rec_data,
                project_group: rec_data?.project_group?.id,
                project: rec_data?.project?.id,
                guard: rec_data?.guard?.id,
                turn: rec_data?.turn?.id
            };
            setForm(copy);
        });

        eventBus.subscribe('email_modal_close', (data) => {
            setIsOpen(false)
        });
    }, []);

    const submit = () => {
        switch (form['config_level']) {
            case 'group':
                form['project'] = null;
                form['guard'] = null;
                break;
            case 'project':
                form['project_group'] = null;
                form['guard'] = null;
                break;
            case 'guard':
                form['project_group'] = null;
                form['project'] = null;
                break;
        }
        if (form['config_level'] === 'group' && !form['project_group']) {
            toast.error('Por favor seleccione un grupo de proyecto');
            return;
        }
        if (form['config_level'] === 'project' && !form['project']) {
            toast.error('Por favor seleccione un proyecto');
            return;
        }
        if (form['config_level'] === 'guard' && !form['guard']) {
            toast.error('Por favor seleccione una guardia');
            return;
        }

        if (!form['config_level'] || !form['turn'] || !form['subject'] || !form['recipients'] || !form['days'] || !form['first_time']) {
            toast.error('Por favor llene todos los campos requeridos');
            return;
        }
        if (form['days'].length === 0) {
            toast.error('Por favor seleccione al menos un día de envío');
            return;
        }
        api.put(`emails/${form?.id}/`, form).then((response) => {
            if (response?.status === 200) {
                setIsOpen(false);
                onSuccess();
                return;
            }
        }).catch(error => {
            console.log(error);
        }
        );
    }

    return (
        <div>
            {isOpen &&
                <Modal style={{ width: '66%' }} title={'E-Mail'} desc={'Editar'} close={() => setIsOpen(false)}>
                    <Label>Estado</Label>
                    <Switch value={form?.active} onChange={(e) => setForm({ ...form, active: e })} />

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '32%' }}>
                            <Label required={true}>Nivel de Configuración</Label>
                            <Select value={form['config_level']} options={configLevelOptions} onChange={(val) => {
                                setForm({
                                    ...form,
                                    config_level: val[0]
                                })
                            }} />
                        </div>

                        {form['config_level'] === 'group' && (<div style={{ flexBasis: '32%' }}>
                            <Label required={true}>Grupo de Proyecto</Label>
                            <Select value={form['project_group']} onChange={(val) => {
                                setForm({
                                    ...form,
                                    project_group: val[0],
                                });
                            }} options={groupProject?.map((x) => {
                                return {
                                    name: x.name ?? '',
                                    value: x.id
                                }
                            })} />
                        </div>)}

                        {form['config_level'] === 'project' && (
                            <div style={{ flexBasis: '32%' }}>
                                <Label required={true}>Proyecto</Label>
                                <Select value={form['project']} onChange={(val) => {
                                    setForm({
                                        ...form,
                                        project: val[0],
                                    });
                                }} options={projects?.map((x) => {
                                    return {
                                        name: x.name ?? '',
                                        value: x.id
                                    }
                                })} />
                            </div>
                        )}

                        {form['config_level'] === 'guard' && (<div style={{ flexBasis: '32%' }}>
                            <Label required={true}>Guardia</Label>
                            <Select value={form['guard']} onChange={(val) => {
                                setForm({
                                    ...form,
                                    guard: val[0],
                                });
                            }} options={guards?.map((x) => {
                                return {
                                    name: x.name ?? '',
                                    value: x.id
                                }
                            })} />
                        </div>)}

                        <div style={{ flexBasis: '32%' }}>
                            <Label required={true}>Turno</Label>
                            <Select value={form['turn']} onChange={(val) => {
                                setForm({ ...form, turn: val[0] })
                            }} options={shifts?.map((x) => {
                                return {
                                    name: x.isDay ? "Día" : "Noche",
                                    value: x.id
                                }
                            })} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '32%' }}>
                            <Label>Nivel de Agrupación</Label>
                            <Select value={form['group_level']} options={groupLevelOptions} onChange={(val) => {
                                setForm({
                                    ...form,
                                    group_level: val[0]
                                })
                            }} />
                        </div>

                        <div style={{ flexBasis: '66%' }}>
                            <Label required={true}>Asunto</Label>
                            <Text value={form['subject']} onChange={(val) => {
                                setForm({ ...form, subject: val })
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '100%' }}>
                            <Label required={true}>Destinatarios</Label>
                            <TextArea value={form['recipients']} onChange={(val) => {
                                setForm({ ...form, recipients: val })
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '100%' }}>
                            <Label required={true}>Días de Envío de Reporte</Label>
                            <DateSelect form={form} setForm={setForm} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '50%' }}>
                            <div className={'d-flex justify-content-between align-items-center'}>
                                <div>
                                    <Label required={true}>Primer Envío</Label>
                                    <TimePicker value={form?.first_time} onChange={(val) => {
                                        setForm({
                                            ...form,
                                            first_time: val
                                        })
                                    }} />
                                </div>

                                <div>
                                    <Label>Segundo Envío</Label>
                                    <TimePicker value={form?.second_time} onChange={(val) => {
                                        setForm({
                                            ...form,
                                            second_time: val
                                        })
                                    }} />
                                </div>

                                <div>
                                    <Label>Tercer Envío</Label>
                                    <TimePicker value={form?.third_time} onChange={(val) => {
                                        setForm({
                                            ...form,
                                            third_time: val
                                        })
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Mostrar</Label>
                            <CovidSelect form={form} setForm={setForm} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                        <div className={styles.button} onClick={submit}>
                            <FontAwesomeIcon icon={faSave} color={'#52A035'} />
                            <div className={'ps-2'}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}