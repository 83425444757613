import React, { useContext, useEffect, useState } from "react";
import Header from "../../dashboard/components/header";
import HeaderCreate from "../../../components/header_create";
import MainContext from "../../../context";
import Table from "../../../components/ajonjolib/table/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import PermissionsModal from "../company/modals/permissions";
import PermissionsCreate from "./create";
import api from "../../../api";
import { eventBus } from "../../../components/ajonjolib/toasts/toast/toast";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Permissions component renders the permissions page.
 * 
 * @component
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <Permissions />
 * 
 * @description
 * This component uses several hooks:
 * - `useState` to manage the state of the table rows and the search text.
 * 
 * The component renders a header with a search box and a create button.
 * The component also renders a table with permission details, including:
 * - Estado (active switch)
 * - Nombre (name)
 * - Fecha de Creación (created_at)
 * - Acciones (edit button)  
 */
export default function Permissions() {
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [openCreate, setOpenCreate] = useState(false);


    const { setSettings } = useContext(MainContext);
    useEffect(() => {
        document.title = "Sleeptech - Perfiles";
        setSettings((prev) => {
            return {
                ...prev, sidebar: 3, submenu: 2
            }
        });

        api.get('roles/').then(response => {
            setRows(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [setSettings]);

    const retrieveData = () => {
        api.get('roles/').then(response => {
            setRows(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }

    const cols = [
        {
            title: 'Estado', key: 'status', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <div className={'me-2'} style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: data ? '#00C853' : '#EAEAEC' }} />
                        <div>{data ? 'Activo' : 'Inactivo'} </div>
                    </div>
                )
            }
        },
        { title: 'Nombre', key: 'name' },
        { title: 'Fecha de Creación', key: 'created_at', code: (row, data) => new Date(data).toLocaleString('es-es') },
        {
            title: 'Acciones', key: 'actions', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faEdit} color={'#5863B4'} size={'lg'} role={'button'} onClick={() => {
                            eventBus.emit('permissions_modal_open', row);
                        }} />
                    </div>
                )
            }
        }
    ];

    const onSearch = (text) => {
        setSearchText(text);
    }

    const filteredRows = rows.length > 0 ? rows.filter((row) => {
        const text = searchText.toLowerCase();
        const status = row.status ? 'activo' : 'inactivo';
        const created_at = new Date(row.created_at).toLocaleString('es-es');
        return (
            row.name?.toLowerCase().includes(text) ||
            status.includes(text) ||
            created_at.includes(text)
        );
    }) : [];

    return (
        <div>
            <PermissionsModal onSuccess={() => {
                toast.success('Perfil actualizado correctamente');
                retrieveData();
            }} />

            <Header title={'Perfiles'}>
                <div className={'searchBox'}>
                    <div>
                        <img src={'/icons/search.png'} alt={'search'} width={'auto'} height={'24px'} />
                    </div>
                    <input type={'text'} placeholder={'Buscar...'}
                        onChange={(e) => {
                            onSearch(e.target.value);
                        }
                        }
                    />
                </div>
            </Header>
            <HeaderCreate name={'Lista de Perfiles'} width={'622px'}
                open={openCreate} setOpen={setOpenCreate}
            >
                <PermissionsCreate onSuccess={() => {
                    toast.success('Perfil creado correctamente');
                    setOpenCreate(false);
                    retrieveData();
                }}></PermissionsCreate>
            </HeaderCreate>
            <div className={'mb-3'} />
            <Table cols={cols} rows={filteredRows} />

        </div>
    )
}