import React, { useContext, useEffect, useState } from "react";
import Header from "./components/header";
import Risk from "./components/risk";
import Usage from "./components/usage";
import Filters from "./components/filters";
import MainContext from "../../context";
import Evolutive from "./components/evolutive";
import { Button } from "../../components/ajonjolib/inputs/ajonjolinput";
import styles from './index.module.css';
import ExportModal from "../configuration/company/modals/export";
import { openModal } from "../../components/ajonjolib/toasts/toast/toast";
import api from "../../api";
import { dateToStr, timestampToStr } from "../../utils/date";
import { useWebSocket } from "../../wsContext";
import { FiltersContext, useFilters } from "../../filtersContext";

function parseWithZero(data) {
    if (isNaN(data)) return "00";
    if (parseInt(data) < 10) return "0" + data.toString();
    else return data;
}

export default function Dashboard() {
    const [filters, setFilters] = useContext(FiltersContext);
    const [riskData, setRiskData] = useState({});
    const [usageData, setUsageData] = useState({});
    const [sleepData, setSleepData] = useState({});
    const [evolutiveData, setEvolutiveData] = useState([{}]);
    const { setSettings } = useContext(MainContext);
    const [generalSelect, setGeneralSelect] = useState('both');

    const socket = useWebSocket();

    useEffect(() => {
        if (!socket) return;
        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            const message = data.message;
            const project_id = data.project;
            const guard_id = parseInt(data.guard);
            const project_group_id = data.project_group;
            const enterprise_id = data.enterprise;

            const enterprise_filter = filters.company === enterprise_id || filters.company === -1;
            const project_group_filter = filters.project_group === project_group_id || filters.project_group === -1;
            const project_filter = filters.project === project_id || filters.project === -1;
            const guard_filter = filters.guard === guard_id || filters.guard === -1;
            const now = new Date();
            const start_date = new Date(filters.start_date);
            start_date.setHours(0, 0, 0, 0);
            const end_date = new Date(filters.end_date);
            end_date.setHours(23, 59, 59, 999);
            const date_filter = now >= start_date && now <= end_date;
            console.log('filters', { enterprise_filter, project_group_filter, project_filter, guard_filter, date_filter });
            if (enterprise_filter && project_group_filter && project_filter && guard_filter && date_filter) {
                retrieveData();
            }
        };
    }, [socket, filters]);

    const retrieveData = () => {
        let parsed_filters = '';
        if (Object.entries(filters).length <= 2) {
            return;
        }
        for (const [key, value] of Object.entries(filters)) {
            if (key === 'start_date' || key === 'end_date' || key === 'date') {
                parsed_filters += `${key}=${timestampToStr(value)}&`;
                continue;
            }
            parsed_filters += `${key}=${value}&`;
        }

        api.get(`dashboard/risk_data?${parsed_filters}`).then((response) => {
            if (response.status !== 200) return;
            let risk_data = {};

            response.data?.forEach((entry) => {
                const isDay = entry?.turn?.isDay;
                const entry_name = isDay ? "day" : "night";
                risk_data[entry_name] = {
                    total: isNaN(parseInt(entry.total)) ? 0 : parseInt(entry.total),
                    low_risk: isNaN(parseInt(entry.low_risk)) ? 0 : parseInt(entry.low_risk),
                    mid_risk: isNaN(parseInt(entry.mid_risk)) ? 0 : parseInt(entry.mid_risk),
                    high_risk: isNaN(parseInt(entry.high_risk)) ? 0 : parseInt(entry.high_risk),
                    critic_risk: isNaN(parseInt(entry.critic_risk)) ? 0 : parseInt(entry.critic_risk),
                    unknown_risk: isNaN(parseInt(entry.unknown_risk)) ? 0 : parseInt(entry.unknown_risk),
                    low_percentage: isNaN(parseInt(entry.low_risk)) ? 0 : parseInt(entry.low_risk) / parseInt(entry.total) * 100,
                    mid_percentage: isNaN(parseInt(entry.mid_risk)) ? 0 : parseInt(entry.mid_risk) / parseInt(entry.total) * 100,
                    high_percentage: isNaN(parseInt(entry.high_risk)) ? 0 : parseInt(entry.high_risk) / parseInt(entry.total) * 100,
                    critic_percentage: isNaN(parseInt(entry.critic_risk)) ? 0 : parseInt(entry.critic_risk) / parseInt(entry.total) * 100,
                    unknown_percentage: isNaN(parseInt(entry.unknown_risk)) ? 0 : parseInt(entry.unknown_risk) / parseInt(entry.total) * 100,
                };
            });
            risk_data.both = {
                total: risk_data?.day?.total + risk_data?.night?.total,
                low_risk: risk_data?.day?.low_risk + risk_data?.night?.low_risk,
                mid_risk: risk_data?.day?.mid_risk + risk_data?.night?.mid_risk,
                high_risk: risk_data?.day?.high_risk + risk_data?.night?.high_risk,
                critic_risk: risk_data?.day?.critic_risk + risk_data?.night?.critic_risk,
                unkwown_risk: risk_data?.day?.unknown_risk + risk_data?.night?.unknown_risk,
                low_percentage: (risk_data?.day?.low_risk + risk_data?.night?.low_risk) / (risk_data?.day?.total + risk_data?.night?.total) * 100,
                mid_percentage: (risk_data?.day?.mid_risk + risk_data?.night?.mid_risk) / (risk_data?.day?.total + risk_data?.night?.total) * 100,
                high_percentage: (risk_data?.day?.high_risk + risk_data?.night?.high_risk) / (risk_data?.day?.total + risk_data?.night?.total) * 100,
                critic_percentage: (risk_data?.day?.critic_risk + risk_data?.night?.critic_risk) / (risk_data?.day?.total + risk_data?.night?.total) * 100,
                unknown_percentage: (risk_data?.day?.unknown_risk + risk_data?.night?.unknown_risk) / (risk_data?.day?.total + risk_data?.night?.total) * 100,
            };

            risk_data.percentage_graph = [
                { name: "Bajo", both: risk_data.both.low_percentage, day: risk_data?.day?.low_percentage, night: risk_data?.night?.low_percentage },
                { name: "Medio", both: risk_data.both.mid_percentage, day: risk_data?.day?.mid_percentage, night: risk_data?.night?.mid_percentage },
                { name: "Alto", both: risk_data.both.high_percentage, day: risk_data?.day?.high_percentage, night: risk_data?.night?.high_percentage },
                { name: "Crítico", both: risk_data.both.critic_percentage, day: risk_data?.day?.critic_percentage, night: risk_data?.night?.critic_percentage },
                { name: "Desconocido", both: risk_data.both.unknown_percentage, day: risk_data?.day?.unknown_percentage, night: risk_data?.night?.unknown_percentage }
            ];

            setRiskData(risk_data);
        });

        api.get(`dashboard/usage_data?${parsed_filters}`).then((response) => {
            if (response.status !== 200) return;
            const data = response.data;

            let usage_data = {};

            data?.forEach((entry) => {
                const isDay = entry?.turn?.isDay;
                const total = isNaN(parseInt(entry.total_sum)) ? 0 : parseInt(entry.total_sum);
                const used = isNaN(parseInt(entry.used_sum)) ? 0 : parseInt(entry.used_sum);
                if (isDay) {
                    usage_data.day = {
                        used: used,
                        not_used: total - used,
                        total_users: total
                    }
                } else {
                    usage_data.night = {
                        used: used,
                        not_used: total - used,
                        total_users: total
                    }
                }
            });
            usage_data.both = {
                used: usage_data?.day?.used + usage_data?.night?.used,
                not_used: usage_data?.day?.not_used + usage_data?.night?.not_used,
                total_users: usage_data?.day?.total_users + usage_data?.night?.total_users
            }
            setUsageData(usage_data);
        });

        api.get(`dashboard/sleep_total_data?${parsed_filters}`).then((response) => {
            if (response.status !== 200) return;

            let sleep_data = {};

            response.data?.forEach((entry) => {
                const isDay = entry?.turn?.isDay;
                const entry_name = isDay ? "day" : "night";
                const total = isNaN(parseInt(entry.total)) ? 0 : parseInt(entry.total);
                const sleep_sum = isNaN(parseInt(entry.sleep_sum)) ? 0 : parseInt(entry.sleep_sum);
                const hours_fixed = parseWithZero(Math.floor(sleep_sum / total / 60));
                const minutes_fixed = parseWithZero(parseInt((sleep_sum / total) % 60));
                sleep_data[entry_name] = {
                    total: total,
                    sleep_sum: sleep_sum,
                    hours_fixed: hours_fixed,
                    minutes_fixed: minutes_fixed
                };
            });
            sleep_data.both = {
                total: sleep_data?.day?.total + sleep_data?.night?.total,
                sleep_sum: sleep_data?.day?.sleep_sum + sleep_data?.night?.sleep_sum,
                hours_fixed: (sleep_data?.day?.hours_fixed + sleep_data?.night?.hours_fixed) / 2.0,
                minutes_fixed: (sleep_data?.day?.minutes_fixed + sleep_data?.night?.minutes_fixed) / 2.0,
            };

            setSleepData(sleep_data);
        });

        api.get(`dashboard/risk_data_statistics?${parsed_filters}`).then((response) => {
            const data = response.data;
            let evol = [];
            const day = data?.find((entry) => entry.turn.isDay);
            const night = data?.find((entry) => !entry.turn.isDay);
            for (let i = 0; i < day?.data?.length; i++) {
                let low_percentage_day = isNaN(parseInt(day?.data[i]?.low_risk)) ? 0 : parseInt(day?.data[i]?.low_risk) / parseInt(day?.data[i]?.total) * 100;
                let low_percentage_night = isNaN(parseInt(night?.data[i]?.low_risk)) ? 0 : parseInt(night?.data[i]?.low_risk) / parseInt(night?.data[i]?.total) * 100;
                let low_percentage = low_percentage_day + low_percentage_night;
                let mid_percentage_day = isNaN(parseInt(day?.data[i]?.mid_risk)) ? 0 : parseInt(day?.data[i]?.mid_risk) / parseInt(day?.data[i]?.total) * 100;
                let mid_percentage_night = isNaN(parseInt(night?.data[i]?.mid_risk)) ? 0 : parseInt(night?.data[i]?.mid_risk) / parseInt(night?.data[i]?.total) * 100;
                let mid_percentage = mid_percentage_day + mid_percentage_night;
                let high_percentage_day = isNaN(parseInt(day?.data[i]?.high_risk)) ? 0 : parseInt(day?.data[i]?.high_risk) / parseInt(day?.data[i]?.total) * 100;
                let high_percentage_night = isNaN(parseInt(night?.data[i]?.high_risk)) ? 0 : parseInt(night?.data[i]?.high_risk) / parseInt(night?.data[i]?.total) * 100;
                let high_percentage = high_percentage_day + high_percentage_night;
                evol.push({
                    name: day?.data[i].month,
                    low_risk: low_percentage / 2.0,
                    mid_risk: mid_percentage / 2.0,
                    high_risk: high_percentage / 2.0,
                    critical_day: day?.data[i]?.high_risk,
                    critical_night: night?.data[i]?.high_risk,
                });
            }
            setEvolutiveData((prev) => {
                return {
                    ...prev,
                    risk_data: evol
                }
            })
        });

        api.get(`dashboard/sleep_total_data_statistics?${parsed_filters}`).then((response) => {
            const data = response.data;
            let evol = [];
            const day = data?.find((entry) => entry.turn.isDay);
            const night = data?.find((entry) => !entry.turn.isDay);
            for (let i = 0; i < day?.data?.length; i++) {
                evol.push({
                    name: day?.data[i].month,
                    day_minutes: day?.data[i]?.sleep_sum / day?.data[i]?.total,
                    night_minutes: night?.data[i]?.sleep_sum / night?.data[i]?.total,
                });
            }

            setEvolutiveData((prev) => {
                return {
                    ...prev,
                    sleep_time: evol
                }
            })
        });

        api.get(`dashboard/usage_data_statistics?${parsed_filters}`).then((response) => {
            const data = response.data;
            let usage = [];
            const day = data?.find((entry) => entry.turn.isDay);
            const night = data?.find((entry) => !entry.turn.isDay);
            for (let i = 0; i < day?.data?.length; i++) {
                const used_both = day?.data[i]?.used + night?.data[i]?.used
                const not_used_both = (day?.data[i]?.total - day?.data[i]?.used) + (night?.data[i]?.total - night?.data[i]?.used)
                let used_percent = used_both / (used_both + not_used_both) * 100;
                let not_used_percent = not_used_both / (used_both + not_used_both) * 100;
                // Round to nearest integer
                used_percent = Math.round(used_percent);
                not_used_percent = Math.round(not_used_percent);
                usage.push({
                    name: day?.data[i].month,
                    total_day: day?.data[i]?.total,
                    total_night: night?.data[i]?.total,
                    total_both: day?.data[i]?.total + night?.data[i]?.total,
                    used_day: day?.data[i]?.used,
                    used_night: night?.data[i]?.used,
                    used_both: used_both,
                    not_used_day: day?.data[i]?.total - day?.data[i]?.used,
                    not_used_night: night?.data[i]?.total - night?.data[i]?.used,
                    not_used_both: not_used_both,
                    used_percent: used_percent,
                    not_used_percent: not_used_percent
                });
            }

            setEvolutiveData((prev) => {
                return {
                    ...prev,
                    usage: usage
                }
            })
        })
    }

    useEffect(() => {
        document.title = "Sleeptech - Dashboard";
        setSettings((prev) => {
            return {
                ...prev, sidebar: 0
            }
        });

        retrieveData();
    }, [setSettings, filters]);

    return (
        <div className={'d-flex flex-column'} style={{
            height: '100%',
            width: '100%',
            overflowY: 'none',
        }}>
            <ExportModal title={'Dashboard'} />
            <Header title={'Bienvenido Admin'}>
                <>
                    <div className={styles.buttonContainer}>
                        <div className={`${styles.button} ${generalSelect === 'both' ? styles.selected : ''}`} onClick={() => setGeneralSelect('both')}>
                            <img className={'me-2'} alt={'shift'} src={'/icons/both_shifts.png'} width={'25px'} height={'25px'} />
                            <div>Ambos Turnos</div>
                        </div>
                        <div className={`${styles.button} ${generalSelect === 'day' ? styles.selected : ''}`} onClick={() => setGeneralSelect('day')}>
                            <img className={'me-2'} alt={'shift'} src={'/icons/day_shift.png'} width={'20px'} height={'20px'} />
                            <div>Turno Día</div>
                        </div>
                        <div className={`${styles.button} ${generalSelect === 'night' ? styles.selected : ''}`} onClick={() => setGeneralSelect('night')}>
                            <img className={'me-2'} alt={'shift'} src={'/icons/night_shift.png'} width={'20px'} height={'20px'} />
                            <div>Turno Noche</div>
                        </div>
                    </div>
                    <Button variant={'primary'} name={'Exportar'} icon={'/icons/export.png'} onSubmit={() => {
                        openModal('export_modal')
                    }} />
                </>
            </Header>
            <div className={'d-flex justify-content-between'} style={{
                height: '100%',
                width: '100%',
                overflowY: 'scroll',
                paddingTop: '2px',
            }}>
                <div style={{
                    width: '100%', height: '100%', marginRight: '20px',
                }}>
                    <Risk data={riskData} sleepData={sleepData} generalSelect={generalSelect} />
                    <div className={'mb-4'} />
                    <Usage data={usageData} generalSelect={generalSelect} />
                    <div className={'mb-4'} />
                    <Evolutive data={evolutiveData} />
                </div>
                <div style={{
                    width: '310px', height: '100%'
                }}>
                    <Filters filters={filters} setFilters={setFilters} type={'dashboard'} />
                </div>
            </div>
        </div>
    )
}