import React, { useContext, useEffect, useState } from "react";
import Filters from "../dashboard/components/filters";
import MainContext from "../../context";
import Table from "../../components/ajonjolib/table/table";
import HeaderDropdown from "../../components/header_dropdown";
import Header from "../dashboard/components/header";
import { Button } from "../../components/ajonjolib/inputs/ajonjolinput";
import ExportModal from "../configuration/company/modals/export";
import { openModal } from "../../components/ajonjolib/toasts/toast/toast";
import api from "../../api";
import { FiltersContext } from "../../filtersContext";
import { timestampToStr, minutesToStr } from "../../utils/date";
import { useWebSocket } from "../../wsContext";
import { BlueFace, FaceFromRisk, GreenFace, GreyFace, RedFace, YellowFace } from "../../utils/faces";

export default function Results() {
    const [filters, setFilters] = useContext(FiltersContext);
    const [open, setOpen] = useState(true);
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');


    const socket = useWebSocket();

    useEffect(() => {
        if (!socket) return;
        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            const message = data.message;
            const project_id = data.project;
            const guard_id = parseInt(data.guard);
            const project_group_id = data.project_group;
            const enterprise_id = data.enterprise;

            const enterprise_filter = filters.company === enterprise_id || filters.company === -1;
            const project_group_filter = filters.project_group === project_group_id || filters.project_group === -1;
            const project_filter = filters.project === project_id || filters.project === -1;
            const guard_filter = filters.guard === guard_id || filters.guard === -1;
            const now = new Date();
            const start_date = new Date(filters.date);
            start_date.setHours(0, 0, 0, 0);
            const end_date = new Date(filters.date);
            end_date.setHours(23, 59, 59, 999);
            const date_filter = now >= start_date && now <= end_date;
            console.log('filters', { enterprise_filter, project_group_filter, project_filter, guard_filter, date_filter });
            if (enterprise_filter && project_group_filter && project_filter && guard_filter && date_filter) {
                retrieveData();
            }
        };
    }, [socket, filters]);

    const cols = [
        {
            title: "Nivel de Riesgo", key: 'risk_level', code: (row, data) => {
                return <FaceFromRisk risk={data} />
            }
        },
        {
            title: "N° Horas", header: (title) => {
                const filter_date = filters.date;
                let today = new Date(filter_date);
                let yesterday = new Date(today);
                let beforeYesterday = new Date(today);

                yesterday.setDate(yesterday.getDate() - 1);
                beforeYesterday.setDate(beforeYesterday.getDate() - 2);

                const formatDate = (date) => {
                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    const day = date.getDate().toString().padStart(2, '0');
                    return `${day}/${month}`;
                };

                return (
                    <div style={{ padding: '0 20px' }}>
                        <div className={'d-flex justify-content-center flex-column align-items-center'}>
                            <div>{title}</div>
                            <div className={'d-flex justify-content-between align-items-center w-100'} style={{ color: '#FFF' }}>
                                <div className={'d-flex justify-content-center align-items-center'} style={{ backgroundColor: '#5863B4', borderRadius: '5px 5px 0 0', width: '54px', height: '23px' }}>{formatDate(beforeYesterday)}</div>
                                <div className={'d-flex justify-content-center align-items-center'} style={{ backgroundColor: '#5863B4', borderRadius: '5px 5px 0 0', width: '54px', height: '23px' }}>{formatDate(yesterday)}</div>
                                <div className={'d-flex justify-content-center align-items-center'} style={{ backgroundColor: '#5863B4', borderRadius: '5px 5px 0 0', width: '54px', height: '23px' }}>{formatDate(today)}</div>
                            </div>
                        </div>
                    </div>
                );
            }, key: 'hours', code: (row, data) => {
                return (
                    <div className={'d-flex justify-content-between align-items-center'} style={{
                        color: '#5863B4', fontWeight: 600, lineHeight: '19px', justifyContent: 'between'
                    }}>
                        <div className={'d-flex justify-content-between align-items-center w-100'} >
                            <div className={'d-flex'}
                            >{minutesToStr(row?.two_days_ago_sleep_effective_duration)}</div>
                            <div className={'d-flex'} style={{ marginLeft: '10px' }}
                            >{minutesToStr(row?.one_day_ago_sleep_effective_duration)}</div>
                            <div className={'d-flex'} style={{ marginLeft: '10px' }}
                            >{minutesToStr(row?.sleep_duration)}</div>
                        </div>
                    </div >
                )
            }
        },
        { title: "ID Trabajador", key: 'employee.id' },
        {
            title: "Nombres y Apellidos", key: 'employee', code: (row, data) => {
                return data?.vNombres + " " + data?.vApellidos;
            }
        },
        { title: "ID de Banda", key: 'employee.device.id' },
        {
            title: "Leído", key: 'last_sync', code: (row, data) => {
                // if data is today
                let today = new Date();
                let last_sync = new Date(data * 1000);
                if (today.getDate() === last_sync.getDate() && today.getMonth() === last_sync.getMonth() && today.getFullYear() === last_sync.getFullYear()) {
                    return (<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M17.2398 0.339512C18.0427 0.937538 18.2412 2.11985 17.6832 2.98027L7.26086 19.0498C6.43916 20.3167 4.69037 20.3167 3.86866 19.0498L0.316817 13.5734C-0.241235 12.713 -0.042737 11.5307 0.760175 10.9327C1.56309 10.3346 2.66637 10.5474 3.22442 11.4078L5.56476 15.0162L14.7756 0.814628C15.3336 -0.0457983 16.4369 -0.258515 17.2398 0.339512Z"
                            fill="#80B53D" />
                    </svg>)
                }
                else return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.7992 19.4508C17.5314 20.1831 18.7186 20.1831 19.4508 19.4508C20.1831 18.7186 20.1831 17.5314 19.4508 16.7992L12.6517 10L19.4508 3.20083C20.1831 2.46859 20.1831 1.28141 19.4508 0.549177C18.7186 -0.183057 17.5314 -0.183057 16.7992 0.549177L10 7.34835L3.20083 0.549175C2.46859 -0.183058 1.28141 -0.183058 0.549175 0.549175C-0.183058 1.28141 -0.183058 2.46859 0.549174 3.20082L7.34835 10L0.549175 16.7992C-0.183058 17.5314 -0.183058 18.7186 0.549175 19.4508C1.28141 20.1831 2.46859 20.1831 3.20083 19.4508L10 12.6517L16.7992 19.4508Z" fill="#F66565" /></svg>)
            }
        },
        {
            title: "Cumple", key: 'risk_level', code: (row, data) => {
                if (data === "low") return (<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.2398 0.339512C18.0427 0.937538 18.2412 2.11985 17.6832 2.98027L7.26086 19.0498C6.43916 20.3167 4.69037 20.3167 3.86866 19.0498L0.316817 13.5734C-0.241235 12.713 -0.042737 11.5307 0.760175 10.9327C1.56309 10.3346 2.66637 10.5474 3.22442 11.4078L5.56476 15.0162L14.7756 0.814628C15.3336 -0.0457983 16.4369 -0.258515 17.2398 0.339512Z" fill="#80B53D" /></svg>)
                else return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.7992 19.4508C17.5314 20.1831 18.7186 20.1831 19.4508 19.4508C20.1831 18.7186 20.1831 17.5314 19.4508 16.7992L12.6517 10L19.4508 3.20083C20.1831 2.46859 20.1831 1.28141 19.4508 0.549177C18.7186 -0.183057 17.5314 -0.183057 16.7992 0.549177L10 7.34835L3.20083 0.549175C2.46859 -0.183058 1.28141 -0.183058 0.549175 0.549175C-0.183058 1.28141 -0.183058 2.46859 0.549174 3.20082L7.34835 10L0.549175 16.7992C-0.183058 17.5314 -0.183058 18.7186 0.549175 19.4508C1.28141 20.1831 2.46859 20.1831 3.20083 19.4508L10 12.6517L16.7992 19.4508Z" fill="#F66565" /></svg>)
            }
        }
    ]

    const getParsedFilters = () => {
        let parsed_filters = '';
        for (const [key, value] of Object.entries(filters)) {
            // if (key === 'risk_level') continue;
            if (key === 'date') {
                parsed_filters += `${key}=${timestampToStr(value)}&`;
                continue;
            }
            parsed_filters += `${key}=${value}&`;
        }
        return parsed_filters
    }

    const retrieveData = () => {
        let parsed_filters = getParsedFilters();

        api.get(`employee-health-data/?${parsed_filters}`).then((response) => {
            if (response.status !== 200) return;
            setRows(response.data);
        });
    }

    const { setSettings } = useContext(MainContext);
    useEffect(() => {
        document.title = "Sleeptech - Resultados";
        setSettings((prev) => {
            return {
                ...prev, sidebar: 1
            }
        });

        retrieveData();
    }, [setSettings, filters]);

    const onSearch = (text) => {
        setSearchText(text);
    }

    const filteredRows = rows.filter((row) => {
        const text = searchText.toLowerCase();
        const two_days_text = minutesToStr(row?.two_days_ago_sleep_duration);
        const one_day_text = minutesToStr(row?.one_day_ago_sleep_duration);
        const current_day_text = minutesToStr(row?.sleep_duration);
        const employee_id_text = row.employee?.id.toString();
        const band_id_text = row.employee?.device?.id.toString();
        const fullName = row.employee?.vNombres + ' ' + row.employee?.vApellidos;
        return row.id.toString().includes(text) ||
            (two_days_text && two_days_text.toLowerCase().includes(text)) ||
            (one_day_text && one_day_text.toLowerCase().includes(text)) ||
            (current_day_text && current_day_text.toLowerCase().includes(text)) ||
            (employee_id_text && employee_id_text.toLowerCase().includes(text)) ||
            (band_id_text && band_id_text.toLowerCase().includes(text)) ||
            (fullName && fullName.toLowerCase().includes(text))
    })

    return (
        <div>
            <ExportModal title={'Resultado Turno'} allowDownloadTemplate={false} />
            <Header title={'Resultado Turno'}>
                <div>
                    <div className={'searchBox'}>
                        <div>
                            <img src={'/icons/search.png'} alt={'search'} width={'auto'} height={'24px'} />
                        </div>
                        <input type={'text'} placeholder={'Buscar...'}
                            onChange={(e) => {
                                onSearch(e.target.value);
                            }
                            }
                        />
                    </div>
                </div>
                <Button variant={'primary'} name={'Exportar'} icon={'/icons/export.png'} onSubmit={() => {
                    openModal('export_modal', {
                        url: 'summary/user-results/export/',
                        query: getParsedFilters(),
                    })
                }} />
            </Header>
            <div className={'d-flex'}>
                <div style={{ width: '100%', marginRight: '20px' }}>
                    <HeaderDropdown name={'Estadísticas de Clasificación de Riesgo'} setOpen={setOpen} open={open} />
                    <div className={'mb-3'} />
                    {open && <Table cols={cols} rows={filteredRows} />}
                </div>
                <div style={{
                    width: '310px', height: '100%'
                }}>
                    <Filters filters={filters} setFilters={setFilters} type={'results'} />
                </div>
            </div>
        </div>
    )
}