/**
 * A simple event bus for subscribing to and emitting events.
 * 
 * @property {Object} listeners - An object containing event listeners.
 * 
 * @function subscribe
 * @description Subscribes to an event and adds a callback to the listeners array.
 * @param {string} eventName - The name of the event to subscribe to.
 * @param {function} callback - The callback function to execute when the event is emitted.
 * 
 * @function unsubscribe
 * @description Unsubscribes from an event by removing the callback from the listeners array.
 * @param {string} eventName - The name of the event to unsubscribe from.
 * @param {function} callback - The callback function to remove from the listeners array.
 * 
 * @function emit
 * @description Emits an event by calling all the callbacks in the listeners array.
 * @param {string} eventName - The name of the event to emit.
 * @param {any} data - The data to pass to the event listeners.
 */
const eventBus = {
    listeners: {},

    subscribe: (eventName, callback) => {
        if (!eventBus.listeners[eventName]) {
            eventBus.listeners[eventName] = [];
        }
        eventBus.listeners[eventName].push(callback);
    },

    unsubscribe: (eventName, callback) => {
        if (eventBus.listeners[eventName]) {
            eventBus.listeners[eventName] = eventBus.listeners[eventName].filter(
                (listener) => listener !== callback
            );
        }
    },

    emit: (eventName, data) => {
        if (eventBus.listeners[eventName]) {
            eventBus.listeners[eventName].forEach((listener) => listener(data));
        }
    },
};

/**
 * Enum for toast types.
 * @readonly
 * @enum {string}
 * @property {string} SUCCESS - Represents a success toast.
 * @property {string} WARNING - Represents a warning toast.
 * @property {string} ERROR - Represents an error toast.
 * @property {string} INFO - Represents an info toast.
 * @property {string} DARK_SUCCESS - Represents a dark-themed success toast.
 * @property {string} DARK_ERROR - Represents a dark-themed error toast.
 */
const ToastTypes = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info',
    DARK_SUCCESS: 'dark_success',
    DARK_ERROR: 'dark_error',
};

/**
 * Displays a toast notification with the specified message and type.
 *
 * @param {string} message - The message to display in the toast.
 * @param {string} [type=ToastTypes.SUCCESS] - The type of toast to display (e.g., success, error, info).
 */
function toast(message, type = ToastTypes.SUCCESS) {
    eventBus.emit('showToast', { message: message, type: type });
}

/**
 * Opens a modal by emitting an event with the specified modal name and data.
 *
 * @param {string} modalName - The name of the modal to open.
 * @param {Object} data - The data to pass to the modal.
 */
function openModal(modalName, data) {
    eventBus.emit(`${modalName}_open`, data);
}

/**
 * Closes the specified modal by emitting a close event.
 *
 * @param {string} modalName - The name of the modal to close.
 */
function closeModal(modalName) {
    eventBus.emit(`${modalName}_close`);
}

/**
 * Subscribes to open and close events for a specified modal.
 *
 * @param {string} modalName - The name of the modal to subscribe to.
 * @param {function} setter - A function to set the modal's open state.
 * @param {function} callback - A callback function to execute when the modal is opened.
 */
function subscribeModal(modalName, setter, callback) {
    eventBus.subscribe(`${modalName}_open`, (data) => {
        setter(true);
        callback(data);
    });

    eventBus.subscribe(`${modalName}_close`, (data) => {
        setter(false)
    });
}

export {
    eventBus,
    ToastTypes,
    toast,
    openModal,
    closeModal,
    subscribeModal
}
