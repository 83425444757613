import React, { useEffect, useState } from "react";
import styles from './modals.module.css'
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import { Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import Switch from "../../../../components/ajonjolib/switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";

export default function ProjectModal({ onSuccess, company }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [form, setForm] = React.useState({});
    const [projectGroups, setProjectGroups] = useState([]);

    useEffect(() => {
        api.get('project-groups/?subenterprise=' + company?.id).then((res) => {
            if (res?.status !== 200) return;
            setProjectGroups(res.data);
        });
    }, [isOpen]);

    useEffect(() => {
        eventBus.subscribe('project_modal_open', (rec_data) => {
            setIsOpen(true);
            const copy = {
                ...rec_data,
                project_group: rec_data.project_group?.id,
            }
            setForm(copy);
        });
        eventBus.subscribe('project_modal_close', (data) => { setIsOpen(false) });
    }, []);

    const submit = () => {
        api.put(`projects/${form?.id}/`, form).then((response) => {
            if (response?.status === 200) {
                setIsOpen(false);
                onSuccess();
                return;
            }
        });
    }

    return (
        <div>
            {isOpen &&
                <Modal close={() => setIsOpen(false)} title={'Proyecto'} desc={'Editar'}>
                    <Label>Estado</Label>
                    <Switch value={form['active']} onChange={(e) => setForm({
                        ...form, active: e
                    })} />

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Grupo de Proyecto</Label>
                            <Select value={form['project_group']} options={projectGroups?.map((x) => {
                                return {
                                    value: x.id,
                                    name: x.name
                                }
                            })} onChange={(val) => {
                                setForm({ ...form, project_group: val[0] })
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Proyecto</Label>
                            <Text value={form['name']} onChange={(val) => {
                                setForm({ ...form, name: val })
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Zona Horaria</Label>
                            <Text value={form['timezone']} onChange={(val) => {
                                setForm({ ...form, timezone: val })
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                        <div className={styles.button} onClick={submit}>
                            <FontAwesomeIcon icon={faSave} color={'#52A035'} />
                            <div className={'ps-2'}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}