import React from "react";

/**
 * Label component that renders a styled label with optional required indicator.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The content to be displayed inside the label.
 * @param {Object} [props.style] - Optional additional styles to be applied to the label.
 * @param {string} [props.className] - Optional additional class names to be applied to the label.
 * @param {boolean} [props.required] - If true, a red asterisk will be displayed indicating the field is required.
 * @returns {JSX.Element} The rendered label component.
 */
export default function Label({ children, style, className, required }) {
    return (
        <div className={className} style={{
            color: "#5863B4", fontFamily: 'Lexend Variable, sans-serif',
            fontWeight: "400",
            paddingBottom: "0.2rem", textAlign: 'left',
            ...style
        }}>
            {children}
            {required && <span style={{ color: 'red' }}> *</span>}
        </div>
    )
}
