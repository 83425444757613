import React, { useEffect, useState } from "react";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import styles from '../modals.module.css';
import { Bar, BarChart, CartesianGrid, Cell, Pie, PieChart, ResponsiveContainer, YAxis } from "recharts";
import SleepGraph from "./graph";

function parseWithZero(data) {
    if (isNaN(data)) return "00";
    if (data < 10) return "0" + data.toString();
    else return data;
}

export default function SleepModal() {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState({});
    const [recData, setRecData] = useState({});
    const [faceId, setFaceId] = useState(0);
    const [date, setDate] = useState(null);

    useEffect(() => {
        eventBus.subscribe('sleep_modal_open', (rec_data) => {
            setRecData(rec_data);
            setDate(rec_data.date);
            const user_data = rec_data.user_data;
            const employee_data = rec_data.employee;
            const turn = employee_data?.turn;
            const project = employee_data?.project;
            const isDay = turn?.isDay ? 'DIA' : 'NOCHE';
            const laboralTimeStart = turn?.laboral_time_start ?? '';
            const laboralTimeEnd = turn?.laboral_time_end ?? '';
            const turn_name = isDay + ' ' + (project?.name ?? 'PROYECTO DESCONOCIDO') + ' [' + laboralTimeStart + ' - ' + laboralTimeEnd + ']';
            const total_duration = user_data?.sleep_duration;
            const awake_duration = user_data?.sleep_duration - user_data?.sleep_effective_duration;
            const sleep_duration = user_data?.sleep_effective_duration;
            const sleep_percentage = parseInt(sleep_duration / total_duration * 100);
            const awake_percentage = parseInt(awake_duration / total_duration * 100);
            let parsed = {
                efficency: {
                    awake_hours: Math.floor(awake_duration / 60),
                    awake_minutes: awake_duration % 60,
                    sleep_hours: Math.floor(sleep_duration / 60),
                    sleep_minutes: sleep_duration % 60,
                    total_hours: Math.floor(total_duration / 60),
                    total_minutes: total_duration % 60,
                    debt_hours: Math.max(0, Math.floor((user_data?.sleep_debt / 60) ?? 0)),
                    debt_minutes: Math.max(0, (user_data?.sleep_debt % 60) ?? 0),
                    sleep_percentage: sleep_percentage,
                    graph: [
                        { name: 'Tiempo de Sueño', value: sleep_percentage ? sleep_percentage : 0 },
                        { name: 'Interrupciones', value: awake_percentage ? awake_percentage : 0 }
                    ]
                },
                turn: turn_name,
                historic: {
                    one_day_hours: parseWithZero(Math.floor(user_data?.one_day_ago_sleep_duration / 60)),
                    two_day_hours: parseWithZero(Math.floor(user_data?.two_days_ago_sleep_duration / 60)),
                    three_day_hours: parseWithZero(Math.floor(user_data?.three_days_ago_sleep_duration / 60)),
                    one_day_minutes: parseWithZero(user_data?.one_day_ago_sleep_duration % 60),
                    two_day_minutes: parseWithZero(user_data?.two_days_ago_sleep_duration % 60),
                    three_day_minutes: parseWithZero(user_data?.three_days_ago_sleep_duration % 60),
                    graph: [
                        {
                            y: user_data?.one_day_ago_sleep_duration,
                            y2: user_data?.two_days_ago_sleep_duration,
                            y3: user_data?.three_days_ago_sleep_duration
                        }
                    ]
                }
            };
            setData(parsed);
            const face_id = (user_data?.risk_level === "none" || user_data?.risk_level === "low") ? 1 : (user_data?.risk_level === "mid") ? 2 : 3;
            setFaceId(face_id);
            setIsOpen(true);
        });
        eventBus.subscribe('sleep_modal_close', (rec_data) => { setIsOpen(false) });
    }, []);

    const today = () => {
        const date2 = new Date(date);
        console.log('Today', date2);
        return date2.toLocaleDateString('en-DE');
    }

    const oneDayBefore = () => {
        let date2 = new Date(date);
        date2.setDate(date2.getDate() - 1);
        return date2.toLocaleDateString('en-DE');
    };

    const twoDaysBefore = () => {
        const date2 = new Date(date);
        date2.setDate(date2.getDate() - 2);
        return date2.toLocaleDateString('en-DE');
    };

    const threeDaysBefore = () => {
        const date2 = new Date(date);
        date2.setDate(date2.getDate() - 3);
        return date2.toLocaleDateString('en-DE');
    };

    return (
        <>
            {isOpen &&
                <Modal padding={'0'} title={'Admin Admin'} desc={today()} close={() => setIsOpen(false)} style={{ minWidth: '1036px' }}>
                    <div className={styles.container}>
                        <div className={'d-flex align-items-center'}>
                            <div style={{ fontWeight: 600 }} className={'pe-2'}>Turno:</div>
                            <div>{data.turn}   Descanso [ - 04:30]</div>
                        </div>

                        <div className={styles.content}>
                            <div className={'d-flex align-items-center justify-content-between'}>
                                <div>Detalle de Sueño</div>
                                <div className={'d-flex align-items-center justify-content-between'}>
                                    <img alt={'face'} src={`/icons/faces/${faceId}.png`} width={'46px'} />
                                    <div className={styles.heartRate}>
                                        <div className={'d-flex justify-content-center align-items-center flex-column me-4 ms-2'}>
                                            <img alt={'heart'} src={'/icons/clock.png'} width={'21px'} />
                                            <div style={{ fontSize: '11px', lineHeight: '14px' }}>Total</div>
                                        </div>
                                        <div>{parseWithZero(data?.efficency?.total_hours)}h{parseWithZero(data?.efficency?.total_minutes)}m</div>
                                    </div>
                                </div>
                            </div>

                            <SleepGraph rec_data={recData} />
                        </div>

                        <div className={'d-flex justify-content-between align-items-center'}>
                            <div className={styles.content} style={{ flexBasis: '48%' }}>
                                <div className={'d-flex align-items-center justify-content-between'}>
                                    <div>Eficiencia de Sueño</div>
                                    <div className={'d-flex align-items-center justify-content-between'}>
                                        <img alt={'face'} src={`/icons/faces/${faceId}.png`} width={'46px'} />
                                        <div className={styles.heartRate}>
                                            <div className={'d-flex justify-content-center align-items-center flex-column me-4 ms-2'}>
                                                <img alt={'heart'} src={'/icons/moon.png'} width={'21px'} />
                                                <div style={{ fontSize: '11px', lineHeight: '14px' }}>Total</div>
                                            </div>
                                            <div>{parseWithZero(data?.efficency?.sleep_percentage)}%</div>
                                        </div>
                                    </div>
                                </div>

                                <div className={'d-flex justify-content-between align-items-center w-100'}>
                                    <ResponsiveContainer width={'100%'} height={300}>
                                        <PieChart width={'100%'} height={300} data={data?.efficency?.graph}>
                                            <Pie
                                                data={data?.efficency?.graph}
                                                innerRadius={55}
                                                outerRadius={100}
                                                fill={"#000"}
                                                paddingAngle={2}
                                                dataKey="value"
                                            >
                                                <Cell key={`cell-1`} fill={'#C3A0FE'} />
                                                <Cell key={`cell-2`} fill={'#F66565'} />
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>

                                    <div>
                                        <div style={{ color: '#494B50', fontSize: '11px' }}>Tiempo de Sueño</div>
                                        <div className={'d-flex align-items-center justify-content-center'} style={{
                                            backgroundColor: '#C3A0FEB3', borderRadius: '10px 0 0 10px', width: '166px', height: '40px', color: '#FFF'
                                        }}>{parseWithZero(data?.efficency?.sleep_hours)}h {parseWithZero(data?.efficency?.sleep_minutes)}m</div>

                                        <div style={{ color: '#494B50', fontSize: '11px' }}>Interrupciones</div>
                                        <div className={'d-flex align-items-center justify-content-center'} style={{
                                            backgroundColor: '#F66565B3', borderRadius: '10px 0 0 10px', width: '166px', height: '40px', color: '#FFF'
                                        }}>{parseWithZero(data?.efficency?.awake_hours)}h {parseWithZero(data?.efficency?.awake_minutes)}m</div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.content} style={{ flexBasis: '48%' }}>
                                <div className={'d-flex align-items-center justify-content-between'}>
                                    <div>Histórico Sueño Rotación Turno</div>
                                    <div className={'d-flex align-items-center justify-content-between'}>
                                        <div className={styles.heartRate} style={{
                                            backgroundColor: '#F66565B2'
                                        }}>
                                            <div className={'d-flex justify-content-center align-items-center flex-column me-4 ms-2'}>
                                                <div style={{ fontSize: '11px', lineHeight: '14px' }}>Deuda</div>
                                                <div style={{ fontSize: '11px', lineHeight: '14px' }}>Sueño</div>
                                            </div>
                                            <div>{parseWithZero(data?.efficency?.debt_hours)}h {parseWithZero(data?.efficency?.debt_minutes)}m</div>
                                        </div>
                                    </div>
                                </div>

                                <div className={'d-flex justify-content-between align-items-center my-2'}>
                                    <div>
                                        <div style={{ fontSize: '12px' }}>{threeDaysBefore()}</div>
                                        <div className={'d-flex justify-content-center align-items-center'} style={{
                                            borderRadius: '0 10px 10px 0', backgroundColor: '#5863B4B3',
                                            width: '130px', height: '30px', color: '#FFF'
                                        }}>{data?.historic?.three_day_hours}:{data?.historic?.three_day_minutes}</div>
                                    </div>

                                    <div>
                                        <div style={{ fontSize: '12px' }}>{twoDaysBefore()}</div>
                                        <div className={'d-flex justify-content-center align-items-center'} style={{
                                            borderRadius: '10px', backgroundColor: '#FFB986B3',
                                            width: '130px', height: '30px', color: '#FFF'
                                        }}>{data?.historic?.two_day_hours}:{data?.historic?.two_day_minutes}</div>
                                    </div>

                                    <div>
                                        <div style={{ fontSize: '12px' }}>{oneDayBefore()}</div>
                                        <div className={'d-flex justify-content-center align-items-center'} style={{
                                            borderRadius: '10px 0 0 10px', backgroundColor: '#BF9AFFB3',
                                            width: '130px', height: '30px', color: '#FFF'
                                        }}>{data?.historic?.one_day_hours}:{data?.historic?.one_day_minutes}</div>
                                    </div>
                                </div>

                                <ResponsiveContainer width={'100%'} height={170}>
                                    <BarChart width={'100%'} height={170} data={data?.historic?.graph}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <YAxis
                                        />
                                        <Bar dataKey="y3" fill="#5863B4" />
                                        <Bar dataKey="y2" fill="#FF9B52" />
                                        <Bar dataKey="y" fill="#BF9AFF" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}