import React, { useEffect, useState } from "react";
import styles from './index.module.css';
import HeaderDropdown from "../../../../components/header_dropdown";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import Popup from "reactjs-popup";


export default function Risk({ data, sleepData, generalSelect }) {
    const [selected, setSelected] = useState('both');
    const [selectedData, setSelectedData] = useState(data?.both);
    const [open, setOpen] = useState(true);
    const [data2, setData2] = useState([]);

    useEffect(() => {
        if (generalSelect === 'both') setSelectedData(data?.both);
        else if (generalSelect === 'day') setSelectedData(data?.day);
        else if (generalSelect === 'night') setSelectedData(data?.night);
    }, [generalSelect, data]);

    useEffect(() => {
        setData2([
            { day: sleepData?.day?.hours_fixed, night: sleepData?.night?.hours_fixed },
        ])
    }, [sleepData]);

    const filterName = () => {
        if (generalSelect === "both") return "Ambos Turnos";
        else if (generalSelect === "day") return "Día";
        else if (generalSelect === "night") return "Noche";
    }

    return (
        <div>
            <HeaderDropdown open={open} setOpen={setOpen} name={'Estadísticas de Clasificación de Riesgo'} />
            {open &&
                <div>
                    <div className={styles.graphContainer}>
                        <div>Porcentaje Promedio</div>
                        <ResponsiveContainer width={'100%'} height={300}>
                            <BarChart width={'100%'} height={300} data={data?.percentage_graph}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis
                                    padding={{ top: 30 }}
                                    label={{ value: '%', position: 'insideTop', fill: '#807f7f', fontSize: '14px', fontWeight: '600' }}
                                />
                                {generalSelect === 'both' && <Bar dataKey="both" fill="#5863B4" label={{ position: 'top', formatter: (value) => `${(Number.isNaN(value) ? 0 : value).toFixed(1)}%` }} />}
                                {(generalSelect === 'day' || generalSelect === 'both') && <Bar dataKey="day" fill="#239290" label={{ position: 'top', formatter: (value) => `${(Number.isNaN(value) ? 0 : value).toFixed(1)}%` }} />}
                                {(generalSelect === 'night' || generalSelect === 'both') && <Bar dataKey="night" fill="#FFAA00" label={{ position: 'top', formatter: (value) => `${(Number.isNaN(value) ? 0 : value).toFixed(1)}%` }} />}
                            </BarChart>
                        </ResponsiveContainer>
                    </div>

                    <div className={'d-flex justify-content-between'}>
                        <div className={styles.smallContainer}>
                            <div>Periodos de Sueño Registrados</div>
                            <div className={`${styles.pillContainer} py-3`}>
                                <div className={`${styles.pill}`}>{filterName()}</div>
                            </div>
                            <div className={'d-flex flex-column justify-content-between py-2'} style={{ height: '60%' }}>
                                <div className={'d-flex justify-content-between'}>
                                    <div style={{ color: '#A1A1A1' }}>Alto Riesgo</div>
                                    <div style={{ color: '#F22222' }}>{selectedData?.high_risk}</div>
                                </div>
                                <div className={'d-flex justify-content-between'}>
                                    <div style={{ color: '#A1A1A1' }}>Riesgo Moderado</div>
                                    <div style={{ color: '#FFAF37' }}>{selectedData?.mid_risk}</div>
                                </div>
                                <div className={'d-flex justify-content-between'}>
                                    <div style={{ color: '#A1A1A1' }}>Bajo Riesgo</div>
                                    <div style={{ color: '#7DB338' }}>{selectedData?.low_risk}</div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.smallContainerMiddle}>
                            <div className={'pt-3 px-3'}>Tiempo Total de Sueño</div>
                            <div className={styles.middleChart}>
                                <div className={'d-flex justify-content-between'}>
                                    <div>
                                        <div>Promedio Día</div>
                                        <div className={styles.chartInfo} style={{
                                            backgroundColor: '#239290', borderRadius: '0 10px 10px 0'
                                        }}>{sleepData?.day?.hours_fixed}:{sleepData?.day?.minutes_fixed}</div>
                                    </div>
                                    <div>
                                        <div>Promedio Noche</div>
                                        <div className={styles.chartInfo} style={{
                                            backgroundColor: '#FFAA00', borderRadius: '10px 0 0 10px'
                                        }}>{sleepData?.night?.hours_fixed}:{sleepData?.night?.minutes_fixed}</div>
                                    </div>
                                </div>
                                <ResponsiveContainer width={'100%'} height={170}>
                                    <BarChart width={'100%'} height={170} data={data2}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <YAxis
                                        />
                                        <Bar dataKey="day" fill="#239290" />
                                        <Bar dataKey="night" fill="#FFAA00" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>

                        <div className={`${styles.smallContainer}`}>
                            <div>N° de Casos de Riesgo Muy Crítico</div>
                            <div className={'d-flex justify-content-between align-items-end py-3'}>
                                <img src={'/icons/alert.png'} width={'40px'} height={'40px'} alt={'alert'} style={{ opacity: 0 }} />
                                <div className={styles.ball}>
                                    <div>{data?.both?.critic_risk}</div>
                                </div>
                                <Popup trigger={<img src={'/icons/alert.png'} width={'40px'} height={'40px'} alt={'alert'} />}
                                    position="left center">
                                    <div className={styles.infoPopup}>
                                        <div>Número de casos de operadores que han acumulado una deuda de sueño superior a 8 horas a lo largo de sus tres últimos periodos consecutivos de descanso.</div>

                                    </div>
                                </Popup>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}