import React, { useContext, useEffect, useState } from "react";
import Header from "../../dashboard/components/header";
import HeaderCreate from "../../../components/header_create";
import MainContext from "../../../context";
import Table from "../../../components/ajonjolib/table/table";
import { Button } from "../../../components/ajonjolib/inputs/ajonjolinput";
import BandsModal from "../company/modals/bands";
import ExportModal from "../company/modals/export";
import { eventBus, openModal } from "../../../components/ajonjolib/toasts/toast/toast";
import ImportModal from "../company/modals/import";
import BandsCreate from "./create";
import api from "../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderFilters from "../../../components/header_filters";
import { Label, Select, Text } from "../../../components/ajonjolib/inputs/ajonjolinput";

/**
 * Bands component that displays and manages a list of bands.
 * 
 * @component
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <Bands />
 * 
 * @description
 * This component fetches and displays a list of bands with various filters and search functionality.
 * It also allows importing and exporting band data, and provides modals for creating and updating bands.
 * 
 * @typedef {Object} Filter
 * @property {number} manufacturer - The selected manufacturer ID.
 * @property {number} model - The selected model ID.
 * @property {number} project_group - The selected project group ID.
 * @property {number} project - The selected project ID.
 * @property {number} battery - The selected battery level ID.
 * 
 * @typedef {Object} BatteryLevel
 * @property {string} name - The name of the battery level.
 * @property {number} id - The ID of the battery level.
 * @property {number} min - The minimum battery percentage.
 * @property {number} max - The maximum battery percentage.
 * 
 * @typedef {Object} Row
 * @property {boolean} active - The active status of the band.
 * @property {string} serial - The serial number of the band.
 * @property {Object} model - The model of the band.
 * @property {string} model.name - The name of the model.
 * @property {string} mac - The MAC address of the band.
 * @property {number} id - The ID of the band.
 * @property {string} key - The key of the band.
 * @property {Object} project_group - The project group of the band.
 * @property {string} project_group.name - The name of the project group.
 * @property {Object} project - The project of the band.
 * @property {string} project.name - The name of the project.
 * @property {Object} employee_device - The employee device assigned to the band.
 * @property {string} employee_device.vCodEmpleado - The employee code.
 * @property {string} employee_device.vApellidos - The employee's last name.
 * @property {string} employee_device.vNombres - The employee's first name.
 * @property {number} battery_level - The battery level of the band.
 * 
 * @typedef {Object} Column
 * @property {string} title - The title of the column.
 * @property {string} key - The key of the column.
 * @property {Function} [code] - The function to render the column data.
 * @property {Function} [header] - The function to render the column header.
 * 
 * @typedef {Object} ApiResponse
 * @property {number} status - The status code of the response.
 * @property {Array} data - The data of the response.
 * 
 * @typedef {Object} Api
 * @property {Function} get - The function to make a GET request.
 * @property {Function} post - The function to make a POST request.
 * 
 * @typedef {Object} MainContext
 * @property {Function} setSettings - The function to set the settings.
 * 
 * @typedef {Object} EventBus
 * @property {Function} emit - The function to emit an event.
 * 
 * @typedef {Object} Toast
 * @property {Function} success - The function to show a success toast.
 * 
 * @typedef {Object} Modal
 * @property {Function} openModal - The function to open a modal.
 * 
 * @typedef {Object} Button
 * @property {Function} onSubmit - The function to handle button submit.
 * 
 * @typedef {Object} Select
 * @property {Function} onChange - The function to handle select change.
 * 
 * @typedef {Object} Table
 * @property {Array<Column>} cols - The columns of the table.
 * @property {Array<Row>} rows - The rows of the table.
 * 
 * @typedef {Object} ToastContainer
 * 
 * @typedef {Object} BandsModal
 * @property {Function} onSuccess - The function to handle success.
 * 
 * @typedef {Object} ExportModal
 * @property {string} title - The title of the modal.
 * 
 * @typedef {Object} ImportModal
 * @property {string} title - The title of the modal.
 * @property {Function} submit - The function to handle submit.
 * 
 * @typedef {Object} Header
 * @property {string} title - The title of the header.
 * 
 * @typedef {Object} HeaderCreate
 * @property {string} name - The name of the header.
 * @property {string} width - The width of the header.
 * @property {boolean} open - The open state of the header.
 * @property {Function} setOpen - The function to set the open state.
 * 
 * @typedef {Object} HeaderFilters
 * @property {boolean} open - The open state of the filters.
 * @property {Function} setOpen - The function to set the open state.
 * 
 * @typedef {Object} BandsCreate
 * @property {Function} onSuccess - The function to handle success.
 */
export default function Bands() {
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [openCreate, setOpenCreate] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [filters, setFilters] = useState({});
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [project_groups, setProjectGroups] = useState([]);
    const [projects, setProjects] = useState([]);
    const [battery_levels, setBatteryLevels] = useState([]);

    useEffect(() => {
        api.get('project-groups/').then((response) => {
            if (response?.status !== 200) return;
            let values = response.data;
            values.unshift({ name: 'Todos', id: -1 });
            setProjectGroups(values);
        }).catch(error => {
            console.log(error);
        }
        );

        api.get('device-manufacturers/').then((response) => {
            if (response?.status !== 200) return;
            let values = response.data;
            values.unshift({ name: 'Todos', id: -1 });
            setBrands(values);
        }).catch(error => {
            console.log(error);
        }
        );
        let levels = [];
        levels.push({ name: 'Todos', id: -1, min: 0, max: 100 });
        levels.push({ name: '0-20%', id: 1, min: 0, max: 20 });
        levels.push({ name: '21-50%', id: 2, min: 21, max: 50 });
        levels.push({ name: '51-70%', id: 3, min: 51, max: 70 });
        levels.push({ name: '71-100%', id: 4, min: 71, max: 100 });

        setBatteryLevels(levels);

        setFilters({
            manufacturer: -1,
            model: -1,
            project_group: -1,
            project: -1,
            battery: -1
        });
    }, []);

    useEffect(() => {
        let url = 'device-models/?';
        url += 'manufacturer=' + filters['manufacturer'];
        api.get(url).then((response) => {
            if (response?.status !== 200) return;
            let values = response.data;
            values.unshift({ name: 'Todos', id: -1 });
            setModels(values);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [
        filters['manufacturer']
    ]);

    useEffect(() => {
        let url = 'projects/?';
        url += 'project_group=' + filters['project_group'] + '&';
        api.get(url).then((response) => {
            if (response?.status !== 200) return;
            let values = response.data;
            values.unshift({ name: 'Todos', id: -1 });
            setProjects(values);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [
        filters['project_group']
    ]);


    const { setSettings } = useContext(MainContext);
    useEffect(() => {
        document.title = "Sleeptech - Pulseras";
        setSettings((prev) => {
            return {
                ...prev, sidebar: 3, submenu: 5
            }
        });

        onTapSearch();
    }, [setSettings]);

    const onTapSearch = () => {
        let url = 'devices/?';
        for (const [key, value] of Object.entries(filters)) {
            if (value === -1) continue;
            if (key === 'battery') {
                let battery_level = battery_levels.find((x) => x.id === value);
                url += `min_battery_level=${battery_level.min}&max_battery_level=${battery_level.max}&`;
                continue;
            }
            url += `${key}=${value}&`;
        }
        api.get(url).then(response => {
            if (response?.status !== 200) {
                setRows([]);
                return;
            }
            setRows(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }

    const cols = [
        {
            title: 'Estado', key: 'active', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <div className={'me-2'} style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: data ? '#00C853' : '#EAEAEC' }} />
                        <div>{data}</div>
                    </div>
                )
            }
        },
        { title: 'N° Serie', key: 'serial' },
        { title: 'Marca y Modelo', key: 'model.name' },
        { title: 'MAC de Dispositivo', key: 'mac' },
        { title: 'ID', key: 'id' },
        { title: 'Llave de Dispositivo', key: 'key' },
        { title: 'Grupo de Proyecto', key: 'project_group.name' },
        { title: 'Proyecto', key: 'project.name' },
        {
            title: 'Usuario Asignado', key: 'employee_device', header: (title) => {
                return (
                    <div className={'d-flex justify-content-center flex-column align-items-center'}>
                        <div>{title}</div>
                        <div className={'d-flex justify-content-center align-items-center'} style={{ color: '#FFF' }}>
                            <div className={'d-flex justify-content-center align-items-center'} style={{ backgroundColor: '#5863B4', borderRadius: '5px 5px 0 0', width: '120px', height: '23px', fontSize: '11px' }}>ID</div>
                            <div className={'ms-3'} />
                            <div className={'d-flex justify-content-center align-items-center'} style={{ backgroundColor: '#5863B4', borderRadius: '5px 5px 0 0', width: '205px', height: '23px', fontSize: '11px' }}>Apellidos y Nombres</div>
                        </div>
                    </div>
                )
            }, code: (row, data) => {
                return (
                    <div className={'d-flex justify-content-center align-items-center'}>
                        <div style={{ width: 120 }}>{row?.employee_device?.vCodEmpleado}</div>
                        <div className={'ms-3'} />
                        <div className={'d-flex justify-content-left align-items-left'} style={{ width: 205 }}>
                            <div>{row?.employee_device?.vApellidos} {row?.employee_device?.vNombres}</div>
                        </div>
                    </div>
                )
            }
        },
        {
            title: 'Batería', key: 'battery_level', code: (row, data) => {
                if (!data) data = 0;
                const percentage = parseInt(data);
                const battery_id = percentage > 70 ? 1 : percentage > 50 ? 2 : percentage > 20 ? 3 : 4;
                return (
                    <div className={'d-flex align-items-center'}>
                        <img alt={'battery'} src={`/icons/battery/${battery_id}.png`} width={'14px'} />
                        <div className={'ps-2'}>{data}%</div>
                    </div>
                )
            }
        },
        {
            title: 'Acciones', key: 'actions', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faEdit} color={'#5863B4'} size={'lg'} role={'button'} onClick={() => {
                            eventBus.emit('bands_modal_open', row);
                        }} />
                    </div>
                )
            }
        }
    ];

    const onSearch = (text) => {
        setSearchText(text);
    }

    const filteredRows = rows.filter((row) => {
        const text = searchText.toLowerCase();
        const batteryLevelText = (row.battery_level?.toString().toLowerCase() ?? '0') + '%';
        return row.serial.toLowerCase().includes(text) ||
            row.model.name.toLowerCase().includes(text) ||
            row.model.model.toLowerCase().includes(text) ||
            row.id.toString().toLowerCase().includes(text) ||
            row.mac.toLowerCase().includes(text) ||
            row.key.toLowerCase().includes(text) ||
            row.project_group?.name?.toLowerCase()?.includes(text) ||
            row.project?.name?.toLowerCase()?.includes(text) ||
            row.employee_device?.vCodEmpleado.toLowerCase()?.includes(text) ||
            row.employee_device?.vApellidos?.toLowerCase()?.includes(text) ||
            row.employee_device?.vNombres?.toLowerCase()?.includes(text) ||
            batteryLevelText.includes(text);
    }
    );

    const onSubmitFile = async (file) => {
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file);
        const response = await api.post('devices/all/import/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        if (response?.status !== 200) return null;
        toast.success('Dispositivos importados correctamente');
        api.get('devices/').then(response => {
            setRows(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
        return response.data.errors ?? [];
    }

    return (
        // Set height to 100%
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <BandsModal onSuccess={() => {
                toast.success('Dispositivo actualizado correctamente');
                onTapSearch();
            }} />
            <ExportModal title={'Master de Pulseras'} />
            <ImportModal title={'Master de Pulseras'} submit={onSubmitFile}></ImportModal>
            <Header title={'Master de Pulseras'}>
                <>
                    <div>
                        <div className={'searchBox'}>
                            <div>
                                <img src={'/icons/search.png'} alt={'search'} width={'auto'} height={'24px'} />
                            </div>
                            <input type={'text'}
                                placeholder={'Buscar...'}
                                onChange={(e) => {
                                    onSearch(e.target.value);
                                }
                                }
                            />
                        </div>
                    </div>
                    <div className={'ms-3'} />
                    <div className={'d-flex align-items-center'}>
                        <Button variant={'primary'} name={'Importar'} icon={'/icons/import.png'} onSubmit={() => {
                            openModal('import_modal', { url: 'devices/all/export' })
                        }} />
                        <div className={'ms-3'} />
                        <Button variant={'primary'} name={'Exportar'} icon={'/icons/export.png'} onSubmit={() => {
                            openModal('export_modal', { url: 'devices/all/export' })
                        }} />
                    </div>
                </>
            </Header>
            <div className={'d-flex align-items-center'}>
                <div style={{ width: '100%' }}>
                    <HeaderCreate name={'Lista de Pulseras'} width={'700px'}
                        open={openCreate} setOpen={setOpenCreate}>
                        <BandsCreate onSuccess={() => {
                            toast.success('Dispositivo creado correctamente');
                            setOpenCreate(false);
                            api.get('devices/').then(response => {
                                setRows(response.data);
                            }).catch(error => {
                                console.log(error);
                            }
                            );
                        }
                        } />
                    </HeaderCreate>
                </div>
                <HeaderFilters open={openFilters} setOpen={setOpenFilters} >
                    <>
                        <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                            <div className={'mb-3'} >
                                <div style={{ flexBasis: '100%' }}>
                                    <Label>Marca</Label>
                                    <Select value={filters['manufacturer']} options={brands?.map((x) => {
                                        return {
                                            name: x.name,
                                            value: x.id
                                        }
                                    })} onChange={(val) => {
                                        setFilters({
                                            ...filters,
                                            manufacturer: val[0],
                                            model: -1
                                        })
                                    }} />
                                </div>
                                <div style={{ flexBasis: '100%' }}>
                                    <Label>Modelo</Label>
                                    <Select value={filters['model']} options={models?.map((x) => {
                                        return {
                                            name: x.name,
                                            value: x.id
                                        }
                                    })} onChange={(val) => {
                                        setFilters({ ...filters, model: val[0] })
                                    }} />
                                </div>
                                <div style={{ flexBasis: '100%' }}>
                                    <Label>Grupo de Proyecto</Label>
                                    <Select value={filters['project_group']} options={project_groups?.map((x) => {
                                        return {
                                            name: x.name,
                                            value: x.id
                                        }
                                    })} onChange={(val) => {
                                        setFilters({
                                            ...filters,
                                            project_group: val[0],
                                            project: -1
                                        })
                                    }} />
                                </div>
                                <div style={{ flexBasis: '100%' }}>
                                    <Label>Proyecto</Label>
                                    <Select value={filters['project']} options={projects?.map((x) => {
                                        return {
                                            name: x.name,
                                            value: x.id
                                        }
                                    })} onChange={(val) => {
                                        setFilters({ ...filters, project: val[0] })
                                    }} />
                                </div>
                                <div style={{ flexBasis: '100%' }}>
                                    <Label>Batería</Label>
                                    <Select value={filters['battery']} options={battery_levels?.map((x) => {
                                        return {
                                            name: x.name,
                                            value: x.id
                                        }
                                    })} onChange={(val) => {
                                        setFilters({ ...filters, battery: val[0] })
                                    }} />
                                </div>
                            </div>
                            <div className={'mb-3'} />

                        </div>
                        <div onClick={onTapSearch} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                            backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
                        }}>
                            <FontAwesomeIcon className={'me-3'} icon={faSearch} color={'#5863B4'} />
                            <div style={{ color: '#5863B4' }}>Buscar</div>
                        </div>
                    </>
                </HeaderFilters>
            </div>
            <div className={'mb-3'} />
            <div style={{ flex: 1, overflowY: 'scroll', overflowX: 'scroll' }}>
                <Table cols={cols} rows={filteredRows} />
            </div>

        </div>
    )
}