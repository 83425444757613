import React, { useState } from 'react';
import styles from "./index.module.css";
import { Label, Text } from "../../components/ajonjolib/inputs/ajonjolinput";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

/**
 * Lock component renders a lock icon.
 * 
 * @component
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <Lock />
 * 
 * @description
 * This component uses an SVG icon.
 * The component renders a lock icon.
 * The component is used in the Email and NewPassword components.
 */
function Lock() {
    return (
        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.3333 8.69401V5.69401C13.3333 2.56068 10.8 0.0273438 7.66667 0.0273438C4.53333 0.0273438 2 2.56068 2 5.69401V8.69401H0V20.0273H15.3333V8.69401H13.3333ZM3.33333 5.69401C3.33333 3.29401 5.26667 1.36068 7.66667 1.36068C10.0667 1.36068 12 3.29401 12 5.69401V8.69401H3.33333V5.69401ZM14 18.694H1.33333V10.0273H14V18.694Z"
                fill="white" />
            <path
                d="M6.99992 14.494V16.694H8.33325V14.494C8.73325 14.2607 8.99992 13.8273 8.99992 13.3607C8.99992 12.6273 8.39992 12.0273 7.66659 12.0273C6.93325 12.0273 6.33325 12.6273 6.33325 13.3607C6.33325 13.8607 6.59992 14.294 6.99992 14.494Z"
                fill="white" />
        </svg>
    )
}

/**
 * Email component renders the email input.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setStep - Callback function to be called on successful form submission.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <Email setStep={() => console.log('Success!')} />
 * 
 * @description
 * This component uses the `useState` hook to manage the state of the email input.
 * The component renders an email input field.
 * The component also renders a submit button.
 *  
 * When the submit button is clicked, the component sends a POST request to the API to request a password reset.
 * If the request is successful, the component calls the `setStep` callback function to move to the next step.
 * If the request fails, the component displays an error message.
 */
function Email({ setStep }) {
    const [email, setEmail] = useState('')

    const submit = () => {
        api.post('/password/request', { email }).then((response) => {
            if (response.status !== 200) return;
            setStep((prev) => prev + 1);
        });
    }

    return (
        <div className={styles.content}>
            <div className={styles.title}>Reestablecer la contraseña</div>

            <div className={styles.notes}>Indícanos tu dirección de correo electrónico o nombre de usuario y te
                enviaremos un enlace para que
                puedas volver a acceder a tu cuenta.
            </div>

            <Label style={{ color: '#fff' }}>Dirección de correo electrónico</Label>
            <Text value={email} onChange={(val) => setEmail(val)} />

            <div className={'d-flex align-items-center justify-content-center'}>
                <div className={styles.button} onClick={submit}>Enviar Enlace</div>
            </div>
        </div>
    )
}

/**
 * NewPassword component renders the new password input.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setStep - Callback function to be called on successful form submission.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <NewPassword setStep={() => console.log('Success!')} />
 * 
 * @description
 * This component uses the `useState` hook to manage the state of the password and confirm password inputs.
 * The component renders password and confirm password input fields.
 * The component also renders a submit button.
 * 
 * When the submit button is clicked, the component sends a POST request to the API to reset the password.
 * If the request is successful, the component calls the `setStep` callback function to move to the next step.
 * If the request fails, the component displays an error message.
 * If the passwords do not match, the component displays an error message.
 * If the password is less than 8 characters long, the component displays an error message.
 */
function NewPassword({ setStep }) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const params = useParams();

    const submit = async () => {
        if (password !== confirmPassword) {
            toast.error('Las contraseñas no coinciden');
            return;
        }
        if (password.length < 8) {
            toast.error('La contraseña debe tener al menos 8 caracteres');
            return;
        }
        const data = {
            password
        }
        const url = `/password/reset/${params.uuid}/${params.token}/`;
        const response = await api.post(url, data);
        if (response.status !== 200) {
            toast.error('Ha ocurrido un error al reestablecer la contraseña');
            return;
        }
        setStep((prev) => prev + 1);
    }

    return (
        <div className={styles.content}>
            <div className={styles.title}>Reestablecer la contraseña</div>

            <div className={styles.notes}>Ingresa tu nueva contraseña</div>

            <Label className={'mb-2'} style={{ color: '#fff', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <Lock />
                <div className={'ms-2'}>Nueva Contraseña</div>
            </Label>
            <Text type={'password'} value={password} onChange={(val) => setPassword(val)} />
            <div className={'mt-4'} />
            <Label className={'mb-2'} style={{ color: '#fff', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <Lock />
                <div className={'ms-2'}>Confirmar Contraseña</div>
            </Label>
            <Text type={'password'} value={confirmPassword} onChange={(val) => setConfirmPassword(val)} />

            <div className={'d-flex align-items-center justify-content-center'}>
                <div className={styles.button} onClick={submit}>Confirmar</div>
            </div>

        </div>
    )
}

/**
 * Success component renders a success message.
 * 
 * @component
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <Success />
 * 
 * @description
 * This component renders a success message.
 * The component is used when the password reset is successful.
 * The component displays a message indicating that the password has been reset successfully.
 * The component also displays a button to navigate back to the login screen.
 * The component uses the `useNavigate` hook to navigate to the login screen.
 */
function Success() {
    const navigate = useNavigate();
    return (
        <div className={styles.content}>
            <div className={styles.title}>Contraseña reestablecida correctamente</div>

            <div className={styles.notes}>Tu contraseña ha sido reestablecida correctamente.</div>

            <div className={styles.darkNote}>Retorna  a la pantalla de Login para acceder a tu cuenta con tu nueva contraseña.</div>

            <div className={'d-flex align-items-center justify-content-center'}>
                <div className={styles.button} onClick={() => {
                    navigate('/login');
                }}>Volver
                </div>
            </div>
        </div>
    )
}

/**
 * EmailSended component renders a message indicating that an email has been sent.
 * 
 * @component
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <EmailSended />
 * 
 * @description
 * This component renders a message indicating that an email has been sent.
 * The component is used when the password reset email has been sent successfully.
 * The component displays a message indicating that an email has been sent to the user's email address.
 * The component also displays a message instructing the user to check their inbox and follow the instructions.
 * The component also displays a button to navigate back to the login screen.
 * The component uses the `useNavigate` hook to navigate to the login screen.
 */
function EmailSended() {
    const navigate = useNavigate();
    return (
        <div className={styles.content}>
            <div className={styles.title}>Correo enviado</div>

            <div className={styles.notes}>Hemos enviado un correo a tu dirección de correo electrónico con un enlace para reestablecer tu contraseña.</div>

            <div className={styles.darkNote}>Por favor revisa tu bandeja de entrada y sigue las instrucciones.</div>

            <div className={'d-flex align-items-center justify-content-center'}>
                <div className={styles.button} onClick={() => {
                    navigate('/login');
                }}>Volver
                </div>
            </div>
        </div>
    )
}

/**
 * RequestPassword component renders the password reset request form.
 * 
 * @component
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <RequestPassword />
 * 
 * @description
 * This component renders the password reset request form.
 * The component uses the `useState` hook to manage the state of the form.
 * The component renders the Email component by default.
 * The component renders the EmailSended component when the email has been sent successfully.
 * The component uses the `setStep` function to switch between the Email and EmailSended components.
 * The component also renders the logo and background image.
 * The component is used on the password reset request page.
 */
export function RequestPassword({ }) {
    const [step, setStep] = useState(0);

    const getStep = () => {
        if (step === 0) return <Email setStep={setStep} />
        else if (step === 1) return <EmailSended />
    }

    return (
        <div className={styles.container}>
            <img className={styles.logo} alt={'stracon'} src={'/branding/stracontech_white.png'} width={'223px'} />
            <img className={styles.background} src={'/login_background_dark.png'} alt={'background'} />

            {getStep()}
        </div>
    )
}

/**
 * ResetPassword component renders the password reset form.
 * 
 * @component
 *  
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <ResetPassword />
 * 
 * @description
 * This component renders the password reset form.
 * The component uses the `useState` hook to manage the state of the form.
 * The component renders the NewPassword component by default.
 * The component renders the Success component when the password has been reset successfully.
 * The component uses the `setStep` function to switch between the NewPassword and Success components.
 * The component also renders the logo and background image.
 * The component is used on the password reset page.
 */
export function ResetPassword() {
    const [step, setStep] = useState(0);

    const getStep = () => {
        if (step === 0) return <NewPassword setStep={setStep} />
        else if (step === 1) return <Success />
    }

    return (
        <div className={styles.container}>
            <img className={styles.logo} alt={'stracon'} src={'/branding/stracontech_white.png'} width={'223px'} />
            <img className={styles.background} src={'/login_background_dark.png'} alt={'background'} />

            {getStep()}
        </div>
    )

}