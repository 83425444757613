import React, { useContext, useEffect, useState } from "react";
import Header from "../../dashboard/components/header";
import CompanyDetailHeader from "./components/company_detail_header/company_detail_header";
import CompanyData from "./sections/company_data";
import Group from "./sections/group";
import Project from "./sections/project";
import Guard from "./sections/guard";
import Email from "./sections/email";
import MainContext from "../../../context";
import GroupModal from "./modals/group";
import ProjectModal from "./modals/project";
import GuardModal from "./modals/guard";
import EmailModal from "./modals/email";
import { Button } from "../../../components/ajonjolib/inputs/ajonjolinput";
import { useParams } from "react-router-dom";
import api from "../../../api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GroupConfigModal from "./modals/group_config";
import { openModal } from "../../../components/ajonjolib/toasts/toast/toast";
import ExportProjectModal from "./modals/export_project";
import ExportGuardModal from "./modals/export_guard";

/**
 * CompanyDetail component renders the company detail page.
 * 
 * @component
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <CompanyDetail />
 * 
 * @description
 * This component uses several hooks:
 * - `useState` to manage the state of the selected section and the company object.
 * - `useEffect` to update the document title and the sidebar and submenu settings.
 * 
 * The component renders a header with a search box and an export button.
 * The component also renders a company detail header and the selected section.
 * The selected section can be one of the following:
 * - CompanyData
 * - Group
 * - Project
 * - Guard
 * - Email
 * The component also renders several modals for editing and exporting data.
 * The modals include:
 * - GroupModal
 * - GroupConfigModal
 * - ProjectModal
 * - GuardModal
 * - EmailModal
 * - ExportProjectModal
 * - ExportGuardModal
 */
export default function CompanyDetail() {
    const [selected, setSelected] = React.useState(0);
    const { setSettings } = useContext(MainContext);
    const { id } = useParams();
    const [company, setCompany] = useState({});
    const [reload, setReload] = useState(false);

    useEffect(() => {
        document.title = "Sleeptech - Empresa";
        setSettings((prev) => {
            return {
                ...prev, sidebar: 3, submenu: 0
            }
        });

        api.get(`subenterprises/${id}/`).then((response) => {
            setCompany(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [setSettings]);

    const renderSection = () => {
        if (selected === 0) return (<CompanyData company={company} />)
        if (selected === 1) return (<Group company={company} />)
        if (selected === 2) return (<Project company={company} />)
        if (selected === 3) return (<Guard company={company} />)
        if (selected === 4) return (<Email company={company} />)
    }

    const onCreate = () => {
        const last = selected;
        setSelected(-1);
        setTimeout(() => {
            setSelected(last);
        }, 100);
    }

    return (
        <div>
            <GroupModal onSuccess={() => { toast.success('Grupo editado correctamente'); onCreate(); }} company={company} />
            <GroupConfigModal onSuccess={() => { toast.success('Configuración de grupo editada correctamente'); onCreate(); }} company={company} />
            <ProjectModal onSuccess={() => { toast.success('Proyecto editado correctamente'); onCreate(); }} company={company} />
            <GuardModal onSuccess={() => { toast.success('Guardia editada correctamente'); onCreate(); }} company={company} />
            <EmailModal onSuccess={() => { toast.success('Email editado correctamente'); onCreate(); }} company={company} />

            <ExportProjectModal onSuccess={() => { toast.success('Proyecto exportado correctamente'); onCreate(); }} company={company} />
            <ExportGuardModal onSuccess={() => { toast.success('Guardia exportada correctamente'); onCreate(); }} company={company} />

            <Header title={'Empresa'} subtitle={'Stracon'}>
                <div className={'searchBox'}>
                    <div>
                        <img src={'/icons/search.png'} alt={'search'} width={'auto'} height={'24px'} />
                    </div>
                    <input type={'text'} placeholder={'Buscar...'}
                        onChange={(e) => {
                            // onSearch(e.target.value);
                        }
                        }
                    />
                </div>
                <Button variant={'primary'} name={'Exportar'} icon={'/icons/export.png'} onSubmit={() => {
                    if (selected == 2) {
                        // Proyecto
                        openModal('export_project_modal')
                    } else if (selected == 3) {
                        // Guardia
                        openModal('export_guard_modal')
                    } else {
                        toast.error('No se puede exportar esta sección');
                    }
                    // openModal('export_modal', {
                    //     url: 'summary/user-detail/export/',
                    //     query: getParsedFilters(),
                    // })
                }} />
            </Header>
            <CompanyDetailHeader selected={selected} setSelected={setSelected}
                company={company} onCreate={onCreate} />
            <div className={'mb-3'} />
            {renderSection()}

        </div>
    );
}