import React, { useEffect } from 'react';
import { Date as AjonjoDate, Select } from "../../ajonjolib/inputs/ajonjolinput"
import styles from "../../../screens/dashboard/components/filters/index.module.css";
import { currentDateStr } from "../../../utils/date.js";
import DatePicker from 'react-datepicker';


export default function DetailFilters({ filters, setFilters, projects, projectGroups, companies, shifts }) {
    return (
        <>
            <div>Empresa</div>
            <Select className={'mb-4'} value={[filters['company']]}
                options={
                    [
                        { name: "Todas", value: -1 },
                        ...companies?.map((x) => {
                            return { name: x.name, value: x.id };
                        })]} onChange={(val) => {
                            if (val[0] === -1) {
                                setFilters({
                                    ...filters,
                                    company: -1,
                                    project_group: -1,
                                    project: -1,
                                    turn: -1,
                                });
                                return;
                            }
                            setFilters({
                                ...filters,
                                company: val[0]
                            })
                        }} />

            <div>Grupo de Proyecto</div>
            <Select className={'mb-4'} value={[filters['project_group']]} onChange={(val) => {

                if (val[0] === -1) {
                    setFilters({
                        ...filters,
                        project_group: -1,
                        project: -1,
                        turn: -1,
                    });
                    return;
                }

                setFilters({
                    ...filters,
                    project_group: val[0]
                });
            }} options={
                [
                    { name: "Todos", value: -1 },
                    ...projectGroups?.map((x) => {
                        return { name: x.name, value: x.id }
                    })]
            } />

            <div>Proyecto</div>
            <Select className={'mb-4'} value={[filters['project']]} onChange={(val) => {

                if (val[0] === -1) {
                    setFilters({
                        ...filters,
                        project: -1,
                        turn: -1,
                    });
                    return;
                }

                setFilters({
                    ...filters,
                    project: val[0]
                });
            }} options={[
                { name: "Todos", value: -1 },
                ...projects?.map((x) => {
                    return { name: x.name, value: x.id }
                })]} />

            <div>Turno</div>
            <Select className={'mb-4'} value={[filters['turn']]}
                options={
                    [{ name: "Todos", value: -1 },
                    ...shifts?.map((x) => {
                        return { name: x?.isDay ? "Día" : "Noche", value: x.id };
                    })]} onChange={(val) => {
                        setFilters({
                            ...filters,
                            turn: val[0]
                        })
                    }} />

            <div>Nivel de Riesgo</div>
            <Select className={'mb-4'} value={[filters['risk_level']]} onChange={(val) => {
                setFilters({
                    ...filters,
                    risk_level: val[0]
                });
            }} options={[{ name: "Todos", value: "all" }, { name: "Bajo", value: "low" }, { name: "Medio", value: "medium" }, { name: "Alto", value: "high" }]} />

            <div>Fecha Consulta</div>
            <div className={styles.dateFilterOnly} style={{
                marginBottom: '100px'
            }}>
                <DatePicker
                    selected={filters['date']}
                    locale="es"
                    dateFormat={'dd/MM/yyyy'}
                    onChange={(date) => {
                        setFilters({
                            ...filters,
                            date: date
                        });
                    }
                    }
                    className={styles.datePicker}
                />
            </div>
        </>
    )
}