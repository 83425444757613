import React from "react";
import styles from './index.module.css'

/**
 * TextArea component renders a styled textarea input.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.value - The current value of the textarea.
 * @param {function} props.onChange - The function to call when the textarea value changes.
 * @param {string} props.placeholder - The placeholder text for the textarea.
 * @returns {JSX.Element} The rendered TextArea component.
 */
export default function TextArea({ value, onChange, placeholder }) {
    return (
        <div className={styles.container}>
            <textarea value={value} onChange={(event) => {
                onChange && onChange(event.target.value);
            }} placeholder={placeholder} />
        </div>
    )
}