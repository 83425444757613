import React, { useEffect, useState } from "react";
import HeaderDropdown from "../../../../components/header_dropdown";
import styles from './index.module.css';
import { Area, LineChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Line } from "recharts";

/**
 * CustomXAxis component renders a custom x-axis label.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.x - The x-coordinate of the label.
 * @param {number} props.y - The y-coordinate of the label.
 * @param {Object} props.payload - The payload object containing the value of the label.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <CustomXAxis x={10} y={20} payload={{ value: '2022-01-01' }} />
 * 
 * @description
 * This component renders a custom x-axis label with the month name.
 * The month name is extracted from the payload value and displayed in Spanish.
 */
function CustomXAxis({ x, y, payload }) {
    console.log(payload);
    const current_date = payload?.value;
    const monthsInSpanish = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    const date = new Date(current_date);
    const monthIndex = date.getMonth();

    return (
        <text x={x} y={y} dy={16} textAnchor="middle" fill="#666">
            {monthsInSpanish[monthIndex + 1]}
        </text>
    )
}

/**
 * CustomToolTipRisk component renders a custom tooltip for the risk classification graph.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.active - Indicates if the tooltip is active.
 * @param {Array} props.payload - The payload array containing the tooltip data.
 * @param {string} props.label - The label of the tooltip.
 * @param {string} props.unit - The unit of the tooltip values.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <CustomToolTipRisk active={true} payload={[{ value: 10 }, { value: 20 }, { value: 30 }]} label={'2022-01-01'} unit={'%'} />
 * 
 * @description
 * This component renders a custom tooltip for the risk classification graph.
 * The tooltip displays the risk classification values for high, moderate, and low risk.
 * The values are displayed in percentage format.
 * The tooltip is displayed only when active.
 * If the label is undefined, the component returns a div with a dash.
 * The tooltip displays the values for high, moderate, and low risk.
 * The values are displayed in percentage format.
 */
function CustomToolTipRisk({ active, payload, label, unit }) {
    if (active) {
        if (label === undefined) return <div>-</div>

        return (
            <div style={{ backgroundColor: '#FFF', border: '1px solid #000', borderRadius: '6px', textAlign: 'left' }} className={'p-1'}>
                {payload &&
                    <>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#F66565'
                            }} />
                            <div>Riesgo Alto: {parseInt(payload[0]?.value)}<b>{unit}</b></div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#FFC90C'
                            }} />
                            <div>Riesgo Moderado: {parseInt(payload[1]?.value)}<b>{unit}</b></div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#B3C739'
                            }} />
                            <div>Riesgo Bajo: {parseInt(payload[2]?.value)}<b>{unit}</b></div>
                        </div>
                    </>}
            </div>
        );
    }

    return null;
}

/**
 * CustomToolTipSleep component renders a custom tooltip for the sleep time graph.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.active - Indicates if the tooltip is active.
 * @param {Array} props.payload - The payload array containing the tooltip data.
 * @param {string} props.label - The label of the tooltip.
 * @param {string} props.unit - The unit of the tooltip values.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <CustomToolTipSleep active={true} payload={[{ value: 10 }, { value: 20 }]} label={'2022-01-01'} unit={'min.'} />
 * 
 * @description
 * This component renders a custom tooltip for the sleep time graph.
 * The tooltip displays the total sleep time values for day and night.
 * The values are displayed in minutes format.
 * The tooltip is displayed only when active.
 * If the label is undefined, the component returns a div with a dash.
 * The tooltip displays the values for day and night sleep time.
 */
function CustomToolTipSleep({ active, payload, label, unit }) {
    if (active) {
        if (label === undefined) return <div>-</div>
        const day = parseInt(payload[0]?.value);
        const night = parseInt(payload[1]?.value);
        return (
            <div style={{ backgroundColor: '#FFF', border: '1px solid #000', borderRadius: '6px', textAlign: 'left' }} className={'p-1'}>
                {payload &&
                    <>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#239290'
                            }} />
                            <div>Día: {parseInt(payload[0]?.value)}<b>{unit}</b></div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#FFAA00'
                            }} />
                            <div>Noche: {parseInt(payload[1]?.value)}<b>{unit}</b></div>
                        </div>
                    </>
                }
            </div>
        )
    }

    return null;
}

/**
 * CustomToolTipCritical component renders a custom tooltip for the critical risk occurrence graph.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.active - Indicates if the tooltip is active.
 * @param {Array} props.payload - The payload array containing the tooltip data.
 * @param {string} props.label - The label of the tooltip.
 * @param {string} props.unit - The unit of the tooltip values.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <CustomToolTipCritical active={true} payload={[{ value: 10 }, { value: 20 }]} label={'2022-01-01'} unit={''} />
 * 
 * @description
 * This component renders a custom tooltip for the critical risk occurrence graph.
 * The tooltip displays the occurrence values for very critical risk during the day and night.
 * The values are displayed without a unit.
 * The tooltip is displayed only when active.
 * If the label is undefined, the component returns a div with a dash.
 * The tooltip displays the values for day and night critical risk occurrence.
 */
function CustomToolTipCritical({ active, payload, label, unit }) {
    if (active) {
        if (label === undefined) return <div>-</div>

        return (
            <div style={{ backgroundColor: '#FFF', border: '1px solid #000', borderRadius: '6px', textAlign: 'left' }} className={'p-1'}>
                {payload &&
                    <>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#239290'
                            }} />
                            <div>Día: {parseInt(payload[0]?.value)}<b>{unit}</b></div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#FFAA00'
                            }} />
                            <div>Noche: {parseInt(payload[1]?.value)}<b>{unit}</b></div>
                        </div>
                    </>
                }
            </div>
        );
    }

    return null;
}

/**
 * CustomToolTipUsed component renders a custom tooltip for the system usage graph.
 * 
 * @component
 * 
 * @param {Object} props - The component props.
 * @param {boolean} props.active - Indicates if the tooltip is active.
 * @param {Array} props.payload - The payload array containing the tooltip data.
 * @param {string} props.label - The label of the tooltip.
 * @param {string} props.unit - The unit of the tooltip values.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <CustomToolTipUsed active={true} payload={[{ value: 10 }, { value: 20 }]} label={'2022-01-01'} unit={'%'} />
 * 
 * @description
 * This component renders a custom tooltip for the system usage graph.
 * The tooltip displays the usage values for system usage and non-usage.
 * The values are displayed in percentage format.
 * The tooltip is displayed only when active.
 * If the label is undefined, the component returns a div with a dash.
 * The tooltip displays the values for system usage and non-usage.
 */
function CustomToolTipUsed({ active, payload, label, unit }) {
    if (active) {
        if (label === undefined) return <div>-</div>

        return (
            <div style={{ backgroundColor: '#FFF', border: '1px solid #000', borderRadius: '6px', textAlign: 'left' }} className={'p-1'}>
                {payload &&
                    <>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#B3C739'
                            }} />
                            <div>Usaron: {parseInt(payload[0]?.value)}<b>{unit}</b></div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className={'me-2'} style={{
                                width: '10px', height: '10px',
                                borderRadius: '50%', backgroundColor: '#F66565'
                            }} />
                            <div>No Usaron: {parseInt(payload[1]?.value)}<b>{unit}</b></div>
                        </div>
                    </>
                }
            </div>
        );
    }

    return null;
}

/**
 * GenericGraph component renders a generic graph with custom tooltip and x-axis.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the graph.
 * @param {Array} props.data - The data array containing the graph data.
 * @param {Array} props.format - The format array containing the graph format details.
 * @param {string} props.unit - The unit of the graph values.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <GenericGraph title={'Clasificación de Riesgo'} data={data} format={format} unit={'%'} />
 * 
 * @description
 * This component renders a generic graph with custom tooltip and x-axis.
 * The graph displays the data with the specified title and unit.
 * The graph format is specified in the format array.
 * The tooltip and x-axis are customized based on the graph data.
 * The graph data is displayed using the specified format.
 * The graph is responsive and adjusts to the container width.
 */
function GenericGraph({ title, data, format, unit, tooltip, tickFormatter }) {
    return (
        <div className={styles.graphContainer}>
            <div className={'d-flex justify-content-between'}>
                <div>{title}</div>
                <div style={{ flexBasis: '40%' }}>
                    <div className={'d-flex align-items-center justify-content-between'}>
                        {format.map((entry) => {
                            return (
                                <div className={'d-flex align-items-center'}>
                                    <div style={{ backgroundColor: entry.hex, borderRadius: '50%', width: '20px', height: '20px' }} />
                                    <div className={'ps-1'} style={{ whiteSpace: 'nowrap', fontFamily: 'Poppins, sans-serif', fontSize: '12px', letterSpacing: '0', fontWeight: '400', lineHeight: '18px' }}>{entry.name}</div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
            <ResponsiveContainer width={'100%'} height={300}>
                <LineChart width={'100%'} height={300} data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <defs>
                        {format.map((entry) => {
                            return (
                                <linearGradient id={entry.key} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={entry.hex} stopOpacity={0.8} />
                                    <stop offset="95%" stopColor={entry.hex} stopOpacity={0} />
                                </linearGradient>
                            )
                        })
                        }
                    </defs>
                    <Tooltip content={tooltip} />
                    <XAxis dataKey="name" tick={<CustomXAxis />} />
                    <YAxis
                        padding={{ top: 30 }}
                        label={{
                            value: unit, position: 'insideTop',
                            fill: '#807f7f', fontSize: '14px', fontWeight: '600'
                        }}
                        tickFormatter={tickFormatter}

                    />
                    {
                        format.map((entry) => {
                            return <Line dataKey={entry.key} stroke={entry.hex} fillOpacity={1} fill={`url(#${entry.key})`} />
                        })
                    }
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

/**
 * Evolutive component renders the evolutive statistics graph.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.data - The data object containing the evolutive statistics data.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <Evolutive data={data} />
 * 
 * @description
 * This component renders the evolutive statistics graph with the following details:
 * - Risk classification graph
 * - Sleep time graph
 * - Critical risk occurrence graph
 * - System usage graph
 */
export default function Evolutive({ data }) {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <HeaderDropdown open={open} setOpen={setOpen} name={'Estadística Evolutiva'} />

            {open &&
                <>
                    <GenericGraph tooltip={<CustomToolTipRisk unit={'%'} />} title={'Clasificación de Riesgo'} unit={'%'} data={data?.risk_data} format={[
                        { hex: '#F66565', name: 'Riesgo Alto', key: 'high_risk' },
                        { hex: '#FFC90C', name: 'Riesgo Moderado', key: 'mid_risk' },
                        { hex: '#B3C739', name: 'Riesgo Bajo', key: 'low_risk' },
                    ]} tickFormatter={(value) => `${value}%`}
                    />

                    <GenericGraph tooltip={<CustomToolTipSleep unit={'min.'} />} title={'Tiempo Total de Sueño'} unit={'Hor.'} data={data?.sleep_time} format={[
                        { hex: '#239290', name: 'Día', key: 'day_minutes' },
                        { hex: '#FFAA00', name: 'Noche', key: 'night_minutes' },
                    ]} tickFormatter={(value) => {
                        const hours = Math.floor(value / 60);
                        const fract_minutes = value % 60;
                        return `${hours}h ${fract_minutes < 10 ? "0" + fract_minutes : fract_minutes}m`
                    }}
                    />

                    <GenericGraph tooltip={<CustomToolTipCritical unit={''} />} title={'Ocurrencia de Riesgo Muy Crítico'} unit={'Ocurr.'} data={data?.risk_data} format={[
                        { hex: '#239290', name: 'Día', key: 'critical_day' },
                        { hex: '#FFAA00', name: 'Noche', key: 'critical_night' },
                    ]} />

                    <GenericGraph tooltip={<CustomToolTipUsed unit={'%'} />} title={'Uso del Sistema'} unit={'Ocurr.'} data={data?.usage} format={[
                        { hex: '#B3C739', name: 'Usaron', key: 'used_percent' },
                        { hex: '#F66565', name: 'No Usaron', key: 'not_used_percent' },
                    ]}
                        tickFormatter={
                            (value) => `${value}%`
                        }
                    />
                </>
            }
        </div>
    )
}