import React, { useState } from "react";
import styles from "../../screens/dashboard/components/risk/index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPlus } from "@fortawesome/free-solid-svg-icons";

/**
 * HeaderFilters component renders a button that toggles a filter popup.
 * 
 * @param {Object} props - The properties object.
 * @param {string} props.name - The name of the filter.
 * @param {React.ReactNode} props.children - The content to be displayed inside the filter popup.
 * @param {string} [props.width] - The width of the filter popup.
 * @param {string} [props.height] - The height of the filter popup.
 * @param {boolean} [props.open] - The state to control the visibility of the filter popup.
 * @param {Function} [props.setOpen] - The function to set the visibility state of the filter popup.
 * 
 * @returns {JSX.Element} The rendered HeaderFilters component.
 */
export default function HeaderFilters({ name, children, width, height,
    open, setOpen,
}) {
    const [localOpen, setLocalOpen] = useState(false);
    let openCreate = open;
    let setOpenCreate = setOpen;
    if (!open && !setOpen) {
        openCreate = localOpen;
        setOpenCreate = setLocalOpen;
    }
    return (
        <div className={'d-flex justify-content-between align-items-center'}>
            <div className={styles.createButton}>
                <div className={styles.createButtonInner} onClick={() => setOpenCreate((prev) => !prev)}>
                    <FontAwesomeIcon className={'me-3'} icon={faFilter} color={'#5863B4'} size={'xl'} />
                    <div>Filtros</div>
                </div>
                {openCreate &&
                    <div className={`${styles.createPopup}`} style={{
                        width: width ? width : '100%', height: height ? height : 'auto',
                        borderRadius: width ? '9px 0 9px 9px' : '0 0 9px 9px',
                    }}>
                        {children}
                    </div>
                }
            </div>
        </div>
    )
}