import React, { useEffect, useState } from "react";
import Switch from "../../../../components/ajonjolib/switch";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import { Button, Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import styles from './modals.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";
import { GreenFace, RedFace, YellowFace } from "../../../../utils/faces";
import TimePicker from "../../../../components/ajonjolib/inputs/time";
import { numToTimePicker, timePickerToNum } from "../../../../utils/date";

export default function GroupConfigModal({ onSuccess }) {
    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState({})

    useEffect(() => {
        eventBus.subscribe('group_config_modal_open', (rec_data) => {
            setIsOpen(true);
            const rec_data_copy = {
                ...rec_data,
            }
            rec_data_copy.subenterprise = rec_data.subenterprise?.id;
            rec_data_copy.low_risk_hours = numToTimePicker(rec_data.low_risk_hours);
            rec_data_copy.mid_risk_hours = numToTimePicker(rec_data.mid_risk_hours);
            rec_data_copy.high_risk_hours = numToTimePicker(rec_data.high_risk_hours);
            rec_data_copy.parameter_hours = numToTimePicker(rec_data.parameter_hours);
            setForm(rec_data_copy);
        });
        eventBus.subscribe('group_config_modal_close', (data) => { setIsOpen(false) });
    }, []);

    const submit = () => {
        const copy = {
            ...form
        }
        console.log(copy);
        copy.low_risk_hours = timePickerToNum(copy.low_risk_hours);
        copy.mid_risk_hours = timePickerToNum(copy.mid_risk_hours);
        copy.high_risk_hours = timePickerToNum(copy.high_risk_hours);
        copy.parameter_hours = timePickerToNum(copy.parameter_hours);
        console.log(copy);
        api.put(`project-groups/${form?.id}/`, copy).then((response) => {
            if (response?.status === 200) {
                setIsOpen(false);
                onSuccess();
                return;
            }
        });
    }

    return (
        <div>
            {isOpen &&
                <Modal title={'Grupo de Proyecto'} desc={'Configuración'} close={() => setIsOpen(false)}>
                    <Label>RANGO DE HORAS DE RIESGO</Label>
                    <div className={'d-flex align-items-center '}>
                        <GreenFace scale={0.8} />
                        <div className={'mx-1'} />
                        <p style={{ width: '100px', textAlign: 'left' }}>Riesgo Bajo</p>
                        <div className={'mx-3'} />
                        <TimePicker
                            value={form['low_risk_hours']} onChange={(val) => {
                                setForm({ ...form, low_risk_hours: val })
                            }
                            } />
                    </div>
                    <div className={'mt-2'} />
                    <div className={'d-flex align-items-center '}>
                        <YellowFace scale={0.8} />
                        <div className={'mx-1'} />
                        <p style={{ width: '100px', textAlign: 'left' }}>Riesgo Medio</p>
                        <div className={'mx-3'} />
                        <TimePicker
                            value={form['mid_risk_hours']} onChange={(val) => {
                                setForm({ ...form, mid_risk_hours: val })
                            }
                            } />
                    </div>
                    <div className={'mt-2'} />
                    <div className={'d-flex align-items-center '}>
                        <RedFace scale={0.8} />
                        <div className={'mx-1'} />
                        <p style={{ width: '100px', textAlign: 'left' }}>Riesgo Alto</p>
                        <div className={'mx-3'} />
                        <TimePicker
                            value={form['high_risk_hours']} onChange={(val) => {
                                setForm({ ...form, high_risk_hours: val })
                            }
                            } />
                    </div>


                    <div className={'mt-4 w-100 pe-3'}>
                        <Label>RIESGO CRÍTICO</Label>
                        <div className={styles.buttonSelectContainer}>
                            <div className={`${styles.buttonSelect} ${form.risk_method === 'sleep_debt' ? styles.selected : ''}`} onClick={() => setForm({ ...form, risk_method: 'sleep_debt' })}>
                                <div>Deuda de Sueño</div>
                            </div>
                            <div className={`${styles.buttonSelect} ${form.risk_method === 'hours' ? styles.selected : ''}`} onClick={() => setForm({ ...form, risk_method: 'hours' })}>
                                <div>N° de Horas</div>
                            </div>
                        </div>
                    </div>

                    <div className={'align-items-center w-100 justify-content-between mt-4 d-flex'}>
                        <div style={{ flexBasis: '45%' }} />
                        <TimePicker
                            value={form['parameter_hours']} onChange={(val) => {
                                setForm({ ...form, parameter_hours: val })
                            }
                            } />
                        <div style={{ flexBasis: '45%' }} />
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                        <div className={styles.button} onClick={submit}>
                            <FontAwesomeIcon icon={faSave} color={'#52A035'} />
                            <div className={'ps-2'}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}