import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import RouteContainer from "./routes";
import Sidebar from "./components/sidebar/sidebar";
import { ContextProvider } from "./context";
import ToastContainer from "./components/ajonjolib/toasts/toast/toast_container";
import { WebSocketProvider } from './wsContext';
import { FiltersProvider } from './filtersContext';

function App() {
    const path = window.location.pathname;
    return (
        <div className="App">
            <ContextProvider>
                <WebSocketProvider>
                    <FiltersProvider>
                        <Router>
                            {(path !== '/login' && path !== '/password_reset') && <Sidebar />}
                            <div style={(path !== '/login' && path !== '/password_reset') ? {

                                marginLeft: '250px',
                                padding: '20px',
                                height: '100%',
                            } : {}}>
                                <RouteContainer />
                            </div>
                        </Router>
                    </FiltersProvider>
                </WebSocketProvider>
                <ToastContainer />
            </ContextProvider>
        </div>
    );
}

export default App;
