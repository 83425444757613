import React, { useEffect, useState } from "react";
import { Label, Select, Text } from "../../../components/ajonjolib/inputs/ajonjolinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../api";

/**
 * EmployeesCreate component renders a form for creating a new employee.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onSuccess - Callback function to be called on successful form submission.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <EmployeesCreate onSuccess={() => console.log('Success!')} />
 * 
 * @description
 * This component uses a single hook:
 * - `useState` to manage the state of the form data.
 * 
 * The component renders a form with various input fields for creating a new employee, including:
 * - Apellidos (last name)
 * - Nombres (first name)
 * - Doc. Identificación (identification document)
 * - Empresa (company selection)
 * - Grupo de Proyecto (project group selection)
 * - Proyecto (project selection)
 * - Guardia (guard selection)
 * - Turno (shift selection)
 * - Pulsera (bracelet selection)
 */
export default function EmployeesCreate({ onSuccess }) {
    const [form, setForm] = useState({
        device: -1,
    });
    const [companies, setCompanies] = useState([]);
    const [projects, setProjects] = useState([]);
    const [guards, setGuards] = useState([]);
    const [shifts, setShifts] = useState([]);
    const [devices, setDevices] = useState([]);
    const [groupProject, setGroupProject] = useState([]);

    useEffect(() => {
        api.get('subenterprises/').then((response) => {
            if (response?.status !== 200) return;
            setCompanies(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, []);

    useEffect(() => {
        let url = 'devices/?active=true&ordering=id&subenterprise=' + form['subenterprise']
        if (form['project']) {
            url += '&project=' + form['project'];
        }
        if (form['projectGroup']) {
            url += '&project_group=' + form['projectGroup'];
        }
        api.get(url).then((response) => {
            if (response?.status !== 200) {
                setDevices([]);
                return;
            }
            setDevices(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }
        , [form['subenterprise'], form['project'], form['projectGroup']]);

    useEffect(() => {
        api.get('project-groups/?subenterprise=' + form['subenterprise']).then((response) => {
            if (response?.status !== 200) setGroupProject([]);
            setGroupProject(response.data);
        }
        ).catch(error => {
            console.log(error);
        }
        );
    }, [form['subenterprise']]);

    useEffect(() => {
        api.get('projects/?project_group=' + form['projectGroup']).then((response) => {
            if (response?.status !== 200) setProjects([]);
            setProjects(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [form['projectGroup']]);

    useEffect(() => {
        api.get('guards/?project=' + form['project'] + '&project_group=' + form['projectGroup']).then((response) => {
            if (response?.status !== 200) setGuards([]);
            setGuards(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [form['project']]);

    useEffect(() => {
        api.get('turns/?project=' + form['project']).then((response) => {
            if (response?.status !== 200) setShifts([]);
            setShifts(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [form['project'], form['guard']]);

    const submit = () => {
        const copy = { ...form };
        if (copy['device'] === -1) {
            delete copy['device'];
        }
        api.post('employees/', form).then((response) => {
            if (response?.status !== 201) return;
            onSuccess();
        }).catch(error => {
            console.log(error);
        }
        );
    }

    return (
        <>
            <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Apellidos</Label>
                        <Text value={form['vApellidos']} onChange={(val) => {
                            setForm({ ...form, vApellidos: val });
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Nombres</Label>
                        <Text value={form['vNombres']} onChange={(val) => {
                            setForm({ ...form, vNombres: val });
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Doc. Identificación</Label>
                        <Text value={form['vCodEmpleado']} onChange={(val) => {
                            setForm({ ...form, vCodEmpleado: val });
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Empresa</Label>
                        <Select value={form['subenterprise']} onChange={(val) => {
                            if (val[0] === form['subenterprise']) return;
                            setForm({
                                ...form, subenterprise: val[0], projectGroup: undefined,
                                project: undefined, guard: undefined, turn: undefined,
                                device: undefined
                            });
                        }} options={companies?.map((x) => {
                            return {
                                name: x.name ?? '',
                                value: x.id
                            }
                        })} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Grupo de Proyecto</Label>
                        <Select value={form['projectGroup']} onChange={(val) => {
                            if (val[0] === form['projectGroup']) return;
                            setForm({
                                ...form, projectGroup: val[0], project: undefined,
                                guard: undefined, turn: undefined, device: undefined
                            });
                        }} options={groupProject?.map((x) => {
                            return {
                                name: x.name ?? '',
                                value: x.id
                            }
                        })} />
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Proyecto</Label>
                        <Select options={projects?.map((x) => {
                            return {
                                name: x.name ?? '',
                                value: x.id
                            }
                        })} value={form['project']} onChange={(val) => {
                            if (val[0] === form['project']) return;
                            setForm({
                                ...form, project: val[0], guard: undefined,
                                turn: undefined, device: undefined
                            });
                        }} />
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Guardia</Label>
                        <Select value={form['guard']} onChange={(val) => {
                            if (val[0] === form['guard']) return;
                            setForm({ ...form, guard: val[0], turn: undefined });
                        }} options={guards?.map((x) => {
                            return {
                                name: x.name ?? '',
                                value: x.id
                            }
                        })} />
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '100%' }}>
                        <Label>Turno</Label>
                        <Select value={form['turn']} onChange={(val) => {
                            if (val[0] === form['turn']) return;
                            setForm({ ...form, turn: val[0] })
                        }} options={shifts?.map((x) => {
                            return {
                                name: x.isDay ? "Día" : "Noche",
                                value: x.id
                            }
                        })} />
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ width: '100%' }}>
                        <Label>Pulsera</Label>
                        <Select searchable={true} value={form['device']}
                            options={
                                [
                                    {
                                        name: 'Ninguno',
                                        value: -1
                                    },
                                    ...devices?.map((x) => {
                                        return {
                                            name: x.serial ?? '',
                                            value: x.id
                                        }
                                    }),

                                ]
                            } onChange={(val) => {
                                if (val[0] === form['device']) return;
                                // Set the project and project group of the device
                                const device = devices.find((x) => x.id === val[0]);
                                console.log('Device', device);
                                if (!device) {
                                    setForm({ ...form, device: val[0] })
                                    return;
                                }

                                setForm({
                                    ...form,
                                    device: val[0],
                                    projectGroup: device.project_group?.id,
                                    project: device.project?.id,
                                });
                                console.log('Form', form);
                            }} />
                    </div>
                </div>
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'} />
                <div style={{ color: '#5863B4' }}>Guardar</div>
            </div>
        </>
    )
}