import styles from './index.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

/**
 * Header component renders a header with a title and optional subtitle.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the header.
 * @param {string} [props.subtitle] - The subtitle of the header.
 * @param {JSX.Element} props.children - The children to render in the header.
 * 
 * @returns {JSX.Element} The rendered header component.
 * 
 * @example
 * return <Header title={'Title'} subtitle={'Subtitle'}>Children</Header>
 * 
 * @description
 * This component uses the following styles:
 * 1. `container` - The main container div.
 * 2. `title` - The title text.
 * 3. `subtitle` - The subtitle text.
 * 
 * The component renders a header with a title and optional subtitle.
 * The component also renders any children passed to it.
 */
export default function Header({ title, subtitle, children }) {
    return (
        <div className={styles.container}>
            <div className={'d-flex align-items-center'}>
                <div className={styles.title}>{title}</div>
                {subtitle &&
                    <>
                        <FontAwesomeIcon className={'px-2'} icon={faChevronRight} color={'#5863B4'} size={'sm'} />
                        <div className={styles.subtitle}>{subtitle}</div>
                    </>
                }
            </div>
            {children}
        </div>
    )
}