import React from "react";

export default function Label({ children, style, className, required }) {
    return (
        <div className={className} style={{
            color: "#5863B4", fontFamily: 'Lexend Variable, sans-serif',
            fontWeight: "400",
            paddingBottom: "0.2rem", textAlign: 'left',
            ...style
        }}>
            {children}
            {required && <span style={{ color: 'red' }}> *</span>}
        </div>
    )
}
