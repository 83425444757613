import React, { useEffect, useState } from "react";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import { Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import Switch from "../../../../components/ajonjolib/switch";
import styles from "./modals.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";

export default function HubsModal({ onSuccess }) {
    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState({});
    const [enterprise, setEnterprise] = useState([]);
    const [guard, setGuard] = useState([]);
    const [groupProject, setGroupProject] = useState([]);
    const [project, setProject] = useState([]);

    useEffect(() => {
        api.get('subenterprises/').then((response) => {
            if (response?.status !== 200) {
                setEnterprise([]);
                return;
            }
            setEnterprise(response.data);
        }
        );
    }, [isOpen]);

    useEffect(() => {
        api.get('project-groups/?subenterprise=' + form['subenterprise']).then((response) => {
            if (response?.status !== 200) {
                setGroupProject([]);
                return;
            }
            setGroupProject(response.data);
        }
        );
    }, [form['subenterprise']]);

    useEffect(() => {
        api.get('projects/?project_group=' + form['project_group']).then((response) => {
            if (response?.status !== 200) {
                setProject([]);
                return;
            }
            setProject(response.data);
        })
    }, [form['project_group']]);

    useEffect(() => {
        api.get('guards/?project=' + form['project'] + '&project_group=' + form['project_group']).then((response) => {
            if (response?.status !== 200) {
                setGuard([]);
                return;
            }
            setGuard(response.data);
        })
    }, [form['project']]);

    useEffect(() => {
        eventBus.subscribe('hubs_modal_open', (rec_data) => {
            setIsOpen(true);
            const copy = {
                ...rec_data,
                subenterprise: rec_data.subenterprise?.id,
                project_group: rec_data.project_group?.id,
                project: rec_data.project?.id,
                guard: rec_data.guard?.id
            }
            setForm(copy);
        });
        eventBus.subscribe('hubs_modal_close', (data) => { setIsOpen(false) });
    }, []);

    const submit = () => {
        api.put(`hubs/${form?.id}/`, form).then((response) => {
            if (response?.status === 200) {
                setIsOpen(false);
                onSuccess();
                return;
            }
        });
    }

    return (
        <div>
            {isOpen &&
                <Modal style={{ width: '50%' }} title={'Master de Pulseras'} desc={'Editar'} close={() => setIsOpen(false)}>
                    <Label>Estado</Label>
                    <Switch value={form['status']} onChange={(e) => setForm({
                        ...form, status: e
                    })} />
                    <div className={'d-flex align-items-center w-100 justify-content-between mt-3'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Serial</Label>
                            <Text value={form['hub_code']} onChange={(val) => {
                                setForm({ ...form, hub_code: val })
                            }} />
                        </div>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Nombre</Label>
                            <Text value={form['name']} onChange={(val) => {
                                setForm({ ...form, name: val })
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Empresa</Label>
                            <Select value={form['subenterprise']} options={enterprise?.map((x) => {
                                return {
                                    name: x.name,
                                    value: x.id
                                }
                            })} onChange={(val) => {
                                if (val[0] === form['subenterprise']) return;
                                setForm({ ...form, subenterprise: val[0], project_group: undefined, project: undefined, guard: undefined });
                            }} />
                        </div>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Grupo de Proyecto</Label>
                            <Select value={form['project_group']} options={groupProject?.map((x) => {
                                return {
                                    name: x.name,
                                    value: x.id
                                }
                            })} onChange={(val) => {
                                if (val[0] === form['project_group']) return;
                                setForm({ ...form, project_group: val[0], project: undefined, guard: undefined });
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Proyecto</Label>
                            <Select value={form['project']} onChange={(val) => {
                                if (val[0] === form['project']) return;
                                setForm({
                                    ...form,
                                    project: val[0],
                                    guard: undefined
                                });
                            }} options={project?.map((x) => {
                                return {
                                    name: x.name,
                                    value: x.id
                                }
                            })} />
                        </div>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Guardia</Label>
                            <Select value={form['guard']} onChange={(val) => {
                                if (val[0] === form['guard']) return;
                                setForm({ ...form, guard: val[0] });
                            }} options={guard?.map((x) => {
                                return {
                                    name: x.name,
                                    value: x.id
                                }
                            })} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                        <div className={styles.button} onClick={submit}>
                            <FontAwesomeIcon icon={faSave} color={'#52A035'} />
                            <div className={'ps-2'}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}