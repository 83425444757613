import React, { useEffect, useState } from "react";
import Table from "../../../../components/ajonjolib/table/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";

/**
 * Group component renders a table with group details.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.company - The company object.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <Group company={companyData} />
 * 
 * @description
 * This component uses several hooks:
 * - `useState` to manage the state of the table rows.
 * - `useEffect` to fetch data from the API when the component mounts.
 * 
 * The component renders a table with group details, including:
 * - Estado (active switch)
 * - Grupo de Proyecto (project group)
 * - Servicio (services)
 * - Mostrar TTS en Reposo (show TTS in rest)
 * - Acciones (edit and configure actions)
 */
export default function Group({ company }) {
    const [rows, setRows] = useState([]);
    const cols = [
        {
            title: 'Estado', key: 'active', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <div className={'me-2'} style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: data ? '#00C853' : '#EAEAEC' }} />
                        <div>{data}</div>
                    </div>
                )
            }
        },
        { title: 'Grupo de Proyecto', key: 'name' },
        {
            title: 'Servicio', key: 'services', code: (row, data) => {
                return (
                    <div>{data.join(', ')}</div>
                )
            }
        },
        {
            title: 'Mostrar TTS en Reposo', key: 'show_tts', code: (row, data) => {
                return (
                    <div>{data ? 'Sí' : 'No'}</div>
                )
            }
        },
        {
            title: 'Acciones', key: 'actions', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faEdit} color={'#5863B4'} size={'lg'} role={'button'} onClick={() => {
                            eventBus.emit('group_modal_open', row);
                        }} />
                        <div className={'mx-2'} />
                        <FontAwesomeIcon icon={faGear} color={'#5863B4'} size={'lg'} role={'button'} onClick={() => {
                            eventBus.emit('group_config_modal_open', row);
                        }} />
                    </div>
                )
            }
        }
    ];

    useEffect(() => {
        api.get(`project-groups/?subenterprise=${company?.id}`).then((response) => {
            if (response?.status !== 200) return;
            setRows(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, []);

    return (
        <div>
            <Table model={'project-groups'} cols={cols} rows={rows} />
        </div>
    )
}