import React, { useEffect, useState } from "react";
import { Label, Select, Text } from "../../../components/ajonjolib/inputs/ajonjolinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../api";

/**
 * BandsCreate component renders a form for creating a new master bracelet.
 *  
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onSuccess - Callback function to be called on successful form submission.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <BandsCreate onSuccess={() => console.log('Success!')} />
 * 
 * @description
 * This component uses several hooks:
 * - `useState` to manage the state of the form data, enterprises, guards, group projects, and projects.
 * - `useEffect` to fetch data from the API when the component mounts and when the project group changes.
 * 
 * The component renders a form with various input fields for creating a new master bracelet, including:
 * - Serial (serial number)
 * - Nombre (name)
 * - Empresa (enterprise selection)
 * - Grupo de Proyecto (project group selection)
 * - Proyecto (project selection)
 * - Guardia (guard selection)
 */
export default function BandsCreate({ onSuccess }) {
    const [form, setForm] = useState({})
    const [enterprise, setEnterprise] = useState([]);
    const [guard, setGuard] = useState([]);
    const [groupProject, setGroupProject] = useState([]);
    const [project, setProject] = useState([]);

    useEffect(() => {
        api.get('subenterprises/').then((response) => {
            if (response?.status !== 200) setEnterprise([]);
            setEnterprise(response.data);
        }
        ).catch(error => {
            console.log(error);
        }
        );
    }, []);

    useEffect(() => {
        api.get('project-groups/?subenterprise=' + form['subenterprise']).then((response) => {
            if (response?.status !== 200) setGroupProject([]);
            setGroupProject(response.data);
        }
        ).catch(error => {
            console.log(error);
        }
        );
    }, [form['subenterprise']]);

    useEffect(() => {
        setForm({
            ...form,
            project: undefined
        });
        api.get('projects/?project_group=' + form['project_group']).then((response) => {
            if (response?.status !== 200) setProject([]);
            setProject(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [form['project_group']]);

    useEffect(() => {
        setForm({
            ...form,
            guard: undefined
        });
        api.get('guards/?project=' + form['project'] + '&project_group=' + form['project_group']).then((response) => {
            if (response?.status !== 200) setGuard([]);
            setGuard(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [form['project']]);

    const submit = () => {
        api.post('hubs/', form).then((response) => {
            if (response?.status !== 201) return;
            onSuccess();

        }).catch(error => {
            console.log(error);
        }
        );
    }

    return (
        <>
            <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Serial</Label>
                        <Text value={form['hub_code']} onChange={(val) => {
                            setForm({ ...form, hub_code: val })
                        }} />
                    </div>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Nombre</Label>
                        <Text value={form['name']} onChange={(val) => {
                            setForm({ ...form, name: val })
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Empresa</Label>
                        <Select value={form['subenterprise']} options={enterprise?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} onChange={(val) => {
                            if (val[0] === form['subenterprise']) return;
                            setForm({ ...form, subenterprise: val[0], project_group: undefined });
                        }} />
                    </div>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Grupo de Proyecto</Label>
                        <Select value={form['project_group']} options={groupProject?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} onChange={(val) => {
                            if (val[0] === form['project_group']) return;
                            setForm({ ...form, project_group: val[0], project: undefined });
                        }} />
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Proyecto</Label>
                        <Select value={form['project']} onChange={(val) => {
                            if (val[0] === form['project']) return;
                            setForm({
                                ...form,
                                project: val[0],
                                guard: undefined
                            });
                        }} options={project?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} />
                    </div>
                    <div style={{ flexBasis: '45%' }}>
                        <Label>Guardia</Label>
                        <Select value={form['guard']} onChange={(val) => {
                            setForm({ ...form, guard: val[0] });
                        }} options={guard?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} />
                    </div>
                </div>
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'} />
                <div style={{ color: '#5863B4' }}>Guardar</div>
            </div>
        </>
    )
}