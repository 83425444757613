// websocketContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { wsURL } from './api';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const notificationsUrl = wsURL + 'notifications/';
        const ws = new WebSocket(notificationsUrl);
        setSocket(ws);

        return () => {
            ws.close();
        };
    }, []);

    return (
        <WebSocketContext.Provider value={socket}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
