import React, { useEffect, useState } from "react";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import styles from './modals.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import api from "../../../../api";

function DownloadButton({ children, onTap }) {
    return (
        <div className={styles.downloadButton} onClick={onTap}>
            <FontAwesomeIcon icon={faFileExcel} color={'#FFF'} className={'me-3'} />
            {children}
        </div>
    )
}

export default function ExportModal({ title }) {
    const [isOpen, setIsOpen] = useState(false);
    const [url, setUrl] = useState('');
    const [query, setQuery] = useState('');

    useEffect(() => {
        eventBus.subscribe('export_modal_open', (rec_data) => {
            setUrl(rec_data.url);
            setIsOpen(true);
            setQuery(rec_data.query);
            console.log('QUERY', query);
            console.log('REC DATA', rec_data);
        });
        eventBus.subscribe('export_modal_close', (data) => { setIsOpen(false) });
    }, []);

    /* const downloadTemplate = () => {
 
     }
 
     const downloadData = () => {
 
     }*/

    return (
        <div>
            {isOpen &&
                <Modal title={title} desc={'Exportar'} close={() => setIsOpen(false)}>
                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%', textAlign: 'left' }}>
                            <div style={{ fontSize: '15px', color: '#494B50', lineHeight: '16px' }}>Descargar Plantilla</div>
                            <div style={{ fontSize: '12px' }}>La plantilla a descargar se encuentra vacía</div>
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <DownloadButton onTap={
                                () => {
                                    // Download the file from the url
                                    const emptyUrl = url + '?empty=true';
                                    const emptyUrlWithQuery = emptyUrl + '&' + query;
                                    api.get(emptyUrlWithQuery, {
                                        responseType: 'blob'
                                    }).then((response) => {
                                        if (response?.status !== 200) return;
                                        const url = window.URL.createObjectURL(new Blob([response.data]));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', 'template.xlsx');
                                        document.body.appendChild(link);
                                        link.click();
                                    })
                                }
                            }>
                                <div>Descargar Plantilla</div>
                            </DownloadButton>
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%', textAlign: 'left' }}>
                            <div style={{ fontSize: '15px', color: '#494B50', lineHeight: '16px' }}>Descargar Registro</div>
                            <div style={{ fontSize: '12px' }}>El registro a descargar toma a los usuarios y datos registrados en el Master de Empleados</div>
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <DownloadButton onTap={() => {
                                // Download the file from the url
                                const urlWithQuery = url + '?' + query;
                                api.get(urlWithQuery, {
                                    responseType: 'blob'
                                }).then((response) => {
                                    if (response?.status !== 200) return;
                                    const url = window.URL.createObjectURL(new Blob([response.data]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', 'data.xlsx');
                                    document.body.appendChild(link);
                                    link.click();
                                    link.parentNode.removeChild(link);
                                })
                            }}>
                                <div>Descargar Registro</div>
                            </DownloadButton>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}