import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Dashboard from "./screens/dashboard";
import Results from "./screens/results";
import Detailed from "./screens/detailed";
import Login from "./screens/login";
import Company from "./screens/configuration/company";
import Access from "./screens/configuration/access";
import Shifts from "./screens/configuration/shifts";
import Employees from "./screens/configuration/employees";
import Bands from "./screens/configuration/bands";
import CompanyDetail from "./screens/configuration/company/detail";
import ResetPassword from "./screens/reset_password";
import Permissions from "./screens/configuration/permissions";
import Hubs from "./screens/configuration/hubs";


function LoginRedirect() {
    useEffect(() => {
        window.location.replace('/login');
    }, []);
    return (<div></div>);
}


export default function RouteContainer() {
    let authenticated = localStorage.getItem('access_token') !== null;
    const jsonPermissions = localStorage.getItem('permissions');
    console.log('jsonPermissions', jsonPermissions);
    let allowedNames = [];
    if (jsonPermissions !== null && jsonPermissions !== "undefined") {
        const permissions = JSON.parse(jsonPermissions);
        allowedNames = permissions?.filter((x) => x.access_level !== "none").map((x) => x.permission.name);
    }
    console.log('allowedNames', allowedNames);

    return (
        <Routes>
            <Route path={'/login'} element={<Login />} />
            <Route path={'/password_reset'} element={<ResetPassword />} />
            {authenticated &&
                <>
                    <Route path={'/'} element={allowedNames.includes('Dashboard') ? <Dashboard /> : null} />
                    <Route path={'/results'} element={allowedNames.includes('Resultado Turno') ? <Results /> : null} />
                    <Route path={'/detailed'} element={allowedNames.includes('Detalle Usuario') ? <Detailed /> : null} />
                    <Route path={'/config/company'} element={allowedNames.includes('Empresa') ? <Company /> : null} />
                    <Route path={'/config/company/detail/:id'} element={allowedNames.includes('Empresa') ? <CompanyDetail /> : null} />
                    <Route path={'/config/access'} element={allowedNames.includes('Accesos') ? <Access /> : null} />
                    <Route path={'/config/permissions'} element={allowedNames.includes('Perfiles') ? <Permissions /> : null} />
                    <Route path={'/config/shifts'} element={allowedNames.includes('Turnos') ? <Shifts /> : null} />
                    <Route path={'/config/employees'} element={allowedNames.includes('Master de Empleados') ? <Employees /> : null} />
                    <Route path={'/config/bands'} element={allowedNames.includes('Master de Pulseras') ? <Bands /> : null} />
                    <Route path={'/config/hubs'} element={allowedNames.includes('Master de Hubs') ? <Hubs /> : null} />
                </>
            }
            <Route path="*" element={<LoginRedirect />} />
        </Routes>
    )
}