import React, { useEffect, useState } from "react";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import { DateRangePicker, Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import Switch from "../../../../components/ajonjolib/switch";
import styles from "./modals.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";

function SexSelect({ form, setForm }) {
    const options = [
        { name: "Femenino", value: 'F' }, { name: "Masculino", value: 'M' }
    ];

    return (
        <div className={styles.dateContainer}>
            {options.map((gender, index) => (
                <div key={index} className={`${styles.dateItem} ${form['gender'] === gender.value ? styles.genderSelected : ''}`} onClick={() => {
                    setForm({ ...form, gender: gender.value });
                }}>
                    <div>{gender.name}</div>
                </div>
            ))}
        </div>
    )
}

export default function AccessModal({ onSuccess }) {
    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState({});
    const [companies, setCompanies] = useState([]);
    const [projectGroups, setProjectGroups] = useState([]);
    const [projects, setProjects] = useState([]);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        api.get('subenterprises/').then((response) => {
            setCompanies(response.data);
        });
    }, []);
    useEffect(() => {
        api.get('roles/').then((response) => {
            setRoles(response.data);
        });
    }, []);

    useEffect(() => {
        api.get('project-groups/?subenterprise=' + form['subenterprise']).then((response) => {
            if (response?.status !== 200) {
                setProjectGroups([]);
                return;
            }
            setProjectGroups(response.data);
        });
    }, [form['subenterprise']]);

    useEffect(() => {
        api.get('projects/?project_group=' + form['project_group']).then((response) => {
            if (response?.status !== 200) {
                setProjects([]);
                return;
            }
            setProjects(response.data);
        });
    }, [form['project_group']]);

    useEffect(() => {
        eventBus.subscribe('access_modal_open', (rec_data) => {
            setIsOpen(true);
            const copy = {
                ...rec_data,
                subenterprise: rec_data.subenterprise?.id,
                project_group: rec_data.project_group?.id,
                project: rec_data.project?.id,
                role: rec_data.role?.id,
            };
            // Replace nulls in copy with undefined
            Object.keys(copy).forEach(key => {
                if (copy[key] === null || copy[key] === undefined) {
                    copy[key] = undefined;
                    if (key === 'subenterprise' || key === 'project_group' || key === 'project') {
                        copy[key] = -1;
                    }
                }
            });
            setForm(copy);
        });
        eventBus.subscribe('access_modal_close', (data) => { setIsOpen(false) });
    }, []);

    const submit = () => {
        const copy = { ...form };
        // Replace undefined with nulls
        Object.keys(copy).forEach(key => {
            if (copy[key] === undefined) {
                copy[key] = null;
            }
            if (copy[key] === -1) {
                copy[key] = null;
            }
        }
        );
        api.patch(`users/${form.id}/`, copy).then((response) => {
            if (response?.status === 200) {
                setIsOpen(false);
                onSuccess();
                return;
            }
        })
    }

    return (
        <div>
            {isOpen &&
                <Modal title={'Accesos'} desc={'Editar'} close={() => setIsOpen(false)}>
                    <Label>Estado</Label>
                    <Switch value={form['is_active']} onChange={(e) => setForm({
                        ...form, is_active: e
                    })} />

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '100%' }}>
                            <Label>Empresa</Label>
                            <Select value={form['subenterprise']} options={
                                [
                                    ...companies?.map((x) => {
                                        return {
                                            name: x.name,
                                            value: x.id
                                        }
                                    }),
                                    { name: 'Todas', value: -1 }
                                ]
                            } onChange={(val) => {
                                if (val[0] === form['subenterprise']) return;
                                setForm({ ...form, subenterprise: val[0], project_group: -1, project: -1 });
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Grupo de Proyecto</Label>
                            <Select value={form['project_group']} options={
                                [...projectGroups?.map((x) => {
                                    return {
                                        name: x.name,
                                        value: x.id
                                    }
                                }),
                                { name: 'Todos', value: -1 }
                                ]
                            } onChange={(val) => {
                                if (val[0] === form['project_group']) return;
                                setForm({ ...form, project_group: val[0], project: -1 });

                            }} />
                        </div>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Proyecto</Label>
                            <Select value={form['project']} options={
                                [...projects?.map((x) => {
                                    return {
                                        name: x.name ?? '-',
                                        value: x.id
                                    }
                                }),
                                { name: 'Todos', value: -1 }
                                ]
                            } onChange={(val) => {
                                setForm({ ...form, project: val[0] })
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Apellidos</Label>
                            <Text value={form['last_name']} onChange={(val) => {
                                setForm({ ...form, last_name: val });
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Nombres</Label>
                            <Text value={form['first_name']} onChange={(val) => {
                                setForm({ ...form, first_name: val });
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>N° Documento</Label>
                            <Text value={form['documentId']} onChange={(val) => {
                                setForm({ ...form, documentId: val });
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>E-Mail</Label>
                            <Text value={form['email']} onChange={(val) => {
                                setForm({ ...form, email: val });
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Usuario Login</Label>
                            <Text value={form['username']} onChange={(val) => {
                                setForm({ ...form, username: val })
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Rol</Label>
                            <Select value={form['role']} options={roles?.map((x) => {
                                return {
                                    name: x.name,
                                    value: x.id
                                }
                            })} onChange={(val) => {
                                setForm({ ...form, role: val[0] })
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Sexo</Label>
                            <SexSelect form={form} setForm={setForm} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Fecha de Alta</Label>
                            <div className={styles.dateFilterOnly}>
                                <input type={'date'} value={form['dischargeDate']} onChange={(event) => {
                                    setForm({ ...form, dischargeDate: event.target.value });
                                }} />
                            </div>
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                        <div className={styles.button} onClick={submit}>
                            <FontAwesomeIcon icon={faSave} color={'#52A035'} />
                            <div className={'ps-2'}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}