import React, { useEffect, useState } from "react";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import styles from "./modals.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import Switch from "../../../../components/ajonjolib/switch";
import api from "../../../../api";

/**
 * EmployeesModal component handles the display and submission of employee data in a modal.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onSuccess - Callback function to be called on successful submission.
 * 
 * @returns {JSX.Element} The rendered EmployeesModal component.
 * 
 * @example
 * <EmployeesModal onSuccess={handleSuccess} />
 * 
 * @description
 * This component fetches and manages data related to companies, projects, guards, shifts, and devices.
 * It listens to events to open and close the modal, and handles form submission to update employee data.
 * 
 * @typedef {Object} Form
 * @property {number} [subenterprise] - The ID of the subenterprise.
 * @property {number} [project] - The ID of the project.
 * @property {number} [projectGroup] - The ID of the project group.
 * @property {number} [device] - The ID of the device.
 * @property {number} [guard] - The ID of the guard.
 * @property {number} [turn] - The ID of the turn.
 * @property {string} [vApellidos] - The last name of the employee.
 * @property {string} [vNombres] - The first name of the employee.
 * @property {string} [vCodEmpleado] - The employee ID.
 * @property {boolean} [active] - The active status of the employee.
 * 
 * @typedef {Object} Company
 * @property {number} id - The ID of the company.
 * @property {string} name - The name of the company.
 * 
 * @typedef {Object} Project
 * @property {number} id - The ID of the project.
 * @property {string} name - The name of the project.
 * 
 * @typedef {Object} Guard
 * @property {number} id - The ID of the guard.
 * @property {string} name - The name of the guard.
 * 
 * @typedef {Object} Shift
 * @property {number} id - The ID of the shift.
 * @property {boolean} isDay - Whether the shift is a day shift.
 * 
 * @typedef {Object} Device
 * @property {number} id - The ID of the device.
 * @property {string} serial - The serial number of the device.
 * @property {Object} [project_group] - The project group associated with the device.
 * @property {Object} [project] - The project associated with the device.
 */
export default function EmployeesModal({ onSuccess }) {
    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState({});
    const [companies, setCompanies] = useState([]);
    const [projects, setProjects] = useState([]);
    const [guards, setGuards] = useState([]);
    const [shifts, setShifts] = useState([]);
    const [devices, setDevices] = useState([]);
    const [groupProject, setGroupProject] = useState([]);

    useEffect(() => {
        api.get('subenterprises/').then((response) => {
            if (response?.status !== 200) {
                setCompanies([]);
                return;
            }
            setCompanies(response.data);
        }).catch(error => {
            console.log(error);
        }
        );


    }, [isOpen]);

    useEffect(() => {
        let url = 'devices/?active=true&ordering=id&subenterprise=' + form['subenterprise']
        if (form['project']) {
            url += '&project=' + form['project'];
        }
        if (form['projectGroup']) {
            url += '&project_group=' + form['projectGroup'];
        }
        api.get(url).then((response) => {
            if (response?.status !== 200) {
                setDevices([]);
                return;
            }
            setDevices(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }
        , [form['subenterprise'], form['project'], form['projectGroup']]);

    useEffect(() => {
        api.get('project-groups/?subenterprise=' + form['subenterprise']).then((response) => {
            if (response?.status !== 200) setGroupProject([]);
            setGroupProject(response.data);
        }
        ).catch(error => {
            console.log(error);
        }
        );
    }, [form['subenterprise']]);

    useEffect(() => {
        api.get('projects/?project_group=' + form['projectGroup']).then((response) => {
            if (response?.status !== 200) setProjects([]);
            setProjects(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [form['projectGroup']]);

    useEffect(() => {
        api.get('guards/?project=' + form['project'] + '&project_group=' + form['projectGroup']).then((response) => {
            if (response?.status !== 200) setGuards([]);
            setGuards(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [form['project']]);

    useEffect(() => {
        api.get('turns/?project=' + form['project']).then((response) => {
            if (response?.status !== 200) setShifts([]);
            setShifts(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, [form['project']]);

    React.useEffect(() => {
        eventBus.subscribe('employees_modal_open', (rec_data) => {
            setIsOpen(true);
            const copy = {
                ...rec_data,
                subenterprise: rec_data.subenterprise?.id,
                project: rec_data.project?.id,
                device: rec_data.device?.id ?? -1,
                guard: rec_data.guard?.id,
                turn: rec_data.turn?.id,
                projectGroup: rec_data.projectGroup?.id,
            };
            setForm(copy);
        });
        eventBus.subscribe('employees_modal_close', (data) => { setIsOpen(false) });
    }, []);

    const submit = () => {
        const formToSend = {
            ...form,
        }
        if (formToSend['device'] === undefined || formToSend['device'] === -1) {
            formToSend['device'] = null;
        }
        api.put(`employees/${form?.id}/`, formToSend).then((response) => {
            if (response?.status === 200) {
                setIsOpen(false);
                onSuccess();
                return;
            }
        }).catch(error => {
            console.log(error);
        }
        );
    }

    return (
        <div>
            {isOpen &&
                <Modal title={'Master de Empleados'} desc={'Editar'} close={() => setIsOpen(false)}>
                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '25%' }}>
                            <Label>Estado</Label>
                            <Switch value={form['active']} onChange={(e) => setForm({
                                ...form, active: e
                            })} />
                        </div>

                        <div style={{ flexBasis: '65%' }}>
                            <Label>Empresa</Label>
                            <Select value={form['subenterprise']} onChange={(val) => {
                                if (val[0] === form['subenterprise']) return;
                                setForm({
                                    ...form, subenterprise: val[0], projectGroup: undefined,
                                    project: undefined, guard: undefined, turn: undefined,
                                    device: undefined
                                });
                            }} options={companies?.map((x) => {
                                return {
                                    name: x.name ?? '',
                                    value: x.id
                                }
                            })} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Grupo de Proyecto</Label>
                            <Select value={form['projectGroup']} onChange={(val) => {
                                if (val[0] === form['projectGroup']) return;
                                setForm({
                                    ...form, projectGroup: val[0], project: undefined
                                    , guard: undefined, turn: undefined, device: undefined
                                });
                            }} options={groupProject?.map((x) => {
                                return {
                                    name: x.name ?? '',
                                    value: x.id
                                }
                            })} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Proyecto</Label>
                            <Select options={projects?.map((x) => {
                                return {
                                    name: x.name ?? '',
                                    value: x.id
                                }
                            })} value={form['project']} onChange={(val) => {
                                if (val[0] === form['project']) return;
                                setForm({
                                    ...form, project: val[0], guard: undefined,
                                    turn: undefined, device: undefined
                                });
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Apellidos</Label>
                            <Text value={form['vApellidos']} onChange={(val) => {
                                setForm({ ...form, vApellidos: val });
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Nombres</Label>
                            <Text value={form['vNombres']} onChange={(val) => {
                                setForm({ ...form, vNombres: val });
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Doc. Identificación</Label>
                            <Text value={form['vCodEmpleado']} onChange={(val) => {
                                setForm({ ...form, vCodEmpleado: val });
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Turno</Label>
                            <Select value={form['turn']} onChange={(val) => {
                                if (val[0] === form['turn']) return;
                                setForm({ ...form, turn: val[0] })
                            }} options={shifts?.map((x) => {
                                return {
                                    name: x.isDay ? "Día" : "Noche",
                                    value: x.id
                                }
                            })} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ width: '45%' }}>
                            <Label>Pulsera</Label>
                            <Select searchable={true} value={form['device']} options={
                                [
                                    {
                                        name: 'Ninguno',
                                        value: -1
                                    },
                                    ...devices?.map((x) => {
                                        return {
                                            name: x.serial ?? '',
                                            value: x.id
                                        }
                                    }),

                                ]
                            } onChange={(val) => {
                                if (val[0] === form['device']) return;
                                const device = devices.find((x) => x.id === val[0]);
                                if (!device) {
                                    setForm({ ...form, device: val[0] });
                                    return;
                                }
                                setForm({
                                    ...form, device: val[0],
                                    projectGroup: device.project_group?.id,
                                    project: device.project?.id,
                                })
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Guardia</Label>
                            <Select value={form['guard']} onChange={(val) => {
                                if (val[0] === form['guard']) return;
                                setForm({ ...form, guard: val[0] });
                            }} options={guards?.map((x) => {
                                return {
                                    name: x.name ?? '',
                                    value: x.id
                                }
                            })} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                        <div className={styles.button} onClick={submit}>
                            <FontAwesomeIcon icon={faSave} color={'#52A035'} />
                            <div className={'ps-2'}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}