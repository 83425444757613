import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import '@fontsource-variable/lexend';
import 'rsuite/dist/rsuite.min.css';

/**
 * The root element of the application.
 * 
 * @type {JSX.Element}
 * 
 * @description
 * The root element of the application is the `App` component.
 * The `App` component is rendered using `ReactDOM.createRoot`.
 */
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
