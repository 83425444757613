import React, { useEffect, useState } from "react";
import Table from "../../../../components/ajonjolib/table/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";

/**
 * Project component renders a table with project details.
 *  
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.company - The company object.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <Project company={companyData} />
 * 
 * @description
 * This component uses several hooks:
 * - `useState` to manage the state of the table rows.
 * - `useEffect` to fetch data from the API when the component mounts.
 * 
 * The component renders a table with project details, including:
 * - Estado (active switch)
 * - Grupo de Proyecto (project group)
 * - Zona Horaria (timezone)
 * - Proyecto (project)
 * - Acciones (edit action)
 */
export default function Project({ company }) {
    const [rows, setRows] = useState([]);
    const cols = [
        {
            title: 'Estado', key: 'active', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <div className={'me-2'} style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: data ? '#00C853' : '#EAEAEC' }} />
                        <div>{data}</div>
                    </div>
                )
            }
        },
        { title: 'Grupo de Proyecto', key: 'project_group.name' },
        { title: 'Zona Horaria', key: 'timezone' },
        { title: 'Proyecto', key: 'name' },
        {
            title: 'Acciones', key: 'actions', code: (row) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faEdit} color={'#5863B4'} size={'lg'} role={'button'} onClick={() => {
                            eventBus.emit('project_modal_open', row);
                        }} />
                    </div>
                )
            }
        }
    ];

    useEffect(() => {
        api.get(`projects/?subenterprise=${company?.id}`).then((res) => {
            if (res?.status !== 200) return;
            setRows(res.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, []);

    return (
        <div>
            <Table model={'projects'} cols={cols} rows={rows} />
        </div>
    )
}