import React, { useState } from "react";
import { Button, Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";
import { toast } from "react-toastify";

/**
 * GroupCreate component for creating a project group.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.company - The company object containing company details.
 * @param {Function} props.onCreate - Callback function to be called after successful creation.
 * @returns {JSX.Element} The rendered component.
 */
export default function GroupCreate({ company, onCreate }) {
    const [form, setForm] = useState({ subenterprise: company?.id, show_tts: false });

    const submit = () => {
        api.post('project-groups/', form).then((response) => {
            if (response.status === 201) {
                toast.success('Grupo de Proyecto creado correctamente');
                onCreate();
            }
        });
    }
    return (
        <>
            <div>
                <Label>Grupo de Proyecto</Label>
                <Text value={form['name']} onChange={(val) => {
                    setForm({ ...form, name: val })
                }} />
                <div className={'mb-4'} />
                <Label>País</Label>
                <Text value={form['country']} onChange={(val) => {
                    setForm({ ...form, country: val })
                }} />
                <div className={'mb-4'} />
                <Label>Tipo de Servicio</Label>
                <Text value={form['services']} onChange={(val) => {
                    setForm({ ...form, services: [val] })
                }} />
                <div className={'mb-4'} />
                <Button style={{
                    padding: '0 20px', fontSize: '13px', height: '30px', textAlign: 'center', justifyContent: 'center',
                    backgroundColor: form['show_tts'] ? '#5863B4' : '#FFF',
                    color: form['show_tts'] ? '#FFF' : '#5863B4'
                }} name={'Mostrar TTS en Reposo'} onSubmit={() => {
                    setForm({ ...form, show_tts: !form['show_tts'] })
                }} />
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'} />
                <div style={{ color: '#5863B4' }}>Guardar</div>
            </div>
        </>
    )
}