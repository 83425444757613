import React, { useEffect, useState } from "react";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import { DateRangePicker, Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import Switch from "../../../../components/ajonjolib/switch";
import styles from "./modals.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";

/**
 * PermissionsModal component renders a modal for editing role permissions.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onSuccess - Callback function to be called on successful form submission.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <PermissionsModal onSuccess={() => console.log('Success!')} />
 * 
 * @description
 * This component uses several hooks:
 * - `useState` to manage the state of the modal, form data, and permissions.
 * - `useEffect` to fetch data from the API when the component mounts.
 * - `useEffect` to subscribe to events for opening and closing the modal.
 * 
 * The component renders a modal with various input fields for editing role permissions, including:
 * - Estado (active switch)
 * - Nombre (role name)
 * - Permisos (permissions)
 */
export default function PermissionsModal({ onSuccess }) {
    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState({});
    const [permissions, setPermissions] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        api.get('permissions/').then((response) => {
            setPermissions(response.data);
        }).catch(error => {
            console.log(error);
        }
        );
    }, []);

    const loadData = (id) => {
        api.get('role-permissions/?role=' + id).then((response) => {

            console.log('RESPONSE', response.data);
            let permissions = {};
            response.data.forEach((item) => {
                permissions[item.permission.id] = item.access_level;
            });
            const updated = {
                ...form,
                ...permissions
            }
            setForm(updated);
            console.log('FORM UPDATED', form);

        }).catch(error => {
            console.log(error);
        }
        );
    }

    useEffect(() => {
        if (form.id && !isDataLoaded) {
            console.log('LOADED DATA', form);
            loadData(form.id); // Aquí llamas a loadData una vez que form se ha actualizado
            setIsDataLoaded(true);
        }
    }, [form, isDataLoaded]);

    useEffect(() => {
        eventBus.subscribe('permissions_modal_open', (rec_data) => {
            setIsOpen(true);
            const copy = {
                name: rec_data.name,
                id: rec_data.id,
                status: rec_data.status,
            }
            console.log('COPY', copy);
            setForm(copy);
            setIsDataLoaded(false);
        });
        eventBus.subscribe('permissions_modal_close', (data) => { setIsOpen(false) });
    }, []);

    const submit = () => {
        api.put(`roles/${form.id}/`, form).then((response) => {
            if (response?.status === 200) {
                setIsOpen(false);
                onSuccess();
                return;
            }
        }).catch(error => {
            console.log(error);
        }
        );
    }

    return (
        <div>
            {isOpen &&
                <Modal title={'Perfiles'} desc={'Editar'} close={() => setIsOpen(false)}>
                    <Label>Estado</Label>
                    <Switch value={form['status']} onChange={(e) => setForm({
                        ...form, status: e
                    })} />

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '100%' }}>
                            <Label>Nombre</Label>
                            <Text value={form['name']} onChange={(val) => {
                                setForm({ ...form, name: val });
                            }} />
                        </div>
                    </div>
                    {/* Add a space */}
                    <div className={'mt-4'} />

                    <div className={'d-flex flex-wrap'} >
                        {permissions.map((permission, index) => (
                            <div key={index}
                                className="d-flex justify-content-between align-items-center mb-4"
                                style={{
                                    flexBasis: '45%',
                                    marginRight: (index % 2 === 0) ? '20px' : '0',
                                }}>
                                <div style={{ width: '100%' }}>
                                    <Label>{permission.name}</Label>
                                    <Select value={form[permission.id] ?? "none"} options={[
                                        { name: "Ninguno", value: "none" },
                                        { name: "Lectura", value: "read" },
                                        { name: "Edición", value: "edit" },
                                    ]} onChange={(val) => {
                                        setForm({ ...form, [permission.id]: val[0] });
                                    }} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                        <div className={styles.button} onClick={submit}>
                            <FontAwesomeIcon icon={faSave} color={'#52A035'} />
                            <div className={'ps-2'}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}