import React, { useContext, useEffect, useState } from "react";
import styles from './sidebar.module.css';
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import MainContext from "../../context";

/**
 * Logout component that renders a logout button.
 * 
 * @component
 * @returns {JSX.Element} The rendered logout component.
 */
function Logout() {

    const logout = () => {
        localStorage.clear();
        window.location.replace('/login');
    }

    return (
        <div className={styles.logoutContainer} onClick={() => logout()}>
            <div className={styles.logout}>
                <div className={styles.logoutTip} />
                <div className={'d-flex align-items-center'} style={{ zIndex: 100 }}>
                    <FontAwesomeIcon className={'pe-2'} icon={faArrowRightFromBracket} />
                    <div style={{ fontSize: '14px', letterSpacing: '8%', fontWeight: 350 }}>Log Out</div>
                </div>
            </div>
        </div>
    )
}

/**
 * SidebarItem component renders a sidebar item with optional submenus.
 * 
 * @param {Object} props - The properties object.
 * @param {Object} props.item - The item object containing details about the sidebar item.
 * @param {string} props.item.name - The name of the sidebar item.
 * @param {string} props.item.icon - The icon filename for the sidebar item.
 * @param {string} [props.item.route] - The route to navigate to when the item is clicked.
 * @param {Array} [props.item.submenus] - The list of submenus under the sidebar item.
 * @param {string} props.item.submenus[].name - The name of the submenu item.
 * @param {string} props.item.submenus[].route - The route to navigate to when the submenu item is clicked.
 * @param {boolean} props.selected - Indicates if the sidebar item is currently selected.
 * @param {number} props.selectedSubmenu - The index of the currently selected submenu.
 * @param {boolean} props.isConfig - Indicates if the sidebar is in configuration mode.
 * 
 * @returns {JSX.Element} The rendered sidebar item component.
 */
function SidebarItem({ item, selected, selectedSubmenu, isConfig }) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    return (
        <div className={`${styles.sidebarItem} ${selected ? styles.sidebarItemSelected : ''} ${open && styles.opened}`} onClick={() => {
            if (item.route) {
                navigate(item.route);
            }
        }}>
            {selected && <div className={styles.sidebarItemPill} />}
            <div className={'d-flex align-items-center justify-content-start'} style={{ paddingTop: '8px' }} onClick={() => {
                if (item.submenus) {
                    setOpen((prev) => !prev);
                }
            }}>
                <img alt={item.name} src={`/sidebar/${selected || open ? 'active' : 'inactive'}/${item.icon}`} className={'me-4'} width={'25px'} />
                <div>{item.name}</div>
            </div>
            <div className={'d-flex justify-content-start flex-column align-items-start'}>
                {(open || isConfig) &&
                    <>
                        {item.submenus?.map((submenu, sindex) => {
                            return (
                                <div className={`${styles.submenu} ${selectedSubmenu === submenu.index && isConfig ? styles.selectedSubmenu : ''}`} onClick={() => {
                                    navigate(submenu.route);
                                }}>
                                    <div>{submenu.name}</div>
                                </div>
                            )
                        })}
                    </>
                }
            </div>
        </div>
    )
}

/**
 * Profile component that renders a user profile with a logout button.
 *  
 * @component
 * @returns {JSX.Element} The rendered profile component.
 */
function Profile() {
    const [showLogout, setShowLogout] = useState(false);
    return (
        <div className={styles.profile} onClick={() => setShowLogout((prev) => !prev)}>
            <div className={styles.profileImage} />
            <div>
                <div style={{ fontWeight: '550', fontSize: '15px' }}>Admin Admin</div>
                <div style={{ color: '#A9AFD0', fontSize: '12px' }}>Mi Perfil</div>
            </div>
            {showLogout && <Logout />}
        </div>
    );
}

/**
 * Sidebar component that renders a sidebar with navigation items based on user permissions.
 * 
 * @component
 * @returns {JSX.Element} The rendered sidebar component.
 * 
 * @example
 * // Usage example:
 * <Sidebar />
 * 
 * @description
 * The Sidebar component retrieves user permissions from localStorage and dynamically generates
 * navigation items based on the allowed permissions. It includes sections for Dashboard, Resultado Turno,
 * Detalle Usuario, and a Configuración section with submenus for Empresa, Accesos, Perfiles, Turnos,
 * Master de Empleados, Master de Pulseras, and Master de Hubs.
 * 
 * @requires useContext
 * @requires useEffect
 * @requires MainContext
 * @requires SidebarItem
 * @requires Profile
 * @requires styles
 */
export default function Sidebar() {
    const { settings } = useContext(MainContext);
    useEffect(() => {

    }, []);

    let items = []

    const jsonPermissions = localStorage.getItem('permissions');
    if (jsonPermissions !== null && jsonPermissions !== "undefined") {
        const permissions = JSON.parse(jsonPermissions);
        const allowedNames = permissions?.filter((x) => x.access_level !== "none").map((x) => x.permission.name);
        const configRoutes = ['Empresa', 'Accesos', 'Perfiles', 'Turnos', 'Master de Empleados', 'Master de Pulseras'];
        const allowedConfig = configRoutes.filter((x) => allowedNames.includes(x));
        const showConfig = allowedConfig.length > 0;
        items = [
            ...(allowedNames?.includes('Dashboard') ? [{ name: 'Dashboard', icon: '/dashboard.png', route: '/', index: 0 }] : []),
            ...(allowedNames?.includes('Resultado Turno') ? [{ name: "Resultado Turno", icon: '/result.png', route: '/results', index: 1 }] : []),
            ...(allowedNames?.includes('Detalle Usuario') ? [{ name: "Detalle Usuario", icon: '/detail.png', route: '/detailed', index: 2 }] : []),
            ...(showConfig ? [{
                name: "Configuración", icon: '/companies.png', index: 3, submenus: [
                    ...(allowedNames?.includes('Empresa') ? [{ name: "Empresa", route: '/config/company', index: 0 }] : []),
                    ...(allowedNames?.includes('Accesos') ? [{ name: "Accesos", route: '/config/access', index: 1 }] : []),
                    ...(allowedNames?.includes('Perfiles') ? [{ name: "Perfiles", route: '/config/permissions', index: 2 }] : []),
                    ...(allowedNames?.includes('Turnos') ? [{ name: "Turnos", route: '/config/shifts', index: 3 }] : []),
                    ...(allowedNames?.includes('Master de Empleados') ? [{ name: "Master de Empleados", route: '/config/employees', index: 4 }] : []),
                    ...(allowedNames?.includes('Master de Pulseras') ? [{ name: "Master de Pulseras", route: '/config/bands', index: 5 }] : []),
                    ...(allowedNames?.includes('Master de Hubs') ? [{ name: "Master de Hubs", route: '/config/hubs', index: 6 }] : []),
                ]
            }] : []),
        ]
    }


    return (
        <div className={styles.container}>
            <div className={`${styles.logoContainer}`}>
                <img alt={'stracontech'} src={'/branding/stracontech_logo_final.png'} height={'40px'} />
            </div>
            <div className={'d-flex justify-content-center w-100'}>
                <div className={styles.sidebarDivider} style={{ marginBottom: '40px' }} />
            </div>
            <div style={{ overflowY: 'auto' }}>
                <div className={styles.sidebarItems}>
                    {items?.map((item, index) => {
                        return <SidebarItem selected={settings?.sidebar === item.index} isConfig={settings?.sidebar === 3} selectedSubmenu={settings?.submenu} key={index} item={item} />
                    })}
                </div>
            </div>

            <div className={styles.sidebarBottom}>
                <div className={styles.sidebarDivider} />
                <Profile />
            </div>
        </div>
    )
}