import React, { useEffect, useState } from "react";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import { DateRangePicker, Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import Switch from "../../../../components/ajonjolib/switch";
import styles from "./modals.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";

/**
 * GuardModal component renders a modal for editing guard details.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onSuccess - Callback function to be called on successful form submission.
 * @param {Object} props.company - The company object containing company details.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <GuardModal onSuccess={() => console.log('Success!')} company={companyData} />
 * 
 * @typedef {Object} Form
 * @property {int} id - The ID of the guard.
 * @property {string} name - The name of the guard.
 * @property {int} project - The ID of the project.
 * @property {int} project_group - The ID of the project group.
 * @property {Date} start_date - The start date of the guard's shift.
 * @property {Date} end_date - The end date of the guard's shift.
 * @property {boolean} active - The status of the guard.
 * 
 * @typedef {Object} Project
 * @property {int} id - The ID of the project.
 * @property {string} name - The name of the project.
 * 
 * @typedef {Object} ProjectGroup
 * @property {int} id - The ID of the project group.
 * @property {string} name - The name of the project group.
 * 
 * @description
 * This component uses several hooks:
 * - `useState` to manage the state of the modal, form data, projects, and project groups.
 * - `useEffect` to fetch data from the API when the modal is opened and when the project group changes.
 * - `useEffect` to subscribe to events for opening and closing the modal.
 * 
 * The component renders a modal with various input fields for editing guard details, including:
 * - Estado (active switch)
 * - Proyecto (project selection)
 * - Guardia (guard name)
 * - Rango de Fecha (date range)
 */
export default function GuardModal({ onSuccess, company }) {
    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState({});
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        api.get('projects/?project_group=' + form['project_group']).then((response) => {
            setProjects(response.data)
        }).catch(error => {
            console.log(error);
        }
        );
    }, [form['project_group']]);

    React.useEffect(() => {
        eventBus.subscribe('guard_modal_open', (rec_data) => {
            setIsOpen(true);
            const copy = {
                ...rec_data,
                project: rec_data.project?.id,
                project_group: rec_data.project_group?.id
            }
            setForm(copy);
        });
        eventBus.subscribe('guard_modal_close', (data) => { setIsOpen(false) });
    }, []);

    const submit = () => {
        api.put(`guards/${form?.id}/`, form).then((response) => {
            if (response?.status === 200) {
                setIsOpen(false);
                onSuccess();
                return;
            }
        }).catch(error => {
            console.log(error);
        }
        );
    }

    return (
        <div>
            {isOpen &&
                <Modal title={'Guardia'} desc={'Editar'} close={() => setIsOpen(false)}>
                    <Label>Estado</Label>
                    <Switch value={form['active']} onChange={(e) => setForm({
                        ...form, active: e
                    })} />

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-5'}>
                        <div style={{ flexBasis: '100%' }}>
                            <Label>Proyecto</Label>
                            <Select value={form['project']} onChange={(val) => {
                                setForm({ ...form, project: val[0], project_group: val[0]?.project_group?.id });
                            }} options={projects?.map((x) => {
                                return {
                                    value: x.id,
                                    name: x.name ? x.name : 'Sin Nombre'
                                }
                            })} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-3'}>
                        <div style={{ flexBasis: '45%' }}>
                            <Label>Guardia</Label>
                            <Text value={form['name']} onChange={(val) => {
                                setForm({ ...form, name: val });
                            }} />
                        </div>

                        <div style={{ flexBasis: '45%' }}>
                            <Label>Rango de Fecha</Label>
                            <div className={'d-flex align-items-center w-100 justify-content-between'}>
                                <input type={'date'} value={form['start_date']} onChange={(event) => {
                                    setForm({ ...form, start_date: event.target.value });
                                }} />
                                <input type={'date'} value={form['end_date']} onChange={(event) => {
                                    setForm({ ...form, end_date: event.target.value });
                                }} />
                            </div>
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                        <div className={styles.button} onClick={submit}>
                            <FontAwesomeIcon icon={faSave} color={'#52A035'} />
                            <div className={'ps-2'}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}